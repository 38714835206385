
export const calculateExpenseRatio = (
  expenses: Record<string, number>,
  income: Record<string, number>,
  monthlyMortgagePayments?: number[]
): number => {
  
  let totalExpenses = sumRecords(expenses)
  
  if (Array.isArray(monthlyMortgagePayments))
    totalExpenses += monthlyMortgagePayments?.reduce((sum, payment) => sum + payment, 0);
  
  const totalIncome = sumRecords(income)
  if (!totalIncome) return 0
  
  return decimalToPercent(totalExpenses / totalIncome)
}


export const calculateMonthlyMortgagePayment = (
  purchasePrice?: number,
  interestRate?: number,
  carryPct?: number
): number => {
  
  if (!purchasePrice || !interestRate || !carryPct)
    return 0;
  
  interestRate = percentToDecimal(interestRate);
  carryPct = percentToDecimal(carryPct);
  
  return +(purchasePrice * interestRate * carryPct / 12).toFixed(2);
}

export const calculateCapRate = (
  purchasePrice?: number,
  income?: Record<string, number>,
  expenses?: Record<string, number>,
) => {
  
  if (!purchasePrice)
    return 0
  
  const ratio = ((sumRecords(income) - sumRecords(expenses)) * 12) / purchasePrice
  return decimalToPercent(ratio)
}

export const calculateCashOnCashReturn = (
  income?: Record<string, number>,
  expenses?: Record<string, number>,
  startupCosts?: Record<string, number>,
) => {
  
  if (!income || !expenses || !startupCosts)
    return 0
  
  const sumStartupCosts = sumRecords(startupCosts)
  if (!sumStartupCosts)
    return 0
  
  const cashOnCashReturn = (sumRecords(income) - sumRecords(expenses)) / sumStartupCosts
  
  return decimalToPercent(cashOnCashReturn)
}

const decimalToPercent = (decimal: number): number => {
  return Math.round(decimal * 100)
}

const percentToDecimal = (percent: number): number => {
  return percent / 100;
}

const sumRecords = (records?: Record<string, number>) => {
  
  if (!records || typeof records !== 'object')
    return 0
  
  const values = Object.values(records)
  return values?.reduce((sum, value) => sum + value, 0) || 0;
}