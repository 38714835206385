import {AxiosResponse} from 'axios';
import { api } from './axios';
// import client from "./hyper";

import { fixURI, getError } from '../utils';

const getData = async (url: string): Promise<any> => {
  // const get = client.createRequest()({ method: "GET", endpoint: fixURI(url) });
  // const res = await get.send()
  // console.log('HYPER FETCH RES:', res)
  // return res
  
  return api()
  .get(fixURI(url))
  .then((response) => {
    if (response?.status === 204)
      return response
    
    else if (response?.status === 200)
      return response?.data;
    
    return getError(response?.data);
  })
  .catch(({ response }) => {
    return response;
  });
};


const postData = async (url: string, data: any): Promise<any> => {
  
  return api()
    .post(fixURI(url), data)
    .then((response) => {
      if (response?.status === 201 || response?.status === 200 || response?.status === 204) {
        return response;
      }
      return getError(response?.data)
    })
    .catch(({ response }) => {
      return response;
    });
};


const putData = (url: string, data: any): Promise<any> => {
  return api()
    .put(fixURI(url), data)
    .then((response) => {
      if (response?.status === 200) {
        return response?.data;
      }

      return getError(response?.data)
    })
    .catch(({ response }) => {
      return response;
    });
};


const patchData = (url: string, data: any): Promise<AxiosResponse> => {
  return api()
    .patch(fixURI(url), data)
    .then((response) => {
      return response
    })
    .catch(({ response }) => {
      return response;
    });
};


const deleteData = (url: string): Promise<AxiosResponse> => {
  return api()
    .delete(fixURI(url))
    .then((response) => {
      return response
    })
    .catch(({ response }) => {
      return response;
    });
};


export { getData, postData, putData, patchData, deleteData };
