import React, { useState, useRef } from 'react';
import {useParams} from "react-router-dom";
import './styles.scss'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { Chart } from "primereact/chart";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from 'primereact/accordion';
import {
  BsFillPersonVcardFill,
  BsFillTelephoneInboundFill,
} from "react-icons/bs";
import {
  FaHouseUser,
} from "react-icons/fa";
import {
  CiLocationOn
} from "react-icons/ci";
import {
  GiMagnifyingGlass
} from "react-icons/gi";


import useLeads from '../../hooks/useLeads';
import useDeals from '../../hooks/useDeals';
import {DealType, House, SmsTemplate, State, TwilioMessage} from '../../api/types';
import {Loader, Message, Map, ProgressCard} from "..";
import {getError, getIntentSeverity, getTimeDiff, priorities, stages} from "../../utils";
import {Badge} from "primereact/badge";
import {Divider} from "primereact/divider";
import {AiOutlineUpload} from "react-icons/ai";
import {PatternFormat} from "react-number-format";

interface LeadsParamType {
  id: string
}

const LeadsTable: React.FC = () => {
  
  const { id } = useParams<LeadsParamType>()
  
  const {
    states,
    dealTypes,
    loading,
    getLeads,
    researchLead,
    deserializeTextAreaInput,
    sendText,
    createLead,
    updateLead,
    deleteLead,
    pageCount,
    templateMsgs,
    getNextPage,
    error
  } = useLeads()
  const deals = useDeals()
  
  const [err, setErr] = useState<string>('');
  const [preLoading, setPreLoading] = useState<boolean>(false);
  
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<State>();
  const [zipcode, setZipcode] = useState<number>();
  const [income, setIncome] = useState<string>('');
  const [expenses, setExpenses] = useState<string>('');
  
  const [dealType, setDealType] = useState<DealType>();
  const [description, setDescription] = useState<string>('');
  const [priority, setPriority] = useState<string>('');
  const [stage, setStage] = useState<string>('');
  const [sellerPrice, setSellerPrice] = useState<number>();
  const [buyerPrice, setBuyerPrice] = useState<number>();
  const [idealPrice, setIdealPrice] = useState<number>();
  
  const [templateMsg, setTemplateMsg] = useState<SmsTemplate>();
  const [fromName, setFromName] = useState<string>('Silas');
  const [message, setMessage] = useState<string>('');
  
  const [leads, setLeads] = useState<House[]>([]);
  const [lead, setLead] = useState<House>();
  const [filteredLeads, setFilteredLeads] = useState<House[]>([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  
  const [filterOutHasDeal, setFilterOutHasDeal] = useState<boolean>(false);
  const [filterByContacted, setFilterByContacted] = useState<boolean>(false);
  const [filterByNotContacted, setFilterByNotContacted] = useState<boolean>(false);
  const [filterByHasResponded, setFilterByHasResponded] = useState<boolean>(false);
  const [filterByHasNotResponded, setFilterByHasNotResponded] = useState<boolean>(false);
  
  const [next, setNext] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  
  const [showResponsesDialog, setShowResponsesDialog] = useState<boolean>(false);
  const [leadDialog, setLeadDialog] = useState<boolean>(false);
  const [researchDialog, setResearchDialog] = useState<boolean>(false);
  const [dealDialog, setDealDialog] = useState<boolean>(false);
  const [contactDialog, setContactDialog] = useState<boolean>(false);
  const [deleteLeadDialog, setDeleteLeadDialog] = useState<boolean>(false);
  const [deleteLeadsDialog, setDeleteLeadsDialog] = useState<boolean>(false);
  
  const [openMap, setOpenMap] = useState<number>(-1);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  
  const toast = useRef(null);
  const dt = useRef(null);
  
  React.useEffect(() => {
    setPreLoading(true)
    getLeads(id).then(async (data) => {
      setPageNumber(1)
      setFilteredLeads(leads.concat(data?.results).filter((item: House) => filterListHelper(item, globalFilter)))
      setLeads(leads.concat(data?.results).filter((item: House) => filterListHelper(item, globalFilter)))
      setNext(data?.next)
      setPreLoading(false)
    })
  }, [])
  
  React.useEffect(() => {
    if (next)
      getNextPage(next).then((data) => {
        setPageNumber(pageNumber + 1)
        setFilteredLeads(leads.concat(data?.results).filter((item: House) => filterListHelper(item, globalFilter)))
        setLeads(leads.concat(data?.results).filter((item: House) => filterListHelper(item, globalFilter)))
        setNext(data?.next)
      })
  }, [next])
  
  const hideDialog = () => {
    setMessage('')
    setTemplateMsg(undefined)
    setShowResponsesDialog(false)
    setContactDialog(false)
    setResearchDialog(false);
    setLeadDialog(false);
    setDealDialog(false);
    setDeleteLeadDialog(false);
    setDeleteLeadsDialog(false);
    setLead(undefined)
    setAddress('')
    setCity('')
    setState(undefined)
    setZipcode(undefined)
    setIncome('')
    setExpenses('')
    setDealType(undefined)
    setPriority('')
    setStage('')
    setDescription('')
    setSellerPrice(undefined)
    setIdealPrice(undefined)
    setBuyerPrice(undefined)
    setOpenMap(-1)
    setErr('')
  }
  
  const hideDeleteLeadDialog = () => {
    setDeleteLeadDialog(false);
  }
  
  const hideDeleteLeadsDialog = () => {
    setDeleteLeadsDialog(false);
  }
  
  const saveLead = async () => {
    setErr('')
    let _leads = [...filteredLeads];
    let _lead = {...lead} as unknown as House
    
    if (!address) {
      setErr('Must enter address')
      return
    }
    if (!city) {
      setErr('Must enter city')
      return
    }
    if (!state) {
      setErr('Must choose state')
      return
    }
    if (zipcode === 0) {
      setErr('Must enter zipcode')
      return
    }
    
    if (!lead) {
      if (toast?.current) {
        const res = await createLead(address, city, state, zipcode ?? 0, income, expenses)
        if (res.status === 201) {
          _lead = res.data
          _leads.unshift(_lead)
          setLeads(_leads)
          setFilteredLeads(_leads)
          hideDialog()
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Lead Created', life: 3000 });
        }
        else {
          setErr(getError(res))
        }
      }
    } else {
      const res = await updateLead(_lead, address, city, state, zipcode ?? 0, income, expenses)
      if (res.status === 200) {
        const index = findIndexById(_lead.id);
        _lead = res.data
        _leads[index] = _lead
        setLeads(_leads)
        setFilteredLeads(_leads)
        hideDialog()
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Lead Edited', life: 3000 });
      }
      else {
        setErr(getError(res.data))
      }
    }
  }
  
  const handleResearchLead = async () => {
    setErr('')
    let _leads = [...filteredLeads];
    
    if (lead) {
      if (toast?.current) {
        const res = await researchLead(lead)
        if (res.id) {
          const index = findIndexById(lead.id);
          _leads[index] = res
          setLeads(_leads)
          setFilteredLeads(_leads)
          hideDialog()
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Lead Created', life: 3000 });
        }
        else {
          setErr(getError(res))
        }
      }
    } else {
      setErr('Must choose lead')
    }
  }
  
  const saveDeal = async () => {
    setErr('')
    let _leads = [...filteredLeads];
    let _lead = {...lead} as unknown as House
    
    if (lead?.id) {
      const index = findIndexById(lead.id);
      _leads[index] = _lead;
      
      if (toast?.current) {
        if (!dealType) {
          setErr('Must choose type')
          return
        }
        if (!priority) {
          setErr('Must choose priority')
          return
        }
        if (!stage) {
          setErr('Must choose stage')
          return
        }
        const res = await deals.createDeal(lead, dealType, description, priority, stage, String(sellerPrice || ''), String(buyerPrice || ''), String(idealPrice || ''))
        if (res.status === 201) {
          hideDialog()
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deal Created', life: 3000 });
        }
        else {
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Creating Deal', life: 3000 });
        }
      }
    }
  }
  
  const editLead = (lead: House) => {
    setLead({...lead});
    setLeadDialog(true);
    setAddress(lead.address)
    setCity(lead.city)
    setState(lead.state_data)
    setZipcode(lead.zipcode)
    setIncome(deserializeTextAreaInput(lead.monthly_income))
    setExpenses(deserializeTextAreaInput(lead.monthly_expenses))
  }
  
  const openResearchLead = (lead: House) => {
    setLead({...lead});
    setResearchDialog(true);
  }
  
  const promoteLead = (lead: House) => {
    setLead({...lead});
    setDealDialog(true);
  }
  
  const contactLead = (lead: House) => {
    setLead({...lead});
    setContactDialog(true);
  }
  
  const showResponses = (lead: House) => {
    setLead(lead);
    setShowResponsesDialog(true);
  }
  
  const confirmDeleteLead = (lead: House) => {
    setLead(lead);
    setDeleteLeadDialog(true);
  }
  
  const formatAddress = () => {
    if (lead)
      return `${lead.address} ${lead.city}, ${lead.state_data.short_name}`
    return ''
  }
  
  const handleTemplateMsgChange = (template: SmsTemplate) => {
    setTemplateMsg(template)
    setMessage(formatMessage(fromName, template))
  }
  
  const handleFromNameChange = (fromNameIn: string) => {
    setFromName(fromNameIn)
    setMessage(formatMessage(fromNameIn, templateMsg))
  }
  
  const formatMessage = (fromNameIn: string, template?: SmsTemplate) => {
    if (template) {
      let msg = template.template_text.replace('{from_name}', fromNameIn)
      msg = msg.replace('{first_name}', lead?.owner_data?.first_name ?? '')
      msg = msg.replace('{business_name}', 'Coleman Group Solutions' ?? '')
      msg = msg.replace('{address}', formatAddress() ?? '')
      return msg
    }
    return ''
  }
  
  const handleDeleteLead = async () => {
    if (toast?.current && lead?.id) {
      const res = await deleteLead(lead.id)
      if (res.status === 204) {
        let _leads = filteredLeads.filter((house: House) => house.id !== lead?.id);
        setLeads(_leads);
        setFilteredLeads(_leads);
        setLead(undefined);
        setDeleteLeadDialog(false);
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Lead Deleted', life: 3000 });
      }
      else {
        setDeleteLeadDialog(false);
        // @ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Deleting Lead', life: 3000 });
      }
    }
  }
  
  const filterListHelper = (house: House, queryIn: string) => {
    if (filterOutHasDeal && house.has_deal) {
      return false
    }
    if (!queryIn) return true
    
    const query = queryIn.toLowerCase()
    
    const fullName = `${house?.owner_data?.first_name} ${house?.owner_data?.last_name}`
    
    return house?.owner_data?.first_name?.toLowerCase()?.includes(query) ||
    house?.owner_data?.last_name?.toLowerCase()?.includes(query) ||
    fullName.toLowerCase()?.includes(query) ||
    house?.owner_data?.last_name?.toLowerCase()?.includes(query) ||
    house?.owner_data?.username?.toLowerCase()?.includes(query) ||
    house?.owner_data?.member_data?.phone?.includes(query) ||
    house?.owner_data?.member_data?.contact_number_data?.number?.includes(query) ||
    house?.address?.toLowerCase()?.includes(query) ||
    house?.city?.toLowerCase()?.includes(query) ||
    house?.owner_data?.member_data?.interest_level?.toLowerCase()?.includes(query) ||
    house?.deal_type_data?.name?.toLowerCase()?.includes(query) ||
    house?.state_data?.long_name?.toLowerCase()?.includes(query) ||
    house?.state_data?.short_name?.toLowerCase()?.includes(query)
  }
  
  const handleGlobalFilterChange = (e: any) => {
    setGlobalFilter(e.target.value)
    setFilteredLeads(leads?.filter((house: House) => filterListHelper(house, e.target.value)))
  }
  
  const handleContactedFilterChange = (contacted: boolean, notContacted: boolean) => {
    setFilterByContacted(contacted)
    setFilterByNotContacted(notContacted)
    setFilteredLeads(leads?.filter((house: House) => {
      return (Boolean(house?.owner_data?.member_data?.last_contacted) === contacted || !Boolean(house?.owner_data?.member_data?.last_contacted) === notContacted) && filterListHelper(house, globalFilter)
    }))
  }
  
  const handleHasRespondedFilterChange = (responded: boolean, notResponded: boolean) => {
    setFilterByHasResponded(responded)
    setFilterByHasNotResponded(notResponded)
    setFilteredLeads(leads?.filter((house: House) => {
      return (Boolean(house?.owner_data?.member_data?.last_responded) === responded || !Boolean(house?.owner_data?.member_data?.last_responded) === notResponded) && filterListHelper(house, globalFilter)
    }))
  }
  
  const findIndexById = (id: number) => {
    let index = -1;
    for (let i = 0; i < filteredLeads.length; i++) {
      if (filteredLeads[i].id === id) {
        index = i;
        break;
      }
    }
    
    return index;
  }
  
  const exportCSV = () => {
    // @ts-ignore
    dt?.current?.exportCSV();
  }
  
  const openNew = () => {
    setLead(undefined);
    setLeadDialog(true);
    setErr('')
  }
  
  const confirmDeleteSelected = () => {
    setDeleteLeadsDialog(true);
  }
  
  const deleteSelectedLeads = async () => {
    let _leads = filteredLeads.filter((val: House) => {
      // @ts-ignore
      return !selectedLeads?.includes(val);
    
    });
    
    if (toast?.current) {
      await selectedLeads.forEach(async (item: House) => {
        await deleteLead(item.id)
      })
      setFilteredLeads(_leads);
      setDeleteLeadsDialog(false);
      setSelectedLeads([]);
      // @ts-ignore
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Leads Deleted', life: 3000 });
    }
  }
  
  const handleContact = async (e: any, lead?: House) => {
    if (lead?.owner_data?.member_data) {
      const res = await sendText(lead.owner_data.member_data.contact_number_data.number, lead.owner_data.member_data.phone, lead, message)
      if (toast?.current) {
        if (res.status === 200) {
          let _items = [...filteredLeads];
          const index = findIndexById(lead.id);
          lead.owner_data.member_data.last_contacted = String(new Date())
          _items[index] = lead;
          setLeads(_items);
          setFilteredLeads(_items);
          setContactDialog(false)
          // @ts-ignore
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Lead Contacted`,
            life: 3000
          });
        }
        else {
          // @ts-ignore
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: getError(res?.data),
            life: 3000
          });
        }
      }
    }
  }
  
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button id="new-lead-btn" label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        <Button id="delete-lead-btn" label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedLeads || !selectedLeads?.length} />
      </React.Fragment>
    )
  }
  
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
      </React.Fragment>
    )
  }
  
  const propertyBodyTemplate = (rowData: House) => {
    const contact_number = rowData.owner_data?.member_data?.phone
    return (
      <>
        <p>
          <BsFillPersonVcardFill className='mr-3' />
          {rowData.owner_data?.first_name} {rowData.owner_data?.last_name}
        </p>
        
        <p>
          <BsFillTelephoneInboundFill className='mr-3' />
          <PatternFormat value={contact_number} displayType={'text'} format="(###) ###-####" />
        </p>
        
        <p>
          <FaHouseUser className='mr-3' />
          {rowData.address}
        </p>
        
        <p>
          <CiLocationOn className='mr-3' />
          {rowData.city}, {rowData.state_data.short_name} {rowData.zipcode}
        </p>
        
        <p>
          <AiOutlineUpload className="mr-3" />
          <span className="text-s text-gray-500">
            Uploaded {getTimeDiff(rowData?.created)}
          </span>
        </p>
        
        <p>
          <GiMagnifyingGlass className="mr-3" />
          <span className="text-s text-gray-500 mr-2">
            Intent:
          </span>
          <Badge
            className='float-left mr-1'
            severity={getIntentSeverity(rowData?.owner_data?.member_data?.interest_level ?? '')}
            value={rowData?.owner_data?.member_data?.interest_level ?? 'UNKNOWN'}
          />
        </p>
      </>
    )
  }
  
  const hasContactedBodyTemplate = (rowData: House) => {
    const researchTimeDiff = rowData?.last_researched ? getTimeDiff(rowData?.last_researched) : null
    const contactTimeDiff = rowData?.owner_data?.member_data?.last_contacted ? getTimeDiff(rowData?.owner_data?.member_data?.last_contacted) : null
    const responseTimeDiff = rowData?.owner_data?.member_data?.last_responded ? getTimeDiff(rowData?.owner_data?.member_data?.last_responded) : null
    return (
      <>
        <div>Researched: </div>
        <br/>
        <Checkbox checked={Boolean(rowData?.last_researched)}/>
        <br/>
        {researchTimeDiff && <p className="text-xs text-gray-500">{researchTimeDiff}</p>}
        
        <br/>
        <br/>
        
        <div>Contacted: </div>
        <br/>
        <Checkbox checked={Boolean(rowData?.owner_data?.member_data?.last_contacted)}/>
        <br/>
        {contactTimeDiff && <p className="text-xs text-gray-500">{contactTimeDiff}</p>}
        
        <br/>
        <br/>
        
        <div>Responded: </div>
        <br/>
        <Checkbox
          checked={Boolean(rowData?.owner_data?.member_data?.last_responded)}
        />
        <br/>
        {responseTimeDiff && <p className="text-xs text-gray-500">{responseTimeDiff}</p>}
      </>
    )
  }
  
  const printFeaturesInHtml = (json: any) => {
    if (!json) return null
    
    return (
      <div>
        {Object?.keys(json).map((key: string) => (
          <div>
            <p><strong>{key}</strong>: {String(json[key])}</p>
          </div>
        ))}
      </div>
    )
  }
  
  const printPropertyTaxesInHtml = (json: any) => {
    if (!json) return null
    
    return (
      <div>
        {Object?.keys(json).map((key: string) => (
          <div>
            <p><strong>{key}</strong>: {String(json[key]["total"])}</p>
          </div>
        ))}
      </div>
    )
  }
  
  const printTaxAssessmentsInHtml = (json: any) => {
    if (!json) return null
    
    return (
      <div>
        {Object?.keys(json).map((key: string) => (
          <Accordion>
            <AccordionTab header={key}>
              <p className="m-0">
                <div><strong>Improvements</strong>: {json[key]["improvements"]}</div>
                <div><strong>Land</strong>: {json[key]["land"]}</div>
                <div><strong>Value</strong>: {json[key]["value"]}</div>
              </p>
            </AccordionTab>
          </Accordion>
        ))}
      </div>
    )
  }
  
  const houseInformationBodyTemplate = (rowData: House) => {
    return (
      <>
        <Accordion multiple activeIndex={0}>
          {rowData.num_bed > 0 || rowData.num_bath > 0 || rowData.sq_ft > 0 || rowData.lot_size > 0 || rowData.year_built > 0 || rowData.property_type && (
            <AccordionTab header="Property">
              {rowData.num_bed > 0 ? (<p><strong>Bedrooms</strong>: {rowData.num_bed}</p>) : null}
              {rowData.num_bath > 0 ? (<p><strong>Bathrooms</strong>: {rowData.num_bath}</p>) : null}
              {rowData.sq_ft > 0 ? (<p><strong>Sq Ft</strong>: {rowData.sq_ft}</p>) : null}
              {rowData.lot_size > 0 ? (<p><strong>Lot Size</strong>: {rowData.lot_size}</p>) : null}
              {rowData.year_built > 0 ? (<p><strong>Year Built</strong>: {rowData.year_built}</p>) : null}
              {rowData.property_type ? (<p><strong>Property Type</strong>: {rowData.property_type}</p>) : null}
            </AccordionTab>
          )}
          
          {rowData.investment_data && Object?.keys(rowData.investment_data)?.length && (
            <AccordionTab className="investment-data" header="Investment Data">
              <div id="investment-data-values">
                {Object.entries(rowData.investment_data)?.map((data: any) => (
                  <p key={data[0]}>
                    <strong>{JSON.stringify(data)}</strong>
                  </p>
                ))}
              </div>
            </AccordionTab>
          )}
          
          {rowData.monthly_income && Object?.keys(rowData.monthly_income)?.length && (
            <AccordionTab className="monthly-income" header="Monthly Income">
              <div id="monthly-income-values">
                {Object.entries(rowData.monthly_income)?.map((income: any) => (
                  <p key={income[0]}>
                    <strong id={`monthly-income-${income[0]}`}>{income[0]}</strong>: {income[1]}
                  </p>
                ))}
              </div>
            </AccordionTab>
          )}
          
          {rowData.monthly_expenses && Object?.keys(rowData.monthly_expenses)?.length && (
            <AccordionTab className="monthly-expenses" header="Monthly Expenses">
              <div id="monthly-expenses-values">
                {Object.entries(rowData.monthly_expenses)?.map((expense: any) => (
                  <p key={expense[0]}>
                    <strong id={`monthly-expenses-${expense[0]}`}>{expense[0]}</strong>: {expense[1]}
                  </p>
                ))}
              </div>
            </AccordionTab>
          )}
          
          {rowData.features && Object.keys(rowData.features)?.length ? (
            <AccordionTab header="Features">
              {printFeaturesInHtml(rowData.features)}
            </AccordionTab>
          ) : null}
          
          {rowData.property_taxes && Object?.keys(rowData.property_taxes)?.length ? (
            <AccordionTab header="Property Taxes">
              {printPropertyTaxesInHtml(rowData.property_taxes)}
            </AccordionTab>
          ) : null}
          
          {rowData.tax_assessment && Object?.keys(rowData.tax_assessment)?.length ? (
            <AccordionTab header="Tax Assessments">
              <p>{printTaxAssessmentsInHtml(rowData.tax_assessment)}</p>
            </AccordionTab>
          ) : null}
        
        </Accordion>
        
      </>
    )
  }
  
  const priceBodyTemplate = (rowData: House) => {
    const options = {
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
    const data = {
      labels: ['Price Lower', 'Price Upper'],
      datasets: [
        {
          label: 'Price Range',
          backgroundColor: '#42A5F5',
          data: [rowData.price_range_lower, rowData.price_range_upper]
        },
      ]
    }
    
    return (
      <div className="card">
        <Chart type="bar" data={data} options={options} />
      </div>
    )
  }
  
  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button id="edit-item-btn" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editLead(rowData)} />
        <Button id="research-item-btn" icon="pi pi-book" className="p-button-rounded p-button-info mr-2" onClick={() => openResearchLead(rowData)} />
        <Button id="create-deal-btn" icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" onClick={() => promoteLead(rowData)} />
        
        <Button icon="pi pi-external-link" className="p-button-rounded mr-2" onClick={() => setOpenMap(rowData?.id)} />
        <Dialog visible={openMap === rowData?.id} style={{ width: '450px' }} modal className="p-fluid" onHide={hideDialog}>
          <Map showAddress address={`${rowData?.address}, ${rowData?.city} ${rowData?.state_data?.short_name}`}/>
        </Dialog>
        
        <Button icon="pi pi-bell" className="p-button-rounded p-button-secondary mr-2" onClick={() => showResponses(rowData)} />
        <Button icon="pi pi-plus" className="p-button-rounded p-button-warning mr-2" onClick={() => contactLead(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteLead(rowData)} />
      </React.Fragment>
    );
  }
  
  const header = (
    <div className="table-header">
      <h5>Leads Retrieved</h5>
      <ProgressCard color='auto' numerator={pageNumber} denominator={pageCount}/>
      <br/>
      <h5 className="mx-0 my-1">Manage Leads</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => handleGlobalFilterChange(e)} placeholder="Search..." />
      </span>
      <span className="p-input-icon-left">
        <Checkbox inputId="cb1" checked={filterByContacted} value={'Filter By Contacted:'} onChange={(e) => handleContactedFilterChange(e.checked, false)}/>
        <label htmlFor="cb1" className="p-checkbox-label">Filter By Contacted</label>
      </span>
      <span className="p-input-icon-right">
        <Checkbox inputId="cb2" checked={filterByNotContacted} value={'Filter By Not Contacted'} onChange={(e) => handleContactedFilterChange(false, e.checked)}/>
        <label htmlFor="cb2" className="p-checkbox-label">Filter By Not Contacted</label>
      </span>
      <span className="p-input-icon-right">
        <Checkbox inputId="cb3" checked={filterByHasResponded} value={'Filter By Has Responded'} onChange={(e) => handleHasRespondedFilterChange(e.checked, false)}/>
        <label htmlFor="cb3" className="p-checkbox-label">Filter By Has Responded</label>
      </span>
      <span className="p-input-icon-right">
        <Checkbox inputId="cb4" checked={filterByHasNotResponded} value={'Filter By Has Not Responded'} onChange={(e) => handleHasRespondedFilterChange(false, e.checked)}/>
        <label htmlFor="cb4" className="p-checkbox-label">Filter By Has Not Responded</label>
      </span>
    </div>
  );
  
  const leadDialogFooter = (
    <React.Fragment>
      <Button id="cancel-item-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button id="submit-item-btn" label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveLead} />
    </React.Fragment>
  );
  
  const researchDialogFooter = (
    <React.Fragment>
      <Button id="cancel-item-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button id="submit-item-btn" label="Submit" icon="pi pi-check" className="p-button-text" onClick={handleResearchLead} />
    </React.Fragment>
  );
  
  const dealDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveDeal} />
    </React.Fragment>
  );
  
  const contactDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Send" icon="pi pi-check" className="p-button-text" onClick={(e) => handleContact(e, lead)} />
    </React.Fragment>
  );
  
  const deleteLeadDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteLeadDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={handleDeleteLead} />
    </React.Fragment>
  );
  
  const deleteLeadsDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteLeadsDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedLeads} />
    </React.Fragment>
  );
  
  const selectedDealTypeTemplate = (option: DealType, props: any) => {
    if (option) {
      return (
        <div className="deal-type-item deal-type-item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const dealTypeOptionTemplate = (option: DealType) => {
    return (
      <div className="deal-type-item">
        <div>{option.name}</div>
      </div>
    );
  }
  
  const selectedStateTemplate = (option: State, props: any) => {
    if (option) {
      return (
        <div className="state-item state-item-value">
          <div>{option.long_name}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const stateOptionTemplate = (option: State) => {
    return (
      <div id="state-item" className="state-item">
        <div>{option.long_name}</div>
      </div>
    );
  }
  
  const selectedStringTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="item item-value">
          <div>{option}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const stringOptionTemplate = (option: string) => {
    return (
      <div className="item">
        <div>{option}</div>
      </div>
    );
  }
  
  const selectedTemplateMsgTemplate = (option: SmsTemplate, props: any) => {
    if (option) {
      return (
        <div className="template-msg-item template-msg-item-value">
          <div>{option.template_text}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const templateMsgOptionTemplate = (option: SmsTemplate) => {
    return (
      <div className="template-msg-item">
        <div>{option.template_text}</div>
      </div>
    );
  }
  
  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
        <Loader dialog loading={preLoading}/>
        
        <DataTable
          ref={dt}
          value={filteredLeads}
          selection={selectedLeads}
          onSelectionChange={(e) => {
            setSelectedLeads(e.value)
          }}
          dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Leads"
          header={header} responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{width: '3rem'}} exportable={false}/>
          <Column field="owner" header="Property" body={propertyBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column header="Conversation" body={hasContactedBodyTemplate} style={{minWidth: '2rem'}}/>
          <Column header="Details" body={houseInformationBodyTemplate} style={{minWidth: '10rem'}}/>
          <Column field="priceChart" header="Price Range" body={priceBodyTemplate} style={{minWidth: '4rem'}}/>
          <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}/>
        </DataTable>
      </div>
  
      <Dialog visible={showResponsesDialog} style={{ width: '450px' }} header="Conversation" modal className="p-fluid" onHide={hideDialog}>
        <div className="col-12">
          {lead?.owner_data?.member_data?.responses?.length ? (lead?.owner_data?.member_data?.responses.map((message: TwilioMessage) => (
            <p key={message?.id}>
              
              <strong>
                {message.direction === 'INBOUND' ? message.member_name : String(message.twilio_number_data?.number)}
              </strong>
              
              <>
                <span className="float-right text-xs text-gray-500">
                  {getTimeDiff(message.created ?? "")}
                </span>
                {message.direction === 'INBOUND' ? (
                <Badge
                  className='float-right mr-1'
                  severity={getIntentSeverity(message?.intent ?? '')}
                  value={message?.intent ?? 'UNKNOWN'}
                />
                ) : null}
              </>
              
              <div className='mt-3'>{message.message}</div>
              <br/>
              <Divider/>
            </p>
          ))) : (
            <p>
              No Responses
            </p>
          )}
        </div>
      </Dialog>
      
      <Dialog visible={leadDialog} style={{ width: '450px' }} header={`${lead?.id ? "Edit" : "Create"} Lead`} modal className="p-fluid" footer={leadDialogFooter} onHide={hideDialog}>
        
        <div className="col-12">
          <InputText id="address-input" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" />
        </div>
        
        <div className="col-12">
          <InputText id="city-input" value={city} onChange={(e) => setCity(e.target.value)} placeholder="City" />
        </div>
        
        <div className="col-12">
          <Dropdown
            id="state-input"
            value={state}
            options={states}
            onChange={(e) => setState(e.value)}
            optionLabel="state"
            placeholder="Select State"
            valueTemplate={selectedStateTemplate}
            itemTemplate={stateOptionTemplate}
          />
        </div>
        
        <div className="col-12">
          <InputNumber id="zipcode-input" placeholder='Zipcode' inputId="integeronly" value={zipcode} onValueChange={(e) => setZipcode(e.value || 0)} />
        </div>
        
        <div className="col-12">
          <InputTextarea id="income-input" placeholder="Monthly Income" value={income} onChange={(e) => setIncome(e.target.value)} rows={5} cols={30} />
        </div>
        
        <div className="col-12">
          <InputTextarea id="expenses-input" placeholder="Monthly Expenses" value={expenses} onChange={(e) => setExpenses(e.target.value)} rows={5} cols={30} />
        </div>
        
        <Loader loading={deals.loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={researchDialog} style={{ width: '450px' }} header="Research Lead" modal className="p-fluid" footer={researchDialogFooter} onHide={hideDialog}>
        <h4>Are you sure you would like to perform research on</h4>
        <h3>{`${lead?.address} ${lead?.city}, ${lead?.state_data?.long_name}`}?</h3>
        <h5>There are cost implications to performing too much research</h5>
        <h5>Researching more than 20 leads a month will lead to extra charges</h5>
        <Loader loading={loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={dealDialog} style={{ width: '450px' }} header="Create Deal" modal className="p-fluid" footer={dealDialogFooter} onHide={hideDialog}>
        
        <div className="col-12">
          <Dropdown
            value={dealType}
            options={dealTypes}
            onChange={(e) => setDealType(e.value)}
            optionLabel="name"
            placeholder="Select Type"
            valueTemplate={selectedDealTypeTemplate}
            itemTemplate={dealTypeOptionTemplate}
          />
        </div>
        
        <div className="col-12">
          <Dropdown
            value={priority}
            options={priorities}
            onChange={(e) => setPriority(e.value)}
            optionLabel="name"
            placeholder="Select Priority"
            valueTemplate={selectedStringTemplate}
            itemTemplate={stringOptionTemplate}
          />
        </div>
        
        <div className="col-12">
          <Dropdown
            value={stage}
            options={stages}
            onChange={(e) => setStage(e.value)}
            optionLabel="stage"
            placeholder="Select Stage"
            valueTemplate={selectedStringTemplate}
            itemTemplate={stringOptionTemplate}
          />
        </div>
        
        <div className="col-12">
          <InputText onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
        </div>
  
        <div className="col-12">
          <InputNumber placeholder='Seller Price' inputId="integeronly" value={sellerPrice} onValueChange={(e) => setSellerPrice(e.value || 0)} />
        </div>
        
        <div className="col-12">
          <InputNumber placeholder='Buyer Price' inputId="integeronly" value={buyerPrice} onValueChange={(e) => setBuyerPrice(e.value || 0)} />
        </div>
        
        <div className="col-12">
          <InputNumber placeholder='Target Price' inputId="integeronly" value={idealPrice} onValueChange={(e) => setIdealPrice(e.value || 0)} />
        </div>
  
        <Loader loading={deals.loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={contactDialog} style={{ width: '450px' }} header="Contact" modal footer={contactDialogFooter} onHide={hideDialog}>
        <div className="confirmation-content">
          <div className="col-12">
            <label htmlFor="templateMsg" className="block">Template Message</label>
            <Dropdown
              id="templateMsg"
              style={{ width: '385px' }}
              value={templateMsg}
              options={templateMsgs}
              onChange={(e) => {
                handleTemplateMsgChange(e.value)
              }}
              optionLabel="templateMsg"
              placeholder="Select Template Message"
              valueTemplate={selectedTemplateMsgTemplate}
              itemTemplate={templateMsgOptionTemplate}
            />
          </div>
          
          <div className="col-12">
            <label htmlFor="fromName" className="block">From Name</label>
            <InputText id="fromName" style={{ width: '385px' }} value={fromName} onChange={(e) => handleFromNameChange(e.target.value)} placeholder="From Name" />
          </div>
          
          <div className="col-12">
            <label htmlFor="message" className="block">Message To Send</label>
            <InputTextarea id="message" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} style={{ width: '385px' }} rows={5} cols={30} autoResize />
          </div>
  
          <Message
            severity={'error'}
            message={error}
          />
        </div>
      </Dialog>
      
      <Dialog visible={deleteLeadDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteLeadDialogFooter} onHide={hideDeleteLeadDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {lead && <span>Are you sure you want to delete <b>{`${lead?.owner_data?.first_name} ${lead?.owner_data?.last_name}`}</b>?</span>}
        </div>
      </Dialog>
      
      <Dialog visible={deleteLeadsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteLeadsDialogFooter} onHide={hideDeleteLeadsDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {selectedLeads.length && <span>Are you sure you want to delete the selected list of leads?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default LeadsTable;
