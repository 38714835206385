import { postData } from './http';
import {Deal, User} from './types';


export const signup = async (email: string, password: string, confirmPassword: string): Promise<void> => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password1', password);
  formData.append('password2', confirmPassword);

  return postData('auth/register/', formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const logout = async (): Promise<void> => {
  const formData = new FormData();
  return postData('auth/logout/', formData);
};


export const login = async (email: string, password: string): Promise<void> => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);

  return postData('auth/login/', formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const memberSignup = async (
  isSeller: boolean,
  isBuyer: boolean,
  phone: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  isNew?: boolean,
  deal?: Deal,
): Promise<any> => {
  const formData = new FormData()
  if (isSeller) formData.append('is_seller', String(isSeller))
  if (isBuyer) formData.append('is_buyer', String(isBuyer))
  formData.append('phone', phone)
  if (firstName) formData.append('first_name', firstName)
  if (lastName) formData.append('last_name', lastName)
  if (email) formData.append('email', email)
  if (deal) formData.append('deal', String(deal?.id))
  if (isNew) formData.append('new', String(isNew))
  
  return postData('members/', formData)
  .then((res) => {
    return res;
  })
  .catch((error) => {
    console.log(error);
  });
};


export const uploadHouse = async (owner: User, address: string, city: string, state: string): Promise<void> => {
  const formData = new FormData();
  formData.append('owner', String(owner.id));
  formData.append('address', address);
  formData.append('city', city);
  formData.append('state_string', state);

  return postData('houses/', formData)
    .then((res) => {
      if (res?.status !== 201) {
        console.log('POST HOUSE ERROR:', res);
      }
      return res;
    })
    .catch((error) => {
      console.log('UPLOAD HOUSES ERROR:', error);
    });
};

