import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  memberSignup,
  patchHouse,
  patchNames,
  signup,
  uploadHouse,
} from '../../api';
import { Loader, Message, TextField, GoogleButton, HomeHeader } from '../../components';
import { getError, GOOGLE_NUMBER } from '../../utils';
import { socialLogin } from '../../store/actions/auth';
import GridContainer from '../../kit-components/Grid/GridContainer';
import GridItem from '../../kit-components/Grid/GridItem';
import RegularButton from '../../kit-components/CustomButtons/Button';
import styles from '../../assets/jss/material-kit-react/views/components';

import './styles.scss';

const useStyles = makeStyles(styles);

const SellerForm: React.FC = () => {
  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [propertyState, setPropertyState] = React.useState('');
  const [houseID, setHouseID] = React.useState('');

  const [condition, setCondition] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [sellReasons, setSellReasons] = React.useState('');
  const [sellTimeline, setSellTimeline] = React.useState('');

  const [isDone, setIsDone] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false)

  const data = useSelector((store: any) => store);
  const dispatch = useDispatch();
  
  const onLogin = () => {
    setLoading(true)
    setError('')
    signup(email, password, confirmPassword).then(
      (res: any) => {
        if (res?.status === 201) {
          setMsg(res?.data?.detail)
          setLoading(false)
          dispatch(socialLogin())
        }
        else {
          setError(getError(res?.data))
          setLoading(false)
        }
      }
    );
  }

  const onUpdateHouse = () => {
    setLoading(true)
    patchHouse(houseID, condition, price, sellReasons, sellTimeline).then(
      () => {
        setIsDone(true)
        setError('')
        setLoading(false)
      }
    )
  }

  const onSellerSignup = () => {
    setLoading(true)
    if (data?.auth?.user?.user?.member_data?.url && houseID) 
      patchHouse(houseID, condition, price, sellReasons, sellTimeline).then(
        (res: any) => {
          if (res?.status !== 200) {
            console.log('PATCH HOUSE ERROR:', res)
            setError(res?.detail)
            setIsDone(true)
            setLoading(false)
          }
        }
      )
    else
      memberSignup(true, false, phone).then((res: any) => {
        dispatch(socialLogin())
        uploadHouse(data?.auth?.user?.user, address, city, propertyState).then(
          (res2: any) => {
            if (res2.status !== 201)
              setError('Error uploading house')
            else {
              setHouseID(res2?.data?.id)
              setError('')
            }
            setLoading(false)
          }
        )}
      );
  };

  const displayMsg = error !== '' ? error : msg

  const waitForVerify = (
    <>
      <GridContainer>
        <GridItem lg={12}>
          <Message severity={error ? 'error' : 'success'} message={displayMsg} />
        </GridItem>
      </GridContainer>
    </>
  )

  const introForm = (
    <>
      <div className={'sign-in-container-social'}>
        <GridItem lg={12}>
          <GoogleButton />
        </GridItem>
      </div>

      <GridItem lg={12}>
        <Message severity={error ? 'error' : 'success'} message={displayMsg} />
        <TextField
          value={email}
          id="email"
          placeholder="Email Address"
          autoComplete="email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          handleSubmit={onLogin}
        />
      </GridItem>

      <GridItem lg={12}>
        <TextField
          value={password}
          id="password"
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          handleSubmit={onLogin}
        />
      </GridItem>

      <GridItem lg={12}>
        <TextField
          value={confirmPassword}
          id="confirm-password"
          type="password"
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          handleSubmit={onLogin}
        />
      </GridItem>

      <GridItem lg={12}>
        <div className="address-form-cash-btn-container">
          <RegularButton onClick={onLogin} className='how-we-help-get-your-best-btn'>
            <div className='submit-btn'>Submit </div>
          </RegularButton>
        </div>

        <div className="can-call-us-text">
          <h4>
            Or Call Us At {GOOGLE_NUMBER}
          </h4>
        </div>
      </GridItem>

    </>
  )

  const sellerForm = (
    <>

      <GridItem lg={12}>
        <TextField
          value={phone}
          id="phone"
          placeholder="Phone Number"
          autoComplete="phone"
          type="phone"
          onChange={(e) => setPhone(e.target.value)}
          handleSubmit={onSellerSignup}
        />
      </GridItem>
  
      <GridItem lg={12}>
        <TextField
          value={address}
          id="address"
          placeholder="Property Address"
          autoComplete="address"
          type="address"
          onChange={(e) => setAddress(e.target.value)}
          handleSubmit={onSellerSignup}
        />
      </GridItem>
  
      <GridItem lg={12}>
        <TextField
          value={city}
          id="city"
          placeholder="Property City"
          autoComplete="propertyCity"
          type="address"
          onChange={(e) => setCity(e.target.value)}
          handleSubmit={onSellerSignup}
        />
      </GridItem>
  
      <GridItem lg={12}>
        <TextField
          value={propertyState}
          id="propertyState"
          placeholder="Property State"
          autoComplete="propertyState"
          type="address"
          onChange={(e) => setPropertyState(e.target.value)}
          handleSubmit={onSellerSignup}
        />
      </GridItem>

      <GridItem lg={12}>
        <div className="address-form-cash-btn-container">
          <RegularButton onClick={onSellerSignup} className='how-we-help-get-your-best-btn'>
            <div className='submit-btn'>Submit </div>
          </RegularButton>
        </div>

        <div className="can-call-us-text">
          <h4>
            Or Call Us At {GOOGLE_NUMBER}
          </h4>
        </div>
      </GridItem>

    </>

  )

  // THIS IS THE COMPONENT THAT SHOWS THE PILLAR FORM
  // TODO: update each "placeholder" value to show what you want
  const pillarForm = (
    <>
      <GridItem lg={12}>
        <Message severity={error ? 'error' : 'success'} message={displayMsg} />
        <TextField
          value={condition}
          id="House Condition"
          placeholder="House Condition"
          onChange={(e) => setCondition(e.target.value)}
          handleSubmit={onUpdateHouse}
        />
      </GridItem>

      <GridItem lg={12}>
        <TextField
          value={price}
          id="price"
          placeholder="Ideal Price"
          onChange={(e) => setPrice(e.target.value)}
          handleSubmit={onUpdateHouse}
        />
      </GridItem>

      <GridItem lg={12}>
        <TextField
          value={sellReasons}
          id="sellReasons"
          placeholder="Reasons for Selling"
          onChange={(e) => setSellReasons(e.target.value)}
          handleSubmit={onUpdateHouse}
        />
      </GridItem>

      <GridItem lg={12}>
        <TextField
          value={sellTimeline}
          id="sellTimeline"
          placeholder="Timeline for Selling"
          onChange={(e) => setSellTimeline(e.target.value)}
          handleSubmit={onUpdateHouse}
        />
      </GridItem>

      <GridItem lg={12}>
        <div className="address-form-cash-btn-container">
          <RegularButton onClick={onUpdateHouse} className='how-we-help-get-your-best-btn'>
            <div className='submit-btn'>Submit </div>
          </RegularButton>
        </div>

        <div className="can-call-us-text">
          <h4>
            Or Call Us At {GOOGLE_NUMBER}
          </h4>
        </div>
      </GridItem>

    </>
  )

  const getFormComponent = () => {
    const isLogin = data?.auth?.user?.isLogin
    const isMember = Boolean(data?.auth?.user?.user?.member_data?.url)

    if (error) {
      return (
        <>
          <Message severity={'error'} message={error} />
          {sellerForm}
        </>
      )
    }
    
    if (isLogin) {

      if (!isMember) {
        return (
          <>
            <Message severity={'success'} message={'Thanks for joining! Continue filling out the inquiry form below if you wish to receive text messages about your real estate solution'} />
            {sellerForm}
          </>
        )
      }

      if (houseID && !isDone) {
        return (
          <>
            <Message severity={'success'} message={'Thanks for filling out our inquiry form! You should have received a text to schedule a call with one of our agents. If you would like to provide more information that will help us help you, fill out the form below!'} />
            {pillarForm}
          </>
        )
      }

      if (houseID) {
        return (
          <>
            <Message severity={'success'} message={'Thanks for providing more information about your property! You are one step closer to finding a solution!'} />
          </>
        )
      }
      return (
        <>
          <Message severity={'success'} message={'Thanks for your inquiry! You should have received a text to schedule a call with a specialist!'} />
        </>
      )
    }

    if (msg === 'Verification e-mail sent.'){
      return (
        <>
          {waitForVerify}
        </>
      )
    }
    return (
      <>
        {introForm}
      </>
    )
  }

  // TODO: Fix form alignment on smaller screens
  // TODO: Improve spacing between form elements
  return (
    <div className="home-form-container">

      <div className="home-header-container">
        <HomeHeader check={true} isAdmin={Boolean(data?.auth?.user?.user?.is_superuser)}/>
      </div>

      <div className="cgs--home">
        <div className={`${classes.container} cgs--home-header`}>
          <div className="cgs--home-header-heading">
            Welcome to Coleman Group Solutions!
          </div>
        </div>
      </div>

      <GridContainer>
        <GridItem lg={6} >
          <div className="home-form-container-text-data">
            <div className="home-page-form-heading">
              We Have
            </div>
            <div className="home-page-form-heading">
              Creative Solutions
            </div>

            <p className="home-fee-contatiner-data">
              <span style={{ color: '#fff' }}>
                <em>
                  <b>No </b>
                  Hassle
                 <b> No </b>
                  <span>Fees</span>. Put more in your pocket
                </em>
              </span>
            </p>

            <h3 className="home-pay-heading-container">
              Talk to one of our home buying specialists that can help craft a creative solution that is tailored around YOUR needs!
            </h3>

            <p className="short-form-heading">
              Verify your identity with us and fill out the inquiry form to get started on your solution!
            </p>
          </div>
        </GridItem>
          
        <GridItem lg={6}>
          <div className="how-we-help-form-container">
            <GridContainer>
              <div className="sign-in-container">
                {getFormComponent()}
                <Loader loading={loading} />
              </div>
            </GridContainer>
          </div>
        </GridItem>

      </GridContainer>
    </div>

  );
};

export default SellerForm;


