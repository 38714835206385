import React, { useState, useRef } from 'react';
import './styles.scss'

import { Badge } from "primereact/badge";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { Chart } from "primereact/chart";
import { CiLocationOn } from 'react-icons/ci';
import {
  BsFillTelephoneOutboundFill,
  BsSpeedometer,
  BsFillCalendarCheckFill, BsRobot
} from 'react-icons/bs';
import {
  AiOutlineUpload
} from 'react-icons/ai';
import { NumericFormat, PatternFormat } from 'react-number-format';


import useLeadLists from '../../hooks/useLeadLists';
import {Bot, House, LeadList, SmsTemplate, TwilioMessage, TwilioNumber} from '../../api/types';
import {Loader, Message, ProgressCard} from "../index";
import {getError, getIntentSeverity, getTimeDiff} from "../../utils";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {Divider} from "primereact/divider";
import {Link} from "react-router-dom";
import useLeads from "../../hooks/useLeads";
import useBots from "../../hooks/useBots";
import {ZIndexUtils} from "primereact/utils";
import getBase = ZIndexUtils.getBase;


const LeadsListTable: React.FC = () => {
  
  const {
    twilioNumbers,
    getLeadLists,
    createLeadList,
    updateLeadList,
    deleteLeadList,
    contactLeadList,
    getNextPage,
    listsPageCount,
    error,
    loading
  } = useLeadLists()
  
  const { templateMsgs } = useLeads()
  const { bots, getBots } = useBots()
  
  const initRate = 20
  
  const [bot, setBot] = useState<Bot>();
  const [file, setFile] = useState<File>();
  const [twilioNumber, setTwilioNumber] = useState<TwilioNumber>();
  const [listRate, setListRate] = useState<number>(initRate);
  const [listEnabled, setListEnabled] = useState<boolean>(true);
  const [templateMsg, setTemplateMsg] = useState<SmsTemplate>();
  
  const [err, setErr] = useState<string>('');
  const [preLoading, setPreLoading] = useState<boolean>(false);
  const [next, setNext] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  
  const [numToText, setNumToText] = useState<number>(5);
  
  const [filteredLeadLists, setFilteredLeadLists] = useState<LeadList[]>([]);
  const [leadLists, setLeadLists] = useState<LeadList[]>([]);
  const [showResponsesDialog, setShowResponsesDialog] = useState<boolean>(false);
  const [contactDialog, setContactDialog] = useState<boolean>(false);
  const [leadListDialog, setLeadListDialog] = useState(false);
  const [deleteLeadListDialog, setDeleteLeadListDialog] = useState(false);
  const [deleteLeadListsDialog, setDeleteLeadListsDialog] = useState(false);
  const [leadList, setLeadList] = useState<LeadList>();
  const [selectedLeadLists, setSelectedLeadLists] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  
  React.useEffect(() => {
    setPreLoading(true)
    getLeadLists().then(async (data) => {
      setPageNumber(1)
      setFilteredLeadLists(data?.results?.filter((item: LeadList) => filterListHelper(item, globalFilter)))
      setLeadLists(data?.results?.filter((item: LeadList) => filterListHelper(item, globalFilter)))
      setNext(data?.next)
      setPreLoading(false)
    })
    getBots()
  }, [])
  
  React.useEffect(() => {
    if (next)
      getNextPage(next).then((data) => {
        console.log(data.results)
        setPageNumber(pageNumber + 1)
        setFilteredLeadLists(leadLists.concat(data?.results).filter((item: LeadList) => filterListHelper(item, globalFilter)))
        setLeadLists(leadLists.concat(data?.results).filter((item: LeadList) => filterListHelper(item, globalFilter)))
        setNext(data?.next)
      })
  }, [next])
  
  const handleUpload = (e: any) => {
    if (e?.files?.length) setFile(e?.files[0])
  }
  
  const findIndexById = (id: number) => {
    let index = -1;
    for (let i = 0; i < filteredLeadLists?.length; i++) {
      if (filteredLeadLists[i].id === id) {
        index = i;
        break;
      }
    }
    
    return index;
  }
  
  const handleContact = async (e: any, list?: LeadList) => {
    if (list) {
      const res = await contactLeadList(list, numToText)
      if (toast?.current) {
        if (res.status === 200) {
          let _leadLists = [...filteredLeadLists];
          const index = findIndexById(list.id);
          list.num_contacted += parseInt(res.data?.num_texted)
          _leadLists[index] = list;
          setLeadLists(_leadLists);
          setFilteredLeadLists(_leadLists);
          setContactDialog(false)
          // @ts-ignore
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${res.data?.num_texted} leads contacted`,
            life: 3000
          });
        }
        else {
          // @ts-ignore
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: getError(res?.data),
            life: 3000
          });
        }
      }
    }
  }
  
  const handleBotChange = (e: any) => {
    setBot(e.value)
  }
  
  const openNew = () => {
    setLeadList(undefined);
    setLeadListDialog(true);
    setErr('')
  }
  
  const hideDialog = () => {
    setErr('')
    setShowResponsesDialog(false)
    setListRate(initRate)
    setContactDialog(false)
    setLeadListDialog(false);
    setLeadList(undefined);
    setTwilioNumber(undefined)
    setFile(undefined)
    setTemplateMsg(undefined)
    setListEnabled(true)
  }
  
  const hideDeleteLeadListDialog = () => {
    setDeleteLeadListDialog(false);
  }
  
  const hideDeleteLeadListsDialog = () => {
    setDeleteLeadListsDialog(false);
  }
  
  const saveLeadList = async () => {
    if (leadList?.id) {
      const index = findIndexById(leadList.id);
      
      if (toast?.current) {
        const res = await updateLeadList(leadList.id, twilioNumber, file, listRate, templateMsg?.id, listEnabled, bot)
        if (res.status === 200) {
          let _leadLists = [...filteredLeadLists];
          _leadLists[index] = res.data;
          setLeadLists(_leadLists);
          setFilteredLeadLists(_leadLists);
          hideDialog()
          // @ts-ignore
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Lead List Updated',
            life: 3000
          });
        }
        else {
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Updating Lead List', life: 3000 });
        }
      }
    }
    else {
      if (twilioNumber && file) {
        const res = await createLeadList(twilioNumber, file, listRate, templateMsg?.id, listEnabled, bot)
        if (res.status === 201) {
          setLeadLists([...leadLists, res.data]);
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Lead List Created', life: 3000 });
          hideDialog()
        }
        else {
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Creating Lead List', life: 3000 });
        }
      }
      else if (!file) {
        setErr("File is required")
        return
      }
    }
    
  }
  
  const editLeadList = (item: LeadList) => {
    setLeadList({...item});
    setLeadListDialog(true);
    setListRate(item?.messages_per_day)
    setTemplateMsg(item?.message_template_data)
    setListEnabled(item?.enabled)
  }
  
  const showResponses = (item: LeadList) => {
    setLeadList(item);
    setShowResponsesDialog(true);
  }
  
  const confirmDeleteLeadList = (item: LeadList) => {
    setLeadList(item);
    setDeleteLeadListDialog(true);
  }
  
  const showContactLeadList = (item: LeadList) => {
    setLeadList(item);
    setContactDialog(true);
  }
  
  const handleDeleteLeadList = async () => {
    
    if (toast?.current && leadList?.id) {
      const res = await deleteLeadList(leadList?.id)
      if (res.status === 204) {
        let _items = filteredLeadLists.filter((item: LeadList) => item.id !== leadList?.id);
        setLeadLists(_items);
        setFilteredLeadLists(_items);
        setLeadList(undefined);
        setDeleteLeadListDialog(false);
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'List Deleted', life: 3000 });
        hideDialog()
      }
    }
  }
  
  const filterListHelper = (list: LeadList, query: string) => {
    const ret = list.contact_number_data.state_data.long_name.toLowerCase().includes(query.toLowerCase()) ||
      list.contact_number_data.state_data.short_name.toLowerCase().includes(query.toLowerCase()) ||
      list.contact_number_data.locality.toLowerCase().includes(query.toLowerCase()) ||
      list.contact_number_data.number.includes(query) ||
      String(list.id).includes(query)
  
    return ret
  }
  
  const handleTemplateMsgChange = (template: SmsTemplate) => {
    setTemplateMsg(template)
  }
  
  const handleGlobalFilterChange = (e: any) => {
    setGlobalFilter(e.target.value)
    const filteredLists = leadLists?.filter((list: LeadList) => filterListHelper(list, e.target.value))
    if (filteredLists)
      setFilteredLeadLists(filteredLists)
  }
  
  const exportCSV = () => {
    // @ts-ignore
    dt?.current?.exportCSV();
  }
  
  const confirmDeleteSelected = () => {
    setDeleteLeadListsDialog(true);
  }
  
  const deleteSelectedLeadLists = async () => {
    let items = filteredLeadLists.filter((item: LeadList) => {
      // @ts-ignore
      return !selectedLeadLists?.includes(item);
    });
    
    if (toast?.current) {
      await selectedLeadLists.forEach(async (item: House) => {
        await deleteLeadList(item.id)
      })
      setFilteredLeadLists(items);
      setDeleteLeadListsDialog(false);
      setSelectedLeadLists([]);
      // @ts-ignore
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Lists Deleted', life: 3000 });
    }
  }
  
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedLeadLists || !selectedLeadLists?.length} />
      </React.Fragment>
    )
  }
  
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
      </React.Fragment>
    )
  }
  
  const renderBadge = (rowData: LeadList) => {
    
    if (rowData?.num_to_contact === 0) {
      return <Badge value="DONE" severity="info" />
    }
    
    return (
      <Badge value={rowData?.enabled ? "ENABLED" : "DISABLED"} severity={rowData?.enabled ? "success" : "danger"} />
    )
  }
  
  const detailsBodyTemplate = (rowData: LeadList) => {
    const contact_number = rowData?.contact_number_data?.number.replace('+', '')
    return (
      <>
        <p>
          <CiLocationOn className="mr-3" />
          {`${rowData?.contact_number_data?.locality}, ${rowData?.contact_number_data?.state_data?.long_name}`}
        </p>
        
        <p>
          <BsFillTelephoneOutboundFill className="mr-3" />
          <PatternFormat value={contact_number} displayType={'text'} format="+# (###) ###-####" />
        </p>
        
        {rowData?.bot ? (
          <p>
            <BsRobot className="mr-3" />
            {rowData?.bot_data?.name}
          </p>
        ) : null}
        
        <p>
          <AiOutlineUpload className="mr-3" />
          <span className="text-s text-gray-500">
            Uploaded {getTimeDiff(rowData?.created)}
          </span>
        </p>
        
        {rowData?.num_to_contact > 0 ? (
          <>
            <p>
              <BsSpeedometer className="mr-3" />
              <span className="text-s text-gray-500">
                {rowData?.messages_per_day} messages per day
              </span>
            </p>
          
            <p>
              <BsFillCalendarCheckFill className="mr-3" />
              {rowData?.messages_per_day > 0 ? (
                <span className="text-s text-gray-500">
                Estimated {Math.ceil(rowData?.num_to_contact / rowData?.messages_per_day)} days left
              </span>
              ) : null}
            </p>
          </>
        ) : null}
        
        <p>
          {renderBadge(rowData)}
        </p>
      </>
    )
  }
  
  const progressBodyTemplate = (rowData: LeadList) => {
    return (
      <>
        <strong>
          Houses Researched:
        </strong>
        <div className='list-fraction'>
          {rowData.num_researched} / {rowData.num_houses}
        </div>
        <br/>
        <br/>
        <ProgressCard color='auto' numerator={rowData.num_researched} denominator={rowData.num_houses}/>
        <br/>
        <strong>
          Sellers Contacted:
        </strong>
        <div className='list-fraction'>
          {rowData.num_contacted} / {rowData.num_members}
        </div>
        <br/>
        <br/>
        <ProgressCard color='auto' numerator={rowData.num_contacted} denominator={rowData.num_members}/>
        <br/>
        <strong>
          Responded:
        </strong>
        <div className='list-fraction'>
          {rowData.num_responded} / {rowData.num_members}
        </div>
        <br/>
        <br/>
        <ProgressCard color='auto' numerator={rowData.num_responded} denominator={rowData.num_members}/>
        
        <Divider className="mt-4 mb-4"/>
        <strong>
          Interested:
        </strong>
        <div className='list-fraction'>
          {rowData.num_interested} / {rowData.num_responded}
        </div>
        <br/>
        <br/>
        <ProgressCard color='auto' numerator={rowData.num_interested} denominator={rowData.num_responded}/>
        <br/>
        <strong>
          Uninterested:
        </strong>
        <div className='list-fraction'>
          {rowData.num_uninterested} / {rowData.num_responded}
        </div>
        <br/>
        <br/>
        <ProgressCard color='auto-reverse' numerator={rowData.num_uninterested} denominator={rowData.num_responded}/>
        <br/>
        <strong>
          Stop:
        </strong>
        <div className='list-fraction'>
          {rowData.num_stop} / {rowData.num_responded}
        </div>
        <br/>
        <br/>
        <ProgressCard color='auto-reverse' numerator={rowData.num_stop} denominator={rowData.num_responded}/>
      </>
    )
  }
  
  const getStepSize = (maxValue: number) => {
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 50) {
      return 5;
    } else if (maxValue <= 100) {
      return 10;
    } else {
      return 50;
    }
  };
  
  const getBasicOptions = (rowData: LeadList) => {
    const maxValue = Math.max(...rowData.timeline_data.datasets.map((dataset: any) => Math.max(...dataset.data)))
    const stepSize = getStepSize(maxValue);
    return {
      maintainAspectRatio: false,
      aspectRatio: .6,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057',
            stepSize: stepSize
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
  };
  
  const timelineChartTemplate = (rowData: LeadList) => {
    return (
      <div className="card">
        <Chart type="line" data={rowData?.timeline_data} options={getBasicOptions(rowData)} />
      </div>
    )
  }
  
  const actionBodyTemplate = (rowData: LeadList) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editLeadList(rowData)} />
        <Button icon="pi pi-plus" className="p-button-rounded p-button-warning mr-2" onClick={() => showContactLeadList(rowData)} />
        <Button icon="pi pi-bell" className="p-button-rounded p-button-secondary mr-2" onClick={() => showResponses(rowData)} />
        <Link className="plain-link" to={`/leads/list/${rowData?.id}`}>
          <Button icon="pi pi-search" className="p-button-rounded p-button-info mr-2"/>
        </Link>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteLeadList(rowData)} />
      </React.Fragment>
    );
  }
  
  const header = (
    <div className="table-header">
      <h5>Lists Retrieved</h5>
      <ProgressCard color='auto' numerator={pageNumber} denominator={listsPageCount}/>
      <br/>
      <h5 className="mx-0 my-1">Manage Lead Lists</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => handleGlobalFilterChange(e)} placeholder="Search..." />
      </span>
    </div>
  );
  
  const leadListDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveLeadList} />
    </React.Fragment>
  );
  
  const contactListDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setContactDialog(false)} />
      <Button label="Send" icon="pi pi-check" className="p-button-text" onClick={(e) => handleContact(e, leadList)} />
    </React.Fragment>
  );
  
  const deleteLeadListDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteLeadListDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={handleDeleteLeadList} />
    </React.Fragment>
  );
  
  const deleteLeadListsDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteLeadListsDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedLeadLists} />
    </React.Fragment>
  );
  
  const selectedBotTemplate = (option: Bot, props: any) => {
    if (option) {
      return (
        <div className="bot-item bot-item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    
    return (
      <span>
          {props.placeholder}
      </span>
    );
  }
  
  const botOptionTemplate = (option: Bot) => {
    return (
      <div className="bot-item">
        <div>{option.name}</div>
      </div>
    );
  }
  
  const selectedTwilioNumberTemplate = (option: TwilioNumber, props: any) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.locality}, {option.state_data.short_name} - {option.number}</div>
        </div>
      );
    }
    
    return (
      <span>
            {props.placeholder}
        </span>
    );
  }
  
  const twilioNumberOptionTemplate = (option: TwilioNumber) => {
    return (
      <div className="country-item">
        <div>{option.locality}, {option.state_data.short_name} - {option.number}</div>
      </div>
    );
  }
  
  const selectedTemplateMsgTemplate = (option: SmsTemplate, props: any) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.template_text}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const templateMsgOptionTemplate = (option: SmsTemplate) => {
    return (
      <div className="country-item">
        <div>{option.template_text}</div>
      </div>
    );
  }
  
  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />
      <Loader dialog loading={preLoading}/>
      
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
        
        <DataTable ref={dt} value={filteredLeadLists} selection={selectedLeadLists} onSelectionChange={(e) => setSelectedLeadLists(e.value)}
                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} LeadLists"
                   header={header} responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{width: '3rem'}} exportable={false}/>
          <Column field="id" header="ID" sortable style={{minWidth: '1em'}}/>
          <Column field="details" header="Details" body={detailsBodyTemplate} sortable style={{minWidth: '12rem'}}/>
          <Column field="progress" header="Progress" body={progressBodyTemplate} style={{minWidth: '16rem'}}/>
          <Column header="Timelines" body={timelineChartTemplate} exportable={false} style={{minWidth: '10rem'}}/>
          <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}/>
        </DataTable>
      </div>
      
      <Dialog visible={leadListDialog} style={{ width: '450px' }} header="Ingest Leads" modal className="p-fluid" footer={leadListDialogFooter} onHide={hideDialog}>
        <div className="field">
          <label htmlFor="upload">Leads File</label>
          <div className="field">
            <FileUpload mode="basic" onSelect={handleUpload} name="upload" accept=".csv" chooseLabel="Ingest File" className="mr-2 inline-block" onUpload={handleUpload} />
          </div>
        </div>
        
        <div className="field">
          <label htmlFor="rate">Messages Per Day</label>
          <div className="field">
            <InputNumber name="rate" style={{ width: '385px' }} placeholder='Number of contacts to text per day' inputId="integeronly" value={listRate} onValueChange={(e) => setListRate(e.value || 0)} />
          </div>
        </div>
        
        <div className="field">
          <label htmlFor="number">Twilio Number</label>
          <div className="field">
            <Dropdown
              value={twilioNumber}
              options={twilioNumbers}
              onChange={(e) => setTwilioNumber(e.value)}
              optionLabel="twilioNumber"
              placeholder="Select Twilio Number"
              valueTemplate={selectedTwilioNumberTemplate}
              itemTemplate={twilioNumberOptionTemplate}
            />
          </div>
        </div>
        
        <div className="field">
          <label htmlFor="templateMsg" className="block">Template Message</label>
          <div className="field">
            <Dropdown
              id="templateMsg"
              style={{ width: '385px' }}
              value={templateMsg}
              options={templateMsgs}
              onChange={(e) => {
                handleTemplateMsgChange(e.value)
              }}
              optionLabel="templateMsg"
              placeholder="Select Template Message"
              valueTemplate={selectedTemplateMsgTemplate}
              itemTemplate={templateMsgOptionTemplate}
            />
          </div>
          
          <label htmlFor="bot" className="block">Bot</label>
          <div className="field">
            <Dropdown
              id="bot"
              style={{ width: '385px' }}
              value={bot}
              options={bots}
              onChange={handleBotChange}
              optionLabel="bot"
              placeholder="Select Bot"
              valueTemplate={selectedBotTemplate}
              itemTemplate={botOptionTemplate}
            />
          </div>
        </div>
    
        <div className="field">
          <label htmlFor="listEnabled" className="block">Enabled</label>
          <Checkbox inputId="cb1" name="listEnabled" checked={listEnabled} value="Enabled" onChange={(e) => setListEnabled(e.checked)}/>
        </div>
        
        <Loader loading={loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
  
      <Dialog visible={showResponsesDialog} style={{ width: '450px' }} header="Responses" modal onHide={hideDialog}>
        <div className="responses-content">
          {leadList?.responses?.length ? leadList.responses.map((message: TwilioMessage) => (
            <p>
              <strong>{message?.member_name}</strong>
              
              <>
                <span className="float-right text-xs text-gray-500">
                  {getTimeDiff(message.created ?? "")}
                </span>
                {message.direction === 'INBOUND' ? (
                  <Badge
                    className='float-right mr-1'
                    severity={getIntentSeverity(message?.intent ?? '')}
                    value={message?.intent ?? 'UNKNOWN'}
                  />
                ) : null}
              </>
              
              <div className='mt-3'>{message.message}</div>
              <Divider/>
            </p>
          )) : (
            <strong>No responses yet</strong>
          )}
        </div>
      </Dialog>
  
      <Dialog visible={contactDialog} style={{ width: '450px' }} header="Contact" modal footer={contactListDialogFooter} onHide={hideDialog}>
        <div className="confirmation-content">
          <div className="col-12">
            <InputNumber style={{ width: '385px' }} placeholder='Number of contacts to text' inputId="integeronly" value={numToText} onValueChange={(e) => setNumToText(e.value || 0)} />
          </div>
          <Message
            severity={'error'}
            message={error}
          />
          <Loader loading={loading} />
        </div>
      </Dialog>
      
      <Dialog visible={deleteLeadListDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteLeadListDialogFooter} onHide={hideDeleteLeadListDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {leadList && <span>Are you sure you want to delete list <b>{leadList.id}</b>?</span>}
        </div>
      </Dialog>
      
      <Dialog visible={deleteLeadListsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteLeadListsDialogFooter} onHide={hideDeleteLeadListsDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {selectedLeadLists?.length && <span>Are you sure you want to delete the selected list of leads?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default LeadsListTable;
