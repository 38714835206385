import {useEffect, useState} from 'react'
import {AxiosResponse} from 'axios';
import {Bot, BotInstruction, SmsTemplate, State, TwilioNumber, User} from '../api/types'
import {deleteData, getData, patchData, postData} from '../api/http';
import {getError} from '../utils';


export default function useBots() {
  const [loading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [error, setError] = useState<string>('');
  
  const [botInstructions, setBotInstructions] = useState<BotInstruction[]>([]);
  const [bots, setBots] = useState<Bot[]>([]);
  
  const [botInstructionsPageCount, setBotInstructionsPageCount] = useState<number>(0);
  const [botsPageCount, setBotsPageCount] = useState<number>(0);
  const pageLength = 10
  
  const getNextPage = async (uri: string): Promise<State[]> => {
    setLoading(true)
    return getData(uri)
    .then((data) => {
      setLoading(false)
      return data
    }).catch(({ response }) => console.log(response));
  }
  
  const getBots = async (owner?: User, page?: number): Promise<any> => {
    setLoading(true)
    
    let url = 'bots/?'
    if (owner) url += `owner_id=${owner.id}&`
    if (page)
      url += `page=${page}&`
    
    return getData(url)
    .then(({ count, results }) => {
      console.log('count', count)
      console.log('results', results)
      setBotsPageCount(Math.ceil(count/pageLength))
      setBots(results)
      setLoading(false)
      return results;
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
      return err;
    });
  }
  
  const getDealQualifierBot = async (): Promise<any> => {
    setLoading(true)
    
    let url = 'bots/qualifier'
    
    return getData(url)
      .then(data => {
        setLoading(false)
        return data;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
        return err;
      });
  }
  
  const getBotInstructions = async (page?: number): Promise<any> => {
    setLoading(true)
    
    let url = 'bot-instructions/?'
    if (page)
      url += `page=${page}&`
    
    return getData(url)
    .then(({ count, results }) => {
      console.log('count', count)
      console.log('results', results)
      setBotInstructionsPageCount(Math.ceil(count/pageLength))
      setBotInstructions(results)
      setLoading(false)
      return results;
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
      return err;
    });
  }
  
  const deleteBot = async (botId: number): Promise<any> => {
    setLoading(true)
    return deleteData(`bots/${botId}`)
    .then((res) => {
      setLoading(false)
      return res;
    })
    .catch(() => {
      setLoading(false)
    });
  }
  
  const contactBot = async (bot: Bot, message: string, dealId?: number) => {
    setLoading(true)
    setMsg('')
  
    const formData = new FormData();
    formData.append('message', message);
    if (dealId) formData.append('deal_id', dealId.toString());
  
    return postData(`bots/${bot.id}/message`, formData)
    .then((res) => {
      setLoading(false)
      setMsg(res?.data?.msg)
      return res
    })
    .catch((err) => {
      setError(err)
      setLoading(false)
      console.log(err);
    });
  }
  
  const createBot = async (
    name: string,
    provider?: string,
    instructionIds?: string[],
    isDealQualifier?: boolean
  ): Promise<AxiosResponse> => {
    setLoading(true)
    setMsg('')
    
    const formData = {
      name,
      provider: provider?.toUpperCase(),
      instruction_ids: instructionIds,
      is_deal_qualifier: isDealQualifier
    };
    
    return postData('bots/', formData)
    .then((res: any) => {
      setLoading(false)
      if (res.status !== 201) {
        setError(getError(res.data))
        return res
      }
      const tmpBots = bots
      tmpBots.unshift(res.data)
      setBots(tmpBots)
      return res
    })
    .catch((err) => {
      setError(err)
      setLoading(false)
      console.log(err);
    });
    
  }
  
  const createBotInstruction = async (instruction: string): Promise<AxiosResponse> => {
    setLoading(true)
    setMsg('')
    
    const formData = {
      instruction
    };
    
    return postData('bot-instructions/', formData)
    .then((res: any) => {
      setLoading(false)
      if (res.status !== 201) {
        setError(getError(res.data))
        return res
      }
      const tmpBotInstructions = botInstructions
      tmpBotInstructions.unshift(res.data)
      setBotInstructions(tmpBotInstructions)
      return res
    })
    .catch((err) => {
      setError(err)
      setLoading(false)
      console.log(err);
    });
    
  }
  
  const updateBotInstruction = async (
    botInstructionId: number,
    instruction: string,
  ) => {
    setLoading(true)
    setMsg('')
    
    const formData = {
      instruction
    }
    
    return patchData(`bot-instructions/${botInstructionId}`, formData)
  }
  
  const addInstructions = async (botId: number, instructions: BotInstruction[]) => {
    setLoading(true)
    setMsg('')
    
    const formData = {
      instructions: instructions.map((item) => {
        return item.id
      })
    }
    
    return postData(`bots/${botId}/instructions`, formData).then((res: any) => {
      setLoading(false)
      
      if (res.status !== 200) {
        setError(getError(res.data))
        return res
      }
      
      return res
    })
    
  }
  
  const removeInstruction = async (bot: Bot, instruction: BotInstruction) => {
    setLoading(true)
    setMsg('')
    
    const formData = {
      instruction_id: instruction.id
    }
    
    return postData(`bots/${bot?.id}/remove_instruction`, formData).then((res: any) => {
      setLoading(false)
      console.log(res)
      return res
    })
    
  }
  
  const clearBotConversation = async (bot?: Bot) => {
    setLoading(true)
    setMsg('')
    
    return getData(`bots/${bot?.id}/clear_messages`).then((res: any) => {
      setLoading(false)
      console.log(res)
      return res
    })
    
  }
  
  const updateBot = async (
    botId: number,
    name?: string,
    provider?: string,
    instructionIds?: string[],
    isDealQualifier?: boolean
  ): Promise<AxiosResponse> => {
    setLoading(true)
    setMsg('')
    
    const formData = {
      name,
      provider: provider?.toUpperCase(),
      instruction_ids: instructionIds,
      is_deal_qualifier: isDealQualifier
    };
    
    return patchData(`bots/${botId}`, formData)
    .then((res: any) => {
      setLoading(false)
      if (res.status !== 200) {
        setError(getError(res.data))
        return res
      }
      const tmpBots = bots
      tmpBots.unshift(res.data)
      setBots(tmpBots)
      return res
    })
    .catch((err) => {
      setError(err)
      setLoading(false)
      console.log(err);
    });
    
  }
  return {
    loading,
    msg,
    error,
    bots,
    botsPageCount,
    getNextPage,
    getBots,
    getDealQualifierBot,
    deleteBot,
    contactBot,
    createBot,
    updateBot,
    getBotInstructions,
    createBotInstruction,
    updateBotInstruction,
    addInstructions,
    removeInstruction,
    clearBotConversation,
  }
}
