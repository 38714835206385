import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AOS from 'aos';

import GridContainer from '../../kit-components/Grid/GridContainer';
import GridItem from '../../kit-components/Grid/GridItem';
import SectionCards from '../section-cards';
import SellerForm from '../../forms/seller-form';
import { NeedToSell, WaysCard } from '..';


import styles from '../../assets/jss/material-kit-react/views/componentsSections/tabsStyle';
import { fadeUp } from '../../utils/animationsName'


import './style.scss';
import homegear from '../../assets/img/homegear.png';
import dollarsign from '../../assets/img/dollar-sign.png';
import simplesystem from '../../assets/img/simplesystem.jpg';

const useStyles = makeStyles(styles);

export default function SectionTabs() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({
      duration: 1
    })
  }, [])

  return (
    <div>
      <div>
        <div id='nav-tabs'>
          <SellerForm />
          <div className={`${classes.section} section-tab-header`}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={11} md={12} >
                  <div className='home-view-heading-container'>
                    <div className="home-view-about-us-desc home-view-heading">
                      We purchase properties at any price and any condition
                    </div>

                    <div className="home-view-about-us-desc">
                      Want to discover how our process works? Make sure to sign in and submit an inquiry above!
                    </div>

                    <div className="home-view-about-us-desc">
                      We’re prepared with creative solutions!
                    </div>
                  </div>
                </GridItem>
                <GridItem className="section-card-grid-container" xs={12} sm={12} md={6} lg={4}>
                  <SectionCards
                    dataAos={fadeUp}
                    heading="1. Contact"
                    className={'section-card-1'}
                    desc="Please catch us up to speed with the situation at hand so that we can learn more about the value of the property. This will help us prepare as many personalized solutions as possible!
                    "
                  />
                </GridItem>
                <GridItem className="section-card-grid-container" xs={12} sm={12} md={6} lg={4}>
                  <SectionCards
                    dataAos={fadeUp}
                    heading="2. Discuss & Craft Solution"
                    className={'section-card-2'}
                    desc="We’ll present to you all the options available so that all you are left to do is decide what solution suits you the best!"
                  />
                </GridItem>

                <GridItem className="section-card-grid-container" xs={12} sm={12} md={6} lg={4}>
                  <SectionCards
                    dataAos={fadeUp}
                    heading="3. Close on your date of choice"
                    className={'section-card-3'}
                    desc=" Pick your closing date of choice once we come up with your solution so you can get paid!"
                  />
                </GridItem>

                <div className='home-view-heading-container'>
                  <NeedToSell />
                </div>

                <GridItem xs={11} lg={4}  >
                  <WaysCard image={homegear} desc="If you just want to be done with your property in Chicago, we understand. We buy houses and it is our goal to show you how easy selling a house can be, on your timeline! So if you've been thinking, 'I need to sell my house fast in Chicago, IL,' we know we have the ability to include you as another satisfied customer." heading="Discover creative solutions for your problems on your terms!" />
                </GridItem>
                <GridItem xs={11} lg={4}  >
                  <WaysCard image={dollarsign} desc="We make sure YOU come first so that you have a peace of mind knowing we always have YOUR best interest. We have encountered every possible situation and we are confident that we can help you with yours and give you the solution you’ve been seeking. We have home buying specialists willing to work non-stop to provide the highest level care and professionalism to ensure you win." heading="We Are Professional Real Estate Solution Specialists and Problem Solvers 
" />
                </GridItem>
                <GridItem xs={11} lg={4}  >
                  <WaysCard image={simplesystem} desc="Thousands of Americans are exploring their options when it comes to selling their house the fastest, easiest, and most transparent way. That’s where we come in. We offer an alternative for those who may not have the time or ability to sell traditionally. We’ll work with you to help solve your situation, offer a transparent solution and close on your timeline." heading='We’ve Heard It All! Regardless of Your Situation, We Want To Help!
' />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
