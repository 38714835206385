import React from 'react';
import { useSelector } from 'react-redux';
import {Grid, LeadsListTable, TwilioFlex} from '../../components';
import './style.scss';

const Ingest: React.FC = () => {
  
  const data = useSelector((store: any) => store);
  
  const crmComponent = (
    <div>
      <br/>
      {data?.auth?.user?.user?.is_superuser ? (
        <>
          <TwilioFlex/>
          <LeadsListTable/>
        </>
      ) : <h2>Please log in</h2>}
    </div>
  );

  return (
    <div className="how-we-help-main-container">
      <Grid
        items={[
          {
            id: 'lg-rules',
            cols: { xs: 12, sm: 12, md: 12, lg: 11 },
            content: crmComponent,
            rows: 1,
          },
        ]}
      />
    </div>
  )
}

export default Ingest
