import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import './styles.scss';

interface TooltipProps {
  children: React.ReactNode
  title: string
}


const TooltipComponent = ({ children, title }: TooltipProps) => {
  
  return (
    <Tooltip
      title={title}
      arrow
      classes={{
        popper: 'cgs--popper',
        tooltip: 'cgs--tooltip'
      }}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default TooltipComponent;
