import React from 'react';
import { TextField } from '..';

// import { TextField } from '@material-ui/core';
import GridContainer from '../../kit-components/Grid/GridContainer';
import GridItem from '../../kit-components/Grid/GridItem';

import './style.scss';

interface AddressFormProps {
  check: boolean;
}

const AddressForm: React.FC<AddressFormProps> = ({ check }) => {
  return (
    <GridItem lg={12}>
      <div className="how-we-help-form-container">
        <GridContainer >
          <GridItem lg={12}>
            <TextField
              value=""
              id="firstName"
              placeholder="First Name"
              onChange={() => console.log('onChange')}
            />
          </GridItem>
          <GridItem lg={12}>
            <TextField
              value=""
              id="lastName"
              placeholder="Last Name"
              onChange={() => console.log('onChange')}
            />
          </GridItem>
          <GridItem lg={12}>
            <TextField
              value=""
              id="address"
              placeholder="Address"
              onChange={() => console.log('onChange')}
            />
          </GridItem>
          <GridItem lg={12}>
            <TextField
              value=""
              id="email"
              type="email"
              placeholder="Email"
              onChange={() => console.log('onChange')}
            />
          </GridItem>
          <GridItem lg={12}>
            <TextField
              value=""
              id="phone"
              // type="number"
              placeholder="Phone*"
              onChange={() => console.log('onChange')}
            />
          </GridItem>
          <GridItem lg={12}>
            <div className="address-form-get-best-offer">GET YOUR BEST OFFER</div>
          </GridItem>
        </GridContainer>
        {/* <GridContainer >
          <GridItem lg={6}>
            <TextField id="standard-basic" label="First" variant="standard" />
          </GridItem>
          <GridItem lg={6}>
            <TextField id="standard-basic" label="Last" variant="standard" />
          </GridItem>
          <GridItem lg={12}>
            <TextField id="standard-basic" label="Property Address" variant="standard" />
          </GridItem>
          <GridItem lg={6}>
            <TextField id="standard-basic" label="Email*" variant="standard" />
          </GridItem>
          <GridItem lg={6}>
            <TextField type="number" id="standard-basic" label="Phone*" variant="standard" />
          </GridItem>
          <GridItem lg={12}>
            <div className="address-form-get-best-offer">GET YOUR BEST OFFER</div>
          </GridItem>
        </GridContainer> */}
      </div>
    </GridItem>
  )
};


export default AddressForm