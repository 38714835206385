import { patchData } from './http';
import {Deal, DealType, Member} from './types';


export const patchNames = async (firstname: string, lastname: string): Promise<void> => {
  const formData = new FormData();
  formData.append('first_name', firstname);
  formData.append('last_name', lastname);

  return patchData('auth/user/', formData)
    .then(data => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const patchCanText = async (member: Member, markUninterested: boolean): Promise<void> => {
  const formData = new FormData();
  formData.append('can_text', String(!markUninterested));
  formData.append('can_email', String(!markUninterested));

  return patchData(`members/${member.id}`, formData)
    .then(data => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const patchHouse = async (houseID: string, condition: string, price: string, sellReasons: string, sellTimeline: string): Promise<void> => {
  const formData = new FormData();
  formData.append('condition', condition);
  formData.append('price_range_upper', price);
  formData.append('sell_reasons', sellReasons);
  formData.append('sell_timeline', sellTimeline);

  return patchData(`houses/${houseID}/`, formData)
    .then(data => {
      return data;
    })
    .catch((error) => {
      console.log('PATCH HOUSE ERROR:', error);
      return error;
    });
};


export const patchDeal = async (
  deal: Deal,
  dealType: DealType,
  stage: string,
  priority: string,
  sellerPrice: string,
  buyerPrice: string,
  dealPrice: string
): Promise<Deal> => {
  const formData = new FormData();
  formData.append('deal_type', String(dealType?.id));
  formData.append('stage', stage);
  formData.append('priority', priority);
  formData.append('seller_price', sellerPrice);
  formData.append('buyer_price', buyerPrice);
  formData.append('deal_price', dealPrice);
  
  return patchData(`deals/${deal?.id}/`, formData)
  .then(data => {
    return data;
  })
  .catch((error) => {
    console.log(error);
    return error;
  });
};


