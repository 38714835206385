import * as React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import { Button } from '@mui/material';
import { FaPhoneAlt } from 'react-icons/fa';
import { VscThreeBars } from 'react-icons/vsc';
import {useDispatch, useSelector} from 'react-redux';

import { socialLogout } from '../../store/actions/auth'
import {CURRENT_URL, gcsBucket, GOOGLE_NUMBER} from '../../utils';
import { SideDrawer } from '..';
import './styles.scss';


const Header: React.FC = () => {
  const location = useLocation();
  const data = useSelector((store: any) => store);
  const dispatch = useDispatch();
  
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleLogout = () => {
    dispatch(socialLogout())
  }

  const isLogin = Boolean(data?.auth?.user?.user)
  const isAdmin = Boolean(data?.auth?.user?.user?.is_superuser)

  return (
    <>
      <AppBar
        position="fixed"
        className="cgs--header"
        classes={{ colorPrimary: 'cgs--header-primary' }}
      >
        <SideDrawer route={location.pathname.replace('/', '')} handleLogout={handleLogout} isLogin={isLogin} isAdmin={isAdmin} open={drawerOpen} setOpen={setDrawerOpen} />
        <Toolbar className="cgs--header__toolbar">
          <Link to="/home">
            <img
              height={81}
              width={120}
              src={`${gcsBucket}/cgs.png`}
              alt="N/A"
            />
          </Link>
          <div className="growing-header-container">
            <div className="growing-header-container-left">
              Growing Communities
            </div>
            <div>{'&'}</div>
            <div className="growing-header-container-right">
              Helping Neighbors
            </div>
            {isLogin ? <div className="sign-out-btn" onClick={() => handleLogout()} >Sign Out</div> :(
              <a href={`${CURRENT_URL}api/rest/login/`}>
                <div id='login-btn' className='login-btn'>
                  <Button>Log In</Button>
                </div>
              </a>
            )}
          </div>
          <div className="header-free-container feel-free-hover">
            <div>
              Feel free to call
            </div>
            <div className="header-free-phone-container">
              <div className="header-free-phone-container-icon">
                <FaPhoneAlt />
              </div>
              ‪{GOOGLE_NUMBER}‬
            </div>
          </div>
          <div className="mobile-side-menu">
            <div className="openDrawer" onClick={() => setDrawerOpen(true)}>
              <VscThreeBars size={22} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
