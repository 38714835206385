import { getData } from './http';
import {House, Member, Question, State, Testimonial, User} from './types';


export const getNextPage = async (uri: string): Promise<any[]> => {
  return getData(uri)
  .then((data) => {
    return data
  }).catch(({ response }) => console.log(response));
}


export const getCurrentUser = async (): Promise<User> => {
  return getData('check/')
    .then(({ results }) => {
      if (!results?.length) throw Error(results)
      return results[0];
    })
    .catch(({ response }) => {
      return response;
    });
}

export const getUser = async (username: string): Promise<User> => {
  return getData(`users/?username=${username}`)
    .then(({ results }) => {
      if (!results?.length) throw Error(results)
      return results[0];
    })
    .catch(({ response }) => {
      return response;
    });
}

export const getTestimonials = async (): Promise<Testimonial[]> => {
  return getData('testimonials/')
    .then((data: Testimonial[]) => {
      return data;
    })
    .catch(({ response }) => {
      return response;
    });
}


export const getQuestions = async (): Promise<Question[]> => {
  return getData('questions/')
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}