const fadeUp = 'fade-up';
const fadeDown = 'fade-down';
const fadeRight = 'fade-right';
const fadeLeft = 'fade-left';

const slideUp = 'slide-up';

const fadeZoomOut = 'zoom-out';

const datAosOnce = 'false'


export { fadeUp, fadeDown, fadeRight, fadeLeft, slideUp, fadeZoomOut, datAosOnce }