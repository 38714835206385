import React from 'react'
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import useBots from "../../hooks/useBots";
import {Deal} from "../../api/types";

interface ChatWindowProps {
  deal: Deal
}

const ChatWindowComponent: React.FC<ChatWindowProps> = ({ deal }) => {
  
  const {
    getDealQualifierBot,
    contactBot,
    loading
  } = useBots()
  
  const handleNewUserMessage = async (newMessage: string) => {
    
    const bot = await getDealQualifierBot();
    
    const res = await contactBot(bot, newMessage, deal?.id)
    if (res?.status === 200)
      addResponseMessage(res.data?.response?.message)
    else
      addResponseMessage("Error sending message")
  }
  
  return (
    <Widget
      title="Deal Qualifier"
      subtitle="Chat with Deal Qualifier about your deal"
      handleNewUserMessage={handleNewUserMessage}
      toggleMessageLoader={loading}
    />
  )
}

export default ChatWindowComponent


