import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';
import AOS from 'aos';

import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-blue/theme.css'


import 'primereact/resources/primereact.min.css'

import BaseRouter from './utils/router';
import { Layout } from './components';
import './style/main.scss';
import 'aos/dist/aos.css'
import {LIFECYCLE} from './utils';


if (LIFECYCLE === 'prod') LogRocket.init('n0phks/prod-coleman');

declare global {
  interface Window {
    LIFECYCLE: string,
    API_URL: string;
    APP_CODE: string;
    MAPS_KEY: string;
    PUBLIC_BUCKET: string;
    GOOGLE_NUMBER?: string,
  }
}

const App: React.FC = () => {
  // const [user, loading, err] = useAuthState(auth);
  const data = useSelector((store: any) => store);

  useEffect(() => {
    AOS.init({
      duration: 4000
    })
  }, [])
  return (
    <div className="cgs">
      <Layout>
        <BaseRouter data={data} />
      </Layout>
    </div>)
};

export default App;
