import React from 'react';
import { useSelector } from 'react-redux';
import {Grid} from '../../components';
import './style.scss';

const Dashboards: React.FC = () => {
  
  const data = useSelector((store: any) => store);
  
  const dashboardsComponent = (
    <div>
      <br/>
      {data?.auth?.user?.user?.is_superuser ? (
        <>
          <iframe width="1000" height="750"
            src="https://datastudio.google.com/embed/reporting/9a77be56-42c9-42a0-9dc7-7aab9ac75a9e/page/ke02C"
            frameBorder="0" allowFullScreen/>
        </>
      ) : <h2>Please log in</h2>}
    
    </div>
  );
  
  return (
    <div className="how-we-help-main-container">
      <Grid
        items={[
          {
            id: 'lg-rules',
            cols: { xs: 12, sm: 10, md: 10, lg: 10 },
            content: dashboardsComponent,
            rows: 1,
          },
        ]}
      />
    </div>
  )
}

export default Dashboards
