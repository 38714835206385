import * as React from 'react';
import {Dialog} from 'primereact/dialog';
// import {BallTriangle} from "react-loader-spinner";

interface LoaderProps {
  loading: boolean
  dialog?: boolean
}

const LoaderComponent: React.FC<LoaderProps> = ({ loading, dialog }) => {
  
  const loader = (
    <div>Loading</div>
  //   <BallTriangle
  //     height="60"
  //     width="60"
  //     color="#0099cc"
  //     ariaLabel="ball-triangle-loading"
  //     wrapperStyle={{}}
  //     wrapperClass=""
  //     visible={true}
  //   />
  )
  
  if (loading)
    return (
      <>
        {dialog ? (
          <Dialog visible={loading} style={{ width: '450px' }} header="Fetching Data..." modal className="p-fluid" onHide={() => console.log('hide')}>
            {loader}
          </Dialog>
        ) : (
          <>
            {loader}
          </>
        )}
      </>
    );
  return null;
};

export default LoaderComponent;
