/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
import GridContainer from '../../kit-components/Grid/GridContainer';
import GridItem from '../../kit-components/Grid/GridItem';
// nodejs library that concatenates classes
import classNames from 'classnames';
// material-ui core components
import { makeStyles } from '@material-ui/core/styles';
// import { Facebook, Instagram } from '@material-ui/icons';

import {gcsBucket, GOOGLE_NUMBER} from "../../utils";
import styles from '../../assets/jss/material-kit-react/components/footerStyle';
import './style.scss'

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();
  return (
    <footer>
      <div className={classes.container}>
        <div className="footer-container" >
          <div>
            <GridContainer>

              <GridItem xs={11} lg={3}>
                <img className="footer-container__image" src={`${gcsBucket}/cgs.png`} />
                <div className="footer-container__desc"><p><span>Our goal at <span className="info-name shortcode"><span className="info-name">Coleman Group Solutions</span></span> is to rejuvenate neighborhoods to help increase the standard of living by improving the overall quality of housing for the residents. We buy houses from individuals in just about any situation including foreclosure, behind on mortgage payments or just need cash fast. Contact us today and let’s see how we can work together!</span></p></div>
              </GridItem >

              <GridItem xs={11} lg={3}>
                <div>
                  <h2 className="footer-container__list-title">Our Company</h2>
                </div>
                <div className="footer-container__list-items">
                  <p className="footer-container__list-items__title"><Link to="contact">Contact Us</Link></p>
                  <p className="footer-container__list-items__title"><Link to="howwehelp">How We Help</Link></p>
                  <p className="footer-container__list-items__title"><Link to="whywehelp">Why We Help</Link></p>
                  <p className="footer-container__list-items__title"><Link to="faq">FAQs</Link></p>
                  <p className="footer-container__list-items__title"><Link to="testimonials">Testimonials</Link></p>
                </div>
              </GridItem>

              {/* <GridItem xs={11} lg={3}> */}
              {/*   <div> */}
              {/*     <h2 className="footer-container__list-title">Who we help</h2> */}
              {/*   </div> */}
              {/*   <div className="footer-container__list-items"> */}
              {/*     <p className="footer-container__list-items__title"><a href="#">Sellers</a></p> */}
              {/*     <p className="footer-container__list-items__title"><a href="#">Buyers</a></p> */}
              {/*     <p className="footer-container__list-items__title"><a href="#">Investors</a></p> */}
              {/*     <p className="footer-container__list-items__title"><a href="#">Agents</a></p> */}
              {/*     <p className="footer-container__list-items__title"><a href="#">Contractors</a></p> */}
              {/*   </div> */}
              {/* </GridItem> */}

              <GridItem xs={11} lg={3}>
                <div>
                  <h2 className="footer-container__list-title">Contact Us</h2>
                </div>
                <div className="footer-container__list-items">
                  <p className="footer-container__list-items__title">1 E Erie St Suite 525-2433,</p>
                  <p className="footer-container__list-items__title">Chicago, IL 60611</p>
                  <p className="footer-container__list-items__number">‪{GOOGLE_NUMBER}</p>
                  <p className="footer-container__list-items__email">contact@colemangroupsolutions.com</p>
                  {/* <div className="footer-container__list-items__social-container">
                  <div className="footer-container__list-items__social-container-icon social-icon-right">
                    <Facebook className="footer-container__list-items__social-container-icon__icon color-social-white" fontSize='large' />
                  </div>
                  <div className="footer-container__list-items__social-container-icon social-icon-right">
                    <Instagram className="footer-container__list-items__social-container__icon" fontSize='large' />
                  </div>
                </div> */}
                </div>
              </GridItem>

              <GridItem xs={11} lg={12}>
                <div className="footer-container__border"></div>
                <div className="footer-container__copy-right"><p>Copyright © {new Date().getFullYear()} <span className="info-name">Coleman Group Solutions</span>. All rights reserved. Powered by <a className='footer-container__consulting-text' href="">TBL Cloud Platform</a></p></div>

              </GridItem>
            </GridContainer>

          </div>
        </div>
      </div>
    </footer>
  );
}
