/* eslint-disable */
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import AOS from 'aos';
import Footer from '../../kit-components/Footer/Footer';
import { Grid, SectionTabs } from '../../components';

import './styles.scss';


const Home: React.FC<RouteComponentProps> = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 2000
    })
  }, [])
  
  const homeComponent = (
    <div>
      <SectionTabs />
      <div className="footer-background">
        <Footer />
      </div>
    </div>
  );
  
  return (
    <Grid
      items={[
        {
          id: 'cgs--login',
          cols: { xs: 12, sm: 12, md: 12, lg: 12 },
          content: homeComponent,
          rows: 2
        }
      ]}
    />
  );
};

export default withRouter(Home);
