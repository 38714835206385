import React from 'react';
import {NavLink} from 'react-router-dom';
import { LIFECYCLE, CURRENT_URL } from '../../utils';
import './style.scss';
import {crmRoutes} from "../../utils/router";

interface HeaderflowProps {
  route: string,
  check?: boolean,
  isAdmin?: boolean,
}

const Headerflow: React.FC<HeaderflowProps> = ({ route, isAdmin }) => {
  
  const baseRoute = route?.split('/')[0]
  
  return (
    <div className='header-flow-item'>
      <NavLink activeClassName='link-focused' className={'header-flow-item-list'} to="/home">Home</NavLink>
      {baseRoute && !crmRoutes.includes(baseRoute) ? (
        <>
          <NavLink activeClassName="link-focused" className={'header-flow-item-list'} to='/contact'>Contact Us</NavLink>
          <NavLink activeClassName='link-focused' className={'header-flow-item-list'} to='/whywehelp'>Why we help</NavLink>
          <NavLink activeClassName='link-focused' className={'header-flow-item-list'} to='/howwehelp'>How we help</NavLink>
          <NavLink activeClassName='link-focused' className={'header-flow-item-list'} to='/faq'>FAQ</NavLink>
          {LIFECYCLE !== 'prod' ? (
            <NavLink activeClassName='link-focused' className={'header-flow-item-list'} to='/testimonials'>Testimonials</NavLink>
          ) : null}
        </>
        ) : null}
      {isAdmin ? (
        <>
          {baseRoute && crmRoutes.includes(baseRoute) ? (
            <>
              {crmRoutes.map((item: string, index: number) => (
                <NavLink
                  key={index}
                  activeClassName='link-focused'
                  className={'header-flow-item-list'}
                  to={`/${item}`}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </NavLink>
              ))}
            </>
          ) : (
            <NavLink activeClassName='link-focused' className={'header-flow-item-list'} to='/ingest'>CRM</NavLink>
          )}
          
          <a className={'header-flow-item-list'} href={`${CURRENT_URL}admin/`}>Admin</a>
        </>
        ) : null}
    </div >
  )
}

export default Headerflow
