import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import {useParams} from "react-router-dom";

import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import {Divider} from "primereact/divider";
import { Toast } from 'primereact/toast';
import {Button} from "primereact/button";
import {Chart} from "primereact/chart";

import './style.scss';
import {ChatWindow, Grid, Message} from '../../components';
import useDeals from "../../hooks/useDeals";
import {Deal} from "../../api/types";
import useLeads from "../../hooks/useLeads";
import {
  calculateCapRate, calculateCashOnCashReturn,
  calculateExpenseRatio,
  calculateMonthlyMortgagePayment
} from "./calculate";

interface DealParams {
  id: string
}

const DealQualifier: React.FC = () => {
  
  const data = useSelector((store: any) => store);
  
  const {updateLead, deserializeTextAreaInput, serializeTextAreaInput} = useLeads()
  
  const { id } = useParams<DealParams>()
  
  const {getDeal, updateDeal} = useDeals();
  
  const [deal, setDeal] = useState<Deal>();
  
  const [purchasePrice, setPurchasePrice] = useState<number>();
  
  const [income, setIncome] = useState<string>();
  const [incomeError, setIncomeError] = useState<string>();
  const [totalMonthlyIncome, setTotalMonthlyIncome] = useState<number>();
  
  const [expenses, setExpenses] = useState<string>();
  const [expensesError, setExpensesError] = useState<string>();
  const [monthlyOperatingExpenses, setMonthlyOperatingExpenses] = useState<number>();
  
  const [startupCosts, setStartupCosts] = useState<string>();
  const [startupCostsError, setStartupCostsError] = useState<string>();
  const [totalStartupCosts, setTotalStartupCosts] = useState<number>();
  
  const [bankInterestRate, setBankInterestRate] = useState<number>();
  const [bankCarryPercentage, setBankCarryPercentage] = useState<number>();
  const [sellerInterestRate, setSellerInterestRate] = useState<number>();
  const [sellerCarryPercentage, setSellerCarryPercentage] = useState<number>();
  
  const [monthlyBankMortgagePayment, setMonthlyBankMortgagePayment] = useState<number>();
  const [monthlySellerMortgagePayment, setMonthlySellerMortgagePayment] = useState<number>();
  
  const [totalMonthlyExpenses, setTotalMonthlyExpenses] = useState<number>()
  const [expenseRatio, setExpenseRatio] = useState<number>();
  const [netMonthlyIncome, setNetMonthlyIncome] = useState<number>();
  const [capRate, setCapRate] = useState<number>();
  const [cashOnCashReturn, setCashOnCashReturn] = useState<number>();
  
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  
  const toast = useRef(null);
  
  useEffect(() => {
    if (id) {
      getDeal(id).then((deal) => {
        if (!deal) return
        
        setDeal(deal)
        setExpenseRatio(deal.expense_ratio * 100)
        setPurchasePrice(deal.seller_price)
        setBankInterestRate(deal.bank_interest_rate)
        setBankCarryPercentage(deal.bank_carry_pct)
        setSellerInterestRate(deal.owner_interest_rate)
        setSellerCarryPercentage(deal.owner_carry_pct)
        setIncome(deserializeTextAreaInput(deal.house_data?.monthly_income))
        setExpenses(deserializeTextAreaInput(deal.house_data?.monthly_expenses))
        setStartupCosts(deserializeTextAreaInput(deal.house_data?.startup_costs))
      })
    }
  }, [id])
  
  useEffect(() => {
    if (!deal) return
    
    let _income
    try {
      _income = income ? JSON.parse(serializeTextAreaInput(income)) : []
      setIncomeError('')
    } catch (e) {
      setIncomeError('Invalid monthly income')
    }
    
    let _expenses
    try {
      _expenses = expenses ? JSON.parse(serializeTextAreaInput(expenses)) : []
      setExpensesError('')
    } catch (e) {
      setExpensesError('Invalid monthly expenses')
    }
    
    let _startupCosts
    try {
      _startupCosts = startupCosts ? JSON.parse(serializeTextAreaInput(startupCosts)) : []
      setStartupCostsError('')
    } catch (e) {
      setStartupCostsError('Invalid startup costs')
    }
    let _totalStartupCosts = 0
    for (const key in _startupCosts)
      _totalStartupCosts += _startupCosts[key]
    setTotalStartupCosts(_totalStartupCosts)
    
    if (!_income || !_expenses) return
    
    const bankMortgagePayment = calculateMonthlyMortgagePayment(
      purchasePrice,
      bankInterestRate,
      bankCarryPercentage
    )
    setMonthlyBankMortgagePayment(bankMortgagePayment)
    
    const sellerMortgagePayment = calculateMonthlyMortgagePayment(
      purchasePrice,
      sellerInterestRate,
      sellerCarryPercentage
    )
    setMonthlySellerMortgagePayment(sellerMortgagePayment)
    
    let _totalMonthlyIncome  = 0
    for (const key in _income)
      _totalMonthlyIncome += _income[key]
    setTotalMonthlyIncome(_totalMonthlyIncome)
    
    let _totalMonthlyOperatingExpenses  = 0
    for (const key in _expenses)
      _totalMonthlyOperatingExpenses += _expenses[key]
    setMonthlyOperatingExpenses(_totalMonthlyOperatingExpenses)
    
    const totalMortgagePayments = bankMortgagePayment + sellerMortgagePayment
    setTotalMonthlyExpenses(_totalMonthlyOperatingExpenses + totalMortgagePayments)
    
    const netMonthlyIncome = _totalMonthlyIncome - _totalMonthlyOperatingExpenses - totalMortgagePayments
    setNetMonthlyIncome(netMonthlyIncome)
    
    const _expenseRatio = calculateExpenseRatio(_expenses, _income, [bankMortgagePayment, sellerMortgagePayment]);
    setExpenseRatio(_expenseRatio)
    
    const _capRate = calculateCapRate(purchasePrice, _income, _expenses)
    setCapRate(_capRate)
    
    const _cashOnCashReturn = calculateCashOnCashReturn(_income, _expenses, _startupCosts)
    setCashOnCashReturn(_cashOnCashReturn)
    
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ['Income', 'Operating Expenses', 'Bank Mortgage Payment', 'Seller Mortgage Payment'],
      datasets: [
        {
          data: [_totalMonthlyIncome, _totalMonthlyOperatingExpenses, bankMortgagePayment, sellerMortgagePayment],
          backgroundColor: [
            documentStyle.getPropertyValue('--green-400'),
            documentStyle.getPropertyValue('--red-400'),
            documentStyle.getPropertyValue('--orange-400'),
            documentStyle.getPropertyValue('--yellow-400'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--green-500'),
            documentStyle.getPropertyValue('--red-500'),
            documentStyle.getPropertyValue('--orange-300'),
            documentStyle.getPropertyValue('--yellow-300'),
          ]
        }
      ]
    };
    const options = {
      cutout: '60%',
      animation: {
        animateRotate: false,
        onComplete: (animation: any) => {
          const ctx = animation.chart.ctx;
          const centerX = animation.chart.width / 2;
          const centerY = animation.chart.height / 1.6;
          
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#000';
          ctx.font = '24px Arial';
          
          const percentageText = `ER: ${(_expenseRatio || 0)}%`;
          ctx.fillText(percentageText, centerX, centerY);
        },
      },
    };
    
    setChartData(data);
    setChartOptions(options);
    
  }, [
    purchasePrice,
    income,
    expenses,
    startupCosts,
    bankInterestRate,
    bankCarryPercentage,
    sellerInterestRate,
    sellerCarryPercentage,
  ]);
  
  const saveModel = async () => {
    if (deal) {
      deal.bank_interest_rate = bankInterestRate || 0
      deal.bank_carry_pct = bankCarryPercentage || 0
      deal.owner_interest_rate = sellerInterestRate || 0
      deal.owner_carry_pct = sellerCarryPercentage || 0
      
      if (income || expenses) {
        const house = deal.house_data
        await updateLead(
          house,
          house.address,
          house.city,
          house.state_data,
          house.zipcode,
          income,
          expenses,
          startupCosts,
        )
      }
      
      const res = await updateDeal(deal)
      if (res?.status === 200)  {
        setDeal(res.data)
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Deal saved', life: 3000 });
      }
    }
  }
  
  const resetModel = () => {
    if (deal) {
      setIncomeError('')
      setExpensesError('')
      setStartupCostsError('')
      setExpenseRatio(deal.expense_ratio)
      setPurchasePrice(deal.seller_price)
      setBankInterestRate(deal.bank_interest_rate)
      setBankCarryPercentage(deal.bank_carry_pct)
      setSellerInterestRate(deal.owner_interest_rate)
      setSellerCarryPercentage(deal.owner_carry_pct)
    }
  }
  
  const formatDollarAmount = (value?: number) : number => {
    if (!value) return 0
    
    value = Math.round(value)
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value) as unknown as number
  }
  
  const inputComponent = (
    <>
      <div className="col-12">
        <label htmlFor="purchase-price" className="font-bold block mb-2">Purchase Price</label>
        <InputNumber
          inputId="purchase-price"
          placeholder='Purchase Price'
          prefix='$'
          currency='USD'
          value={purchasePrice}
          onChange={(e) => setPurchasePrice(e.value || 0)}
        />
      </div>
      
      <div className="col-12">
        <label htmlFor="income-input" className="font-bold block mb-2">Monthly Income</label>
        <InputTextarea
          id="income-input"
          placeholder="Monthly Income"
          value={income}
          onChange={(e) => setIncome(e.target.value)}
          rows={3}
          cols={30}
        />
        <Message message={incomeError} severity="error" />
      </div>
      
      <div className="col-12">
        <label htmlFor="expenses-input" className="font-bold block mb-2">Monthly Expenses</label>
        <InputTextarea
          id="expenses-input"
          placeholder="Monthly Expenses"
          value={expenses}
          onChange={(e) => setExpenses(e.target.value)}
          rows={3}
          cols={30}
        />
        <Message message={expensesError} severity="error" />
      </div>
      
      <div className="col-12">
        <label htmlFor="startup-costs-input" className="font-bold block mb-2">Startup Costs</label>
        <InputTextarea
          id="startup-costs-input"
          placeholder="Startup Costs"
          value={startupCosts}
          onChange={(e) => setStartupCosts(e.target.value)}
          rows={3}
          cols={30}
        />
        <Message message={startupCostsError} severity="error" />
      </div>
      
    </>
  )
  
  const mortgageComponent = (
    <>
      <div className="col-12">
        <label htmlFor="bank-interest-rate" className="font-bold block mb-2">Bank Interest Rate</label>
        <InputNumber
          inputId="bank-interest-rate"
          style={{ alignItems: 'right' }}
          suffix="%"
          mode="decimal"
          showButtons
          step={.5}
          min={0}
          max={100}
          placeholder="Bank Interest Rate"
          value={bankInterestRate}
          onChange={(e) => setBankInterestRate(e.value || 0)}
        />
      </div>
      
      <div className="col-12">
        <label htmlFor="bank-carry-percentage" className="font-bold block mb-2?">Bank Carry Percentage</label>
        <InputNumber
          inputId="bank-carry-percentage"
          placeholder="Bank Carry %"
          suffix="%"
          mode="decimal"
          showButtons
          step={.5}
          min={0}
          max={100}
          value={bankCarryPercentage}
          onChange={(e) => setBankCarryPercentage(e.value || 0)}
        />
      </div>
      
      <Divider/>
      
      <div className="col-12">
        <label htmlFor="seller-interest-rate" className="font-bold block mb-2 mr-2">Seller Interest Rate</label>
        <InputNumber
          inputId="seller-interest-rate"
          placeholder="Seller Interest Rate"
          suffix="%"
          mode="decimal"
          showButtons
          step={.5}
          min={0}
          max={100}
          value={sellerInterestRate}
          onChange={(e) => setSellerInterestRate(e.value || 0)}
        />
      </div>
      
      <div className="col-12">
        <label htmlFor="seller-carry-percentage" className="font-bold block mb-2">Seller Carry Percentage</label>
        <InputNumber
          inputId="seller-carry-percentage"
          placeholder="Seller Carry %"
          suffix="%"
          mode="decimal"
          showButtons
          step={.5}
          min={0}
          max={100}
          value={sellerCarryPercentage}
          onChange={(e) => setSellerCarryPercentage(e.value || 0)}
        />
      </div>
      
      <Divider/>
      
      <Button id="reset-calculate-btn" label="Reset" icon="pi pi-times" className="p-button-text" onClick={resetModel} />
      <Button id="save-btn" label="Save" icon="pi pi-check" className="p-button-text" onClick={saveModel} />
    </>
  )
  
  const calculationsComponent = (
    <div>
      
      <table>
        <tbody>
          <tr>
            <td><strong>Total Down Payment to Seller</strong></td>
            <td id="total-down-payment" align="right">{formatDollarAmount(((purchasePrice || 0) * ((100 - (sellerCarryPercentage || 0)) / 100) || 0))}</td>
          </tr>
          <Divider/>
          <tr>
            <td><strong>Bank Down Payment</strong></td>
            <td id="bank-down-payment" align="right">{formatDollarAmount(((purchasePrice || 0) * ((bankCarryPercentage || 0) / 100) || 0))}</td>
          </tr>
          <tr>
            <td><strong>Buyer Down Payment</strong></td>
            <td id="buyer-down-payment" align="right">{formatDollarAmount(((purchasePrice || 0) * ((100 - (sellerCarryPercentage || 0) - (bankCarryPercentage || 0)) / 100) || 0))}</td>
          </tr>
          <tr>
            <td><strong>Total Startup Costs</strong></td>
            <td id="total-startup-costs" align="right">{formatDollarAmount(totalStartupCosts)}</td>
          </tr>
        </tbody>
        
        <Divider/>
        
        <tbody>
          <tr>
            <td><strong>Bank Payment</strong></td>
            <td id="bank-payment" align="right">{formatDollarAmount(monthlyBankMortgagePayment)}</td>
          </tr>
          <tr>
            <td><strong>Seller Payment</strong></td>
            <td id="seller-payment" align="right">{formatDollarAmount(monthlySellerMortgagePayment)}</td>
          </tr>
          <tr>
            <td><strong>Operating Expenses</strong></td>
            <td id="operating-expenses" align="right">{formatDollarAmount(monthlyOperatingExpenses)}</td>
          </tr>
        </tbody>
      
        <Divider/>
        
        <tbody>
        <tr>
          <td><strong>Gross Monthly Income</strong></td>
          <td id="gross-monthly-income" align="right">{formatDollarAmount(totalMonthlyIncome)}</td>
        </tr>
        <tr>
          <td><strong>Gross Yearly Income</strong></td>
          <td id="gross-yearly-income" align="right">{formatDollarAmount((totalMonthlyIncome || 0) * 12)}</td>
        </tr>
        </tbody>
        
        <Divider/>
        
        <tbody>
          <tr>
            <td><strong>Gross Monthly Expenses</strong></td>
            <td id="gross-monthly-expenses" align="right">{formatDollarAmount(totalMonthlyExpenses)}</td>
          </tr>
          <tr>
            <td><strong>Gross Yearly Expenses</strong></td>
            <td id="gross-yearly-expenses" align="right">{formatDollarAmount((totalMonthlyExpenses || 0) * 12)}</td>
          </tr>
        </tbody>
      
        <Divider/>
      
        <tbody>
          <tr>
            <td><strong>Net Monthly Income</strong></td>
            <td id="net-monthly-income" align="right">{formatDollarAmount(netMonthlyIncome)}</td>
          </tr>
          <tr>
            <td><strong>Net Yearly Income</strong></td>
            <td id="net-yearly-income" align="right">{formatDollarAmount((netMonthlyIncome || 0) * 12)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
  
  const chartsComponent = (
    <div>
      <table className="mb-4">
        <tbody>
        <tr>
          <td><strong>Expense Ratio</strong></td>
          <td id="expense-ratio" align="right">{(expenseRatio || 0)}%</td>
        </tr>
        <tr>
          <td><strong>Cap Rate</strong></td>
          <td id="cap-rate" align="right">{capRate}%</td>
        </tr>
        <tr>
          <td><strong>Cash On Cash Return</strong></td>
          <td id="cash-on-cash-return" align="right">{cashOnCashReturn}%</td>
        </tr>
        </tbody>
      </table>
      
      <div className="card flex justify-content-center">
        <Chart
          type="doughnut"
          data={chartData}
          options={chartOptions}
          className="w-full md:w-30rem"
        />
      </div>
    </div>
  )
  
  
  return (
    <div className="deal-qualifier-container">
      <Toast ref={toast} />
      {data?.auth?.user?.user?.is_superuser && deal ? (
      <>
        <ChatWindow
          deal={deal}
        />
        <h2>
          Deal Qualifier - {deal?.house_data.address}
        </h2>
        <Grid
          items={[
            {
              id: 'lg-input',
              cols: { xs: 12, sm: 4, md: 3, lg: 3 },
              content: inputComponent,
              rows: 1,
            },
            {
              id: 'lg-mortgage',
              cols: { xs: 12, sm: 4, md: 3, lg: 3 },
              content: mortgageComponent,
              rows: 1,
            },
            {
              id: 'lg-calculations',
              cols: { xs: 12, sm: 4, md: 3, lg: 3 },
              content: calculationsComponent,
              rows: 1,
            },
            {
              id: 'lg-chart',
              cols: { xs: 12, sm: 4, md: 3, lg: 3 },
              content: chartsComponent,
              rows: 1,
            },
          ]}
        />
      </>
      ) : <h2>Please log in</h2>}
    </div>
  )
}

export default DealQualifier
