import {useEffect, useState} from 'react'
import {AxiosResponse} from 'axios';
import {Bot, LeadList, SmsTemplate, State, TwilioNumber, User} from '../api/types'
import {deleteData, getData, patchData, postData} from '../api/http';
import {getError} from '../utils';


export default function useLeadLists() {
  const [loading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [error, setError] = useState<string>('');
  
  const [twilioNumbers, setTwilioNumbers] = useState<TwilioNumber[]>([]);
  const [smsTemplates, setSmsTemplates] = useState<SmsTemplate[]>([]);
  const [lists, setLists] = useState<LeadList[]>([]);
  
  const [listsPageCount, setListsPageCount] = useState<number>(0);
  const pageLength = 5
  
  useEffect(() => {
    getTwilioNumbers()
    .then((data: TwilioNumber[]) => setTwilioNumbers(data))
    getSmsTemplates()
    .then((data: SmsTemplate[]) => setSmsTemplates(data))
  }, [])
  
  const getNextPage = async (uri: string): Promise<any> => {
    setLoading(true)
    return getData(uri)
    .then((data) => {
      setLoading(false)
      return data
    }).catch(({ response }) => console.log(response));
  }
  
  const getTwilioNumbers = async (): Promise<TwilioNumber[]> => {
    setLoading(true)
    return getData('twilio-numbers')
    .then((data) => {
      setLoading(false)
      return data;
    })
    .catch(({ response }) => {
      setLoading(false)
      return response;
    });
  }
  
  const getSmsTemplates = async (): Promise<SmsTemplate[]> => {
    setLoading(true)
    return getData('sms-templates')
    .then((data) => {
      setLoading(false)
      return data;
    })
    .catch(({ response }) => {
      setLoading(false)
      return response;
    });
  }
  
  const getLeadLists = async (uploader?: User, page?: number): Promise<any> => {
    setLoading(true)
    
    let url = 'lists/?'
    if (uploader) url += `uploader=${uploader.id}&`
    if (page)
      url += `page=${page}&`
    
    return getData(url)
    .then((data) => {
      setListsPageCount(Math.ceil(data?.count/pageLength))
      setLists(data?.results)
      setLoading(false)
      return data;
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
      return err;
    });
  }
  
  const deleteLeadList = async (leadListId: number): Promise<any> => {
    setLoading(true)
    return deleteData(`lists/${leadListId}`)
    .then((res) => {
      setLoading(false)
      return res;
    })
    .catch(() => {
      setLoading(false)
    });
  }
  
  const contactLeadList = async (list: LeadList, numContact: number) => {
    setLoading(true)
    setMsg('')
  
    const formData = new FormData();
    formData.append('list', String(list.id));
    formData.append('num', String(numContact));
  
    return postData('batch', formData)
    .then((res) => {
      setLoading(false)
      setMsg(res?.data?.msg)
      return res
    })
    .catch((err) => {
      setError(err)
      setLoading(false)
      console.log(err);
    });
  }
  
  const createLeadList = async (
    fromNumber: TwilioNumber,
    file: File,
    listRate?: number,
    templateMsgId?: number,
    listEnabled?: boolean,
    bot?: Bot,
  ): Promise<AxiosResponse> => {
    setLoading(true)
    setMsg('')
    
    const formData = new FormData();
    formData.append('leads_file', file);
    formData.append('contact_number', String(fromNumber.id));
    if (templateMsgId) formData.append('message_template', String(templateMsgId));
    if (listRate && listRate > 0) formData.append('messages_per_day', String(listRate));
    if (listRate !== undefined) formData.append('enabled', String(listEnabled));
    if (bot) formData.append('bot', String(bot.id));
    
    return postData('lists/', formData)
    .then((res: any) => {
      setLoading(false)
      if (res.status !== 201) {
        setError(getError(res.data))
        return res
      }
      const tmpLists = lists
      tmpLists.unshift(res.data)
      setLists(tmpLists)
      return res
    })
    .catch((err) => {
      setError(err)
      setLoading(false)
      console.log(err);
    });
    
  }
  
  const updateLeadList = async (
    listId: number,
    fromNumber?: TwilioNumber,
    file?: File,
    listRate?: number,
    templateMsgId?: number,
    listEnabled?: boolean,
    bot?: Bot,
  ): Promise<AxiosResponse> => {
    setLoading(true)
    setMsg('')
  
    const formData = new FormData();
    if (fromNumber) formData.append('contact_number', String(fromNumber.id));
    if (templateMsgId) formData.append('message_template', String(templateMsgId));
    if (file) formData.append('leads_file', file);
    if (listRate) formData.append('messages_per_day', String(listRate));
    if (listRate !== undefined) formData.append('enabled', String(listEnabled));
    if (bot) formData.append('bot', String(bot.id));
    
    return patchData(`lists/${listId}`, formData)
    .then((res: any) => {
      setLoading(false)
      if (res.status !== 200) {
        setError(getError(res.data))
        return res
      }
      const tmpLists = lists
      tmpLists.unshift(res.data)
      setLists(tmpLists)
      return res
    })
    .catch((err) => {
      setError(err)
      setLoading(false)
      console.log(err);
    });
    
  }
  return {
    loading,
    msg,
    error,
    lists,
    listsPageCount,
    twilioNumbers,
    smsTemplates,
    getNextPage,
    getLeadLists,
    deleteLeadList,
    contactLeadList,
    createLeadList,
    updateLeadList,
  }
}
