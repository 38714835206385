import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Home,
  Faq,
  Contact,
  User,
  Ingest,
  Leads,
  Deals,
  DealQualifier,
  Profile,
  HowWeHelp,
  WhyWeHelp,
  Testimonial,
  Dashboards,
  Bots,
} from '../pages';

interface PrivateRouteProps {
  component: any;
  path: string;
  data: any
}

export const crmRoutes = [
  'ingest',
  'leads',
  'deals',
  'bots',
  'dashboards',
]

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  path,
  data
}) => {

  const isAuth = data?.auth?.authToken?.isLogin;
  return (
    <Route
      render={(props) =>
        isAuth ? (
          <Component path={path} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { previous: props.location }
            }}
          />
        )
      }
    />
  );
};

interface BaseRouterProps {
  data: any
}

const BaseRouter: React.FC<BaseRouterProps> = ({ data }) => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/home" component={Home} />
    <Route path="/faq" component={Faq} />
    <Route path="/contact" component={Contact} />
    <Route path="/ingest" component={Ingest} />
    <Route path='/leads/list/:id' component={Leads} />
    <Route path="/leads" component={Leads} />
    <Route path="/bots" component={Bots} />
    <Route path="/deals/qualifier/:id" component={DealQualifier} />
    <Route path="/deals" component={Deals} />
    <Route path="/dashboards" component={Dashboards} />
    <Route path="/howwehelp" component={HowWeHelp} />
    <Route path="/whywehelp" component={WhyWeHelp} />
    <Route path="/testimonials" component={Testimonial} />
    <PrivateRoute data={data} path="/user/:username" component={User} />
    <PrivateRoute data={data} path="/account" component={User} />
    <PrivateRoute data={data} path="/profile" component={Profile} />
    {/* <PrivateRoute path="/buyer" component={BuyerSignup} /> */}
  </Switch>
);

export default BaseRouter;
