import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AOS from 'aos';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '../../components';
import { Question } from '../../api/types';
import { getQuestions } from '../../api';
import './styles.scss';
import { datAosOnce, fadeRight } from '../../utils/animationsName';
import Footer from '../../kit-components/Footer/Footer';

const Faq: React.FC<RouteComponentProps> = () => {

  const [questions, setQuestions] = React.useState<Question[]>([]);
  
  React.useEffect(() => {
    AOS.init({
      duration: 1
    })
    getQuestions()
      .then((data: Question[]) => {
        setQuestions(data);
      })

  }, []);



  const faqComponent = (
    <div>
      <div className='lg-rules faq-header-main-container'>
        <div className="faq-header-conainer-main">
          <div className="contact-us-container-absolute-child"></div>
        </div>

        <div className='lg-rules--header'>FAQ</div>
        <div style={{ marginTop: '40px' }}>
          {questions?.map((question: Question) => (
            <Accordion defaultExpanded={true} key={question.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='rules-content'
                id='rules-header'
                className="faq-accordian-header"
              >
                <div className="faq-collapse-header-heading">{question.question}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div data-aos-once={datAosOnce} data-aos={fadeRight} className="faq-collapse-header-heading-answer">{question.answer}</div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );

  return (
    <div className="faq-main-container">
      <Grid
        items={[
          {
            id: 'lg-rules',
            cols: { xs: 12, sm: 8, md: 6, lg: 10 },
            content: faqComponent,
            rows: 1,
          },
        ]}
      />
    </div>
  );
};

export default withRouter(Faq);
