import React, { useState, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {Dropdown} from "primereact/dropdown";
import {Chart} from "primereact/chart";
import {InputNumber} from "primereact/inputnumber";
import {Slider} from "primereact/slider";
import { VirtualScroller } from 'primereact/virtualscroller';
import {FileUpload} from "primereact/fileupload";
import Carousel from "react-material-ui-carousel";
import {Card, CardMedia} from "@mui/material";
import {BsFillPersonVcardFill, BsFillTelephoneInboundFill} from "react-icons/bs";
import {PatternFormat} from "react-number-format";
import {FaHouseUser} from "react-icons/fa";
import {CiLocationOn} from "react-icons/ci";
import {AiOutlineUpload} from "react-icons/ai";
import {GiMagnifyingGlass} from "react-icons/gi";
import {Badge} from "primereact/badge";
import { Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';


import useDeals from '../../hooks/useDeals';
import {Deal, DealNote, DealType} from '../../api/types';
import {Loader, Message, Map} from "..";
import {gcsBucket, getIntentSeverity, getTimeDiff, LIFECYCLE, priorities, stages} from "../../utils";
import useLeads from "../../hooks/useLeads";
import './styles.scss'


const DealsTable: React.FC = () => {
  const { getDeals, updateDeal, deleteDeal, createDealNote, uploadImage, loading } = useDeals()
  const leads = useLeads()
  
  const [err, setErr] = useState<string>('');
  const [preLoading, setPreLoading] = useState<boolean>(false);
  
  const [totalSize, setTotalSize] = useState<number>(0);
  const [files, setFiles] = useState<File[]>([]);
  
  const [dealType, setDealType] = useState<DealType>();
  const [description, setDescription] = useState<string>('');
  const [priority, setPriority] = useState<string>('');
  const [stage, setStage] = useState<string>('');
  
  const [sellerPrice, setSellerPrice] = useState<number>();
  const [buyerPrice, setBuyerPrice] = useState<number>();
  const [idealPrice, setIdealPrice] = useState<number>();
  
  const [filterSellerPrice, setFilterSellerPrice] = useState();
  const [filterBuyerPrice, setFilterBuyerPrice] = useState();
  const [filterIdealPrice, setFilterIdealPrice] = useState();
  
  const [filteredDeals, setFilteredDeals] = useState<Deal[]>([]);
  const [deals, setDeals] = useState<Deal[]>([]);
  const [deal, setDeal] = useState<Deal>();
  const [selectedDeals, setSelectedDeals] = useState([]);
  
  const [bankInterestRate, setBankInterestRate] = useState<number>();
  const [bankCarryPercentage, setBankCarryPercentage] = useState<number>();
  const [ownerInterestRate, setOwnerInterestRate] = useState<number>();
  const [ownerCarryPercentage, setOwnerCarryPercentage] = useState<number>();
  const [income, setIncome] = useState<string>();
  const [expenses, setExpenses] = useState<string>();
  
  const [dealNoteSubject, setDealNoteSubject] = useState<string>('');
  const [dealNoteText, setDealNoteText] = useState<string>('');
  
  const [showImagesDialog, setShowImagesDialog] = useState(false);
  const [dealNoteDialog, setDealNoteDialog] = useState(false);
  const [dealDialog, setDealDialog] = useState(false);
  const [deleteDealDialog, setDeleteDealDialog] = useState(false);
  const [deleteDealsDialog, setDeleteDealsDialog] = useState(false);
  
  const [openMap, setOpenMap] = useState<number>(-1);
  
  const [globalFilter, setGlobalFilter] = useState<string>('');
  
  const toast = useRef(null);
  const dt = useRef(null);
  
  React.useEffect(() => {
    setPreLoading(true)
    getDeals('', '', '', '', '').then((results: Deal[]) => {
      setDeals(results)
      setFilteredDeals(results)
      setPreLoading(false)
    })
  }, [])
  
  const hideDialog = () => {
    setErr('')
    setFiles([])
    setStage('')
    setPriority('')
    setDescription('')
    setSellerPrice(undefined)
    setIdealPrice(undefined)
    setBuyerPrice(undefined)
    setDealType(undefined)
    setDealDialog(false);
    setDeleteDealDialog(false);
    setDeleteDealsDialog(false);
    setShowImagesDialog(false);
    setOpenMap(-1)
  }
  
  const hideDeleteDealDialog = () => {
    setDeleteDealDialog(false);
  }
  
  const hideDeleteDealsDialog = () => {
    setDeleteDealsDialog(false);
  }
  
  const hideDealNoteDialog = () => {
    setDealNoteDialog(false);
  }
  
  const handleImageUpload = async () => {
    if (deal) {
      let _deals = [...filteredDeals];
      const index = findIndexById(deal.id);
      let error = false
      
      for (const file of files) {
        const res = await uploadImage(deal.house_data, file, description)
        if (res?.status === 201) {
          _deals[index].house_data.pictures.push(res.data)
          setFilteredDeals(_deals)
          setDeals(_deals)
        }
        else
          error = true
      }
      if (!error) {
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Images Uploaded', life: 3000 });
      } else {
        // @ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Uploading Images', life: 3000 });
      }
      hideDialog()
    }
  }
  
  const handleImageAdd = (e: any) => {
    const _files = files
    _files.push(e.files[0])
    setFiles(_files)
    setTotalSize(totalSize + e.files[0].size);
  }
  
  const saveDeal = async () => {
    let _deals = [...filteredDeals];
    if (deal?.id) {
      const index = findIndexById(deal.id);
      
      if (toast?.current) {
        if (dealType?.id) deal.deal_type = dealType.id
        deal.priority = priority
        deal.stage = stage
        deal.description = description
        deal.seller_price = sellerPrice || 0
        deal.buyer_price = buyerPrice || 0
        deal.deal_price = idealPrice || 0
        deal.bank_interest_rate = bankInterestRate || 0
        deal.bank_carry_pct = bankCarryPercentage || 0
        deal.owner_interest_rate = ownerInterestRate || 0
        deal.owner_carry_pct = ownerCarryPercentage || 0
        
        if (income || expenses) {
          const house = deal.house_data
          const res = await leads.updateLead(
            house,
            house.address,
            house.city,
            house.state_data,
            house.zipcode,
            income,
            expenses,
          )
          if (res?.status === 200) {
            deal.house_data = res.data
            _deals[index] = deal
            setFilteredDeals(_deals)
          }
        }
        
        const res = await updateDeal(deal)
        if (res?.status === 200)  {
          _deals[index] = res.data;
          hideDialog()
          setFilteredDeals(_deals)
          setDeals(_deals)
          setDeal(res.data)
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Deal Updated', life: 3000 });
        }
        else {
          setErr("Unable to update deal");
        }
      }
    }
  }
  
  const editDeal = (deal: Deal) => {
    setDealType(deal.deal_type_data)
    setPriority(deal.priority)
    setStage(deal.stage)
    setDescription(deal.description)
    setSellerPrice(deal.seller_price || undefined)
    setBuyerPrice(deal.buyer_price || undefined)
    setIdealPrice(deal.deal_price || undefined)
    setDeal({...deal});
    setDealDialog(true);
    setIncome(leads.deserializeTextAreaInput(deal.house_data.monthly_income))
    setExpenses(leads.deserializeTextAreaInput(deal.house_data.monthly_expenses))
  }
  
  const resetCalculator = () => {
    if (deal) {
      setSellerPrice(deal.seller_price)
      setBankInterestRate(deal.bank_interest_rate)
      setBankCarryPercentage(deal.bank_carry_pct)
      setOwnerInterestRate(deal.owner_interest_rate)
      setOwnerCarryPercentage(deal.owner_carry_pct)
    }
  }
  
  const confirmDeleteDeal = (deal: any) => {
    setDeal(deal);
    setDeleteDealDialog(true);
  }
  
  const handleDeleteDeal = async () => {
    if (toast?.current && deal?.id) {
      const res = await deleteDeal(deal.id)
      if (res?.status === 204) {
        let _leads = filteredDeals.filter((dealItem: Deal) => deal.id !== dealItem?.id);
        setDeals(_leads);
        setFilteredDeals(_leads);
        hideDialog()
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Lead Deleted', life: 3000 });
      }
      else {
        hideDialog()
        // @ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Deleting Lead', life: 3000 });
      }
    }
  }
  
  const filterListHelper = (deal: Deal, queryIn: string) => {
    const query = queryIn.toLowerCase()
    
    return deal.house_data.owner_data?.first_name?.toLowerCase().includes(query) ||
      deal.house_data.owner_data?.last_name?.toLowerCase().includes(query) ||
      deal.house_data.owner_data?.username?.toLowerCase().includes(query) ||
      deal.house_data.owner_data?.member_data?.phone.includes(query) ||
      deal.house_data.address.toLowerCase().includes(query) ||
      deal.house_data.city.toLowerCase().includes(query) ||
      deal.house_data.state_data.long_name.toLowerCase().includes(query) ||
      deal.house_data.state_data.short_name.toLowerCase().includes(query) ||
      deal.deal_type_data?.name?.toLowerCase().replace('-', '').includes(query.replace('-', '')) ||
      deal.priority?.toLowerCase().includes(query) ||
      deal.stage?.toLowerCase().includes(query) ||
      deal.description?.toLowerCase().includes(query)
  }
  
  const handleGlobalFilterChange = (e: any) => {
    setGlobalFilter(e.target.value)
    setFilteredDeals(deals?.filter((deal: Deal) => filterListHelper(deal, e.target.value)))
  }
  
  const filterSellerPriceChangeHelper = (e: any) => {
    setFilterSellerPrice(e)
    setFilteredDeals(deals?.filter((item: Deal) => {
      return item.seller_price >= e[0] && item.seller_price <= e[1] && filterListHelper(item, globalFilter)
    }))
  }
  
  const filterIdealPriceChangeHelper = (e: any) => {
    setFilterIdealPrice(e)
    setFilteredDeals(deals?.filter((item: Deal) => {
      return item.deal_price >= e[0] && item.deal_price <= e[1] && filterListHelper(item, globalFilter)
    }))
  }
  
  const filterBuyerPriceChangeHelper = (e: any) => {
    setFilterBuyerPrice(e)
    setFilteredDeals(deals?.filter((item: Deal) => {
      return item.buyer_price >= e[0] && item.buyer_price <= e[1] && filterListHelper(item, globalFilter)
    }))
  }
  
  const findIndexById = (id: number) => {
    let index = -1;
    for (let i = 0; i < filteredDeals.length; i++) {
      if (filteredDeals[i].id === id) {
        index = i;
        break;
      }
    }
    
    return index;
  }
  
  const exportCSV = () => {
    // @ts-ignore
    dt?.current?.exportCSV();
  }
  
  const confirmDeleteSelected = () => {
    setDeleteDealsDialog(true);
  }
  
  const openDealNoteForm = (item: Deal) => {
    setDeal(item)
    setDealNoteDialog(true);
  }
  
  const deleteSelectedDeals = async () => {
    let _deals = filteredDeals.filter((val: Deal) => {
      // @ts-ignore
      return !selectedDeals?.includes(val);
      
    });
    
    if (toast?.current) {
      await selectedDeals.forEach(async (item: Deal) => {
        await deleteDeal(item.id)
      })
      setFilteredDeals(_deals);
      setDeleteDealsDialog(false);
      setSelectedDeals([]);
      // @ts-ignore
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Deals Deleted', life: 3000 });
    }
  }
  
  const submitDealNote = async () => {
    let _deals = [...filteredDeals];
    if (deal?.id) {
      const index = findIndexById(deal.id);
  
      if (toast?.current) {
        const res = await createDealNote(deal, dealNoteSubject, dealNoteText)
        if (res?.status === 201)  {
          _deals[index].deal_notes_data.push(res.data)
          setDeal(_deals[index])
          setFilteredDeals(_deals)
          setDeals(_deals)
          setDealNoteSubject('')
          setDealNoteText('')
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Deal Note Added', life: 3000 });
        }
        else {
          setErr(res.data)
        }
      }
    }
  }
  
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedDeals || !selectedDeals?.length} />
      </React.Fragment>
    )
  }
  
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
      </React.Fragment>
    )
  }
  
  const propertyBodyTemplate = (rowData: Deal) => {
    const contact_number = rowData.house_data?.owner_data?.member_data?.phone
    return (
      <>
        <p>
          <BsFillPersonVcardFill className='mr-3' />
          {rowData.house_data?.owner_data?.first_name} {rowData.house_data?.owner_data?.last_name}
        </p>
        
        <p>
          <BsFillTelephoneInboundFill className='mr-3' />
          <PatternFormat value={contact_number} displayType={'text'} format="(###) ###-####" />
        </p>
        
        <p>
          <FaHouseUser className='mr-3' />
          {rowData.house_data?.address}
        </p>
        
        <p>
          <CiLocationOn className='mr-3' />
          {rowData.house_data?.city}, {rowData.house_data?.state_data.short_name} {rowData.house_data?.zipcode}
        </p>
        
        <p>
          <AiOutlineUpload className="mr-3" />
          <span className="text-s text-gray-500">
            Uploaded {getTimeDiff(rowData?.house_data?.created)}
          </span>
        </p>
        
        <p>
          <GiMagnifyingGlass className="mr-3" />
          <span className="text-s text-gray-500 mr-2">
            Intent:
          </span>
          <Badge
            className='float-left mr-1'
            severity={getIntentSeverity(rowData?.house_data?.owner_data?.member_data?.interest_level ?? '')}
            value={rowData?.house_data?.owner_data?.member_data?.interest_level ?? 'UNKNOWN'}
          />
        </p>
      </>
    )
  }
  
  const getFileComponent = (filename: string, index: number) => {
    const filenameStr = LIFECYCLE === 'local' ? `${gcsBucket}/cgs.png` : filename
    if (filename) {
      // @ts-ignore
      const fileExt = filename.split('.').pop().toLowerCase()
      
      const videoExtensions = [
        'mov',
        'mp4',
      ]
      if (videoExtensions.includes(fileExt))
        return (
          <Card className='lg-post'>
            <CardMedia
              key={index}
              autoPlay={false}
              component='video'
              controls
              src={filenameStr}
            />
          </Card>
        )
    }
    return (
      <img
        key={index}
        height={200}
        width="auto"
        src={filenameStr}
        alt="Image not found"
      />
    )
  }
  
  const imagesCarouselTemplate = (rowData: Deal) => {
    return (
      <Button
        type="button"
        label="Images"
        icon="pi"
        className={`p-button-primary`}
        onClick={() => {
          setDeal(rowData)
          setShowImagesDialog(true)
        }}
      />
    )
  }
  
  const dealDetailsBodyTemplate = (rowData: Deal) => {
    
    const redPriorities = [
      'HIGHEST',
      'HIGH',
    ]
    const orangePriorities = [
      'MEDIUM',
    ]
    
    let priorityColor = 'info'
    if (redPriorities.includes(rowData.priority)) priorityColor = 'danger'
    else if (orangePriorities.includes(rowData.priority)) priorityColor = 'warning'
    
    const greenStages = [
      'FOUND BUYER',
    ]
    
    const grayStages = [
      'CLOSED',
    ]
    
    const pinkStages = [
      'NEGOTIATION',
    ]
    
  
    let stageColor = 'info'
    if (greenStages.includes(rowData.stage)) stageColor = 'success'
    else if (grayStages.includes(rowData.stage)) stageColor = 'secondary'
    else if (pinkStages.includes(rowData.stage)) stageColor = 'help'
  
    return (
      <>
        <p>
          {rowData.deal_type_data?.name ? (
            <Button type="button" label={rowData.deal_type_data.name} icon="pi" className={`p-button-secondary`}/>
          ) : null}
        </p>
        <p>
          <Button type="button" label={rowData.priority} icon="pi" className={`p-button-${priorityColor}`}/>
        </p>
        <p>
          <Button type="button" label={rowData.stage} icon="pi" className={`p-button-${stageColor}`}/>
        </p>
      </>
    )
  }
  
  const priceBodyTemplate = (rowData: Deal) => {
    const options = {
      maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
    const data = {
      labels: ['Buyer', 'Target', 'Seller'],
      datasets: [
        {
          label: 'Price',
          backgroundColor: '#42A5F5',
          data: [rowData.buyer_price, rowData.deal_price, rowData.seller_price]
        },
      ]
    }
    
    return (
      <div className="card">
        <Chart type="bar" data={data} options={options} />
      </div>
    )
  }
  
  const actionBodyTemplate = (rowData: Deal) => {
    return (
      <React.Fragment>
        <Button id="edit-item-btn" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDeal(rowData)} />
        
        <Link to={`/deals/qualifier/${rowData.id}`}>
          <Button
            id="open-calculator-btn"
            icon="pi pi-calculator"
            className="p-button-rounded p-button-secondary mr-2"
          />
        </Link>
        
        <Button id="open-notes-btn" icon="pi pi-book" className="p-button-rounded p-button-warning mr-2" onClick={() => openDealNoteForm(rowData)} />
        <Button id="open-map-btn" icon="pi pi-external-link" className="p-button-rounded mr-2" onClick={() => setOpenMap(rowData.id)} />
        <Dialog visible={openMap === rowData.id} style={{ width: '450px' }} modal className="p-fluid" onHide={hideDialog}>
          <Map showAddress address={`${rowData.house_data.address}, ${rowData.house_data.city} ${rowData.house_data.state_data?.short_name}`}/>
        </Dialog>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteDeal(rowData)} />
      </React.Fragment>
    );
  }
  
  const maxPrice = 1000000
  const step = 10000
  
  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">Manage Deals</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => handleGlobalFilterChange(e)} placeholder="Search..." />
      </span>
  
      <>
        <h5>Filter by Seller Price: {filterSellerPrice ? `${filterSellerPrice[0]} - ${filterSellerPrice[1]}` : null}</h5>
        <Slider range step={step} max={maxPrice} value={filterSellerPrice} onChange={(e) => {
          filterSellerPriceChangeHelper(e.value)
        }}
        />
      </>
      <>
        <h5>Filter by Target Price: {filterIdealPrice ? `${filterIdealPrice[0]} - ${filterIdealPrice[1]}` : null}</h5>
        <Slider range step={step} max={maxPrice} value={filterIdealPrice} onChange={(e) => {
          filterIdealPriceChangeHelper(e.value)
        }}
        />
      </>
      <>
        <h5>Filter by Buyer Price: {filterBuyerPrice ? `${filterBuyerPrice[0]} - ${filterBuyerPrice[1]}` : null}</h5>
        <Slider range step={step} max={maxPrice} value={filterBuyerPrice} onChange={(e) => {
          filterBuyerPriceChangeHelper(e.value)
        }}
        />
      </>
    </div>
  );
  
  const dealDialogFooter = (
    <React.Fragment>
      <Button id="cancel-item-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button id="save-item-btn" label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveDeal} />
    </React.Fragment>
  );
  
  const deleteDealDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDealDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={handleDeleteDeal} />
    </React.Fragment>
  );
  
  const deleteDealsDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDealsDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDeals} />
    </React.Fragment>
  );
  
  const dealNoteDialogFooter = (
    <React.Fragment>
      <Button label="Clear" icon="pi pi-times" className="p-button-text" onClick={() => {
        setDealNoteSubject('')
        setDealNoteText('')
      }} />
      <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={submitDealNote} />
    </React.Fragment>
  );
  
  const selectedDealTypeTemplate = (option: DealType, props: any) => {
    if (option) {
      return (
        <div className="deal-type-item deal-type-item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    
    return (
      <span>
            {props.placeholder}
        </span>
    );
  }
  
  const dealTypeOptionTemplate = (option: DealType) => {
    return (
      <div className="deal-type-item">
        <div>{option.name}</div>
      </div>
    );
  }
  
  const selectedPriorityTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="priority-item priority-item-value">
          <div>{option}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const priorityOptionTemplate = (option: string) => {
    return (
      <div className="priority-item">
        <div>{option}</div>
      </div>
    );
  }
  
  const selectedStageTemplate = (option: string, props: any) => {
    if (option) {
      return (
        <div className="stage-item stage-item-value">
          <div>{option}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const stageOptionTemplate = (option: string) => {
    return (
      <div className="country-item">
        <div>{option}</div>
      </div>
    );
  }
  
  const dealNoteItemTemplate = (item: DealNote, options: any) => {
    const className = classNames('scroll-item p-2', {
      'odd': options.odd
    });
    const style = options.props.orientation === 'horizontal' ? { width: '385px' } : { width: '385px', height: '50px' };
    
    return (
    <div>
      <div className={className} style={style}>
        <strong>{item.subject}</strong>
      </div>
      <div className={className} style={style}>
        <p>{item.text}</p>
      </div>
    </div>
    )
  }
  
  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />
      <Loader dialog loading={preLoading}/>
      
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
        
        <DataTable ref={dt} value={filteredDeals} selection={selectedDeals} onSelectionChange={(e) => setSelectedDeals(e.value)}
                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Deals"
                   header={header} responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{width: '3rem'}} exportable={false}/>
          <Column field="owner" header="Property" body={propertyBodyTemplate} sortable style={{minWidth: '6rem'}}/>
          <Column field="details" header="Details" body={dealDetailsBodyTemplate} style={{minWidth: '10rem'}}/>
          <Column field="images" header="Images" body={imagesCarouselTemplate} style={{minWidth: '13rem'}}/>
          <Column field="priceChart" header="Price Range" body={priceBodyTemplate} style={{minWidth: '2rem'}}/>
          <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}/>
        </DataTable>
      </div>
      
      <Dialog visible={showImagesDialog} style={{ width: '450px' }} header="Image Gallery" modal onHide={hideDialog}>
        <div className="confirmation-content">
          <Carousel>
            {deal?.house_data.pictures?.map((item, i) => getFileComponent(item.filename, i))}
          </Carousel>
          <div className="field">
            <FileUpload name="images" onSelect={handleImageAdd} customUpload uploadHandler={handleImageUpload} multiple accept="image/*,video/*" maxFileSize={32000000}
                        emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
          </div>
        </div>
      </Dialog>
      
      <Dialog visible={dealDialog} style={{ width: '450px' }} header="Edit Deal" modal className="p-fluid" footer={dealDialogFooter} onHide={hideDialog}>
  
        <div className="col-12">
          <Dropdown
            value={dealType}
            options={leads.dealTypes}
            onChange={(e) => setDealType(e.value)}
            optionLabel="name"
            placeholder="Select Type"
            valueTemplate={selectedDealTypeTemplate}
            itemTemplate={dealTypeOptionTemplate}
          />
        </div>
  
        <div className="col-12">
          <Dropdown
            value={priority}
            options={priorities}
            onChange={(e) => setPriority(e.value)}
            optionLabel="name"
            placeholder="Select Priority"
            valueTemplate={selectedPriorityTemplate}
            itemTemplate={priorityOptionTemplate}
          />
        </div>
  
        <div className="col-12">
          <Dropdown
            value={stage}
            options={stages}
            onChange={(e) => setStage(e.value)}
            optionLabel="stage"
            placeholder="Select Stage"
            valueTemplate={selectedStageTemplate}
            itemTemplate={stageOptionTemplate}
          />
        </div>
        
        <div className="col-12">
          <InputText value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
        </div>
  
        <div className="col-12">
          <InputNumber
            id='seller-price'
            placeholder='Seller Price'
            inputId="integeronly"
            value={sellerPrice}
            onValueChange={(e) => setSellerPrice(e.value || 0)}
          />
        </div>
  
        <div className="col-12">
          <InputNumber
            placeholder='Buyer Price'
            inputId="integeronly"
            value={buyerPrice}
            onValueChange={(e) => setBuyerPrice(e.value || 0)}
          />
        </div>
  
        <div className="col-12">
          <InputNumber
            placeholder='Target Price'
            inputId="integeronly"
            value={idealPrice}
            onValueChange={(e) => setIdealPrice(e.value || 0)}
          />
        </div>
  
        <Loader loading={loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={deleteDealDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDealDialogFooter} onHide={hideDeleteDealDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {deal && <span>Are you sure you want to delete <b>{`${deal.house_data.owner_data.first_name} ${deal.house_data.owner_data.last_name}`}</b>?</span>}
        </div>
      </Dialog>
      
      <Dialog visible={deleteDealsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDealsDialogFooter} onHide={hideDeleteDealsDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {selectedDeals.length && <span>Are you sure you want to delete the selected list of deals?</span>}
        </div>
      </Dialog>
      
      <Dialog visible={dealNoteDialog}
              style={{ width: '450px' }}
              header={(
                <><i className="pi pi-pencil mr-3" style={{ fontSize: '1rem'}} />Notes</>
              )}
              modal
              footer={dealNoteDialogFooter}
              onHide={hideDealNoteDialog}>
        <div className="confirmation-content">
  
          <div className="col-12">
            <div className="virtualscroller-demo flex">
              <div className="card flex">
                <div className="flex align-items-center flex-wrap">
                  <div className="flex flex-column mr-3 mt-3">
                    <VirtualScroller items={deal?.deal_notes_data} itemSize={50} itemTemplate={dealNoteItemTemplate} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-12">
            <Message message={err} severity='error'/>
            <Loader loading={loading}/>
          </div>
          
          <div className="col-12">
            <InputText placeholder="Note Subject" style={{ width: '385px' }} value={dealNoteSubject} onChange={(e) => setDealNoteSubject(e.target.value)} />
          </div>
          
          <div className="col-12">
            <InputText placeholder="Note Text" style={{ width: '385px' }} value={dealNoteText} onChange={(e) => setDealNoteText(e.target.value)} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DealsTable;
