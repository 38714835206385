import LogRocket from 'logrocket';
import { logout } from '../../api';
import { getCurrentUser } from '../../api/get';
import * as types from './types';
import {User} from '../../api/types';


function setAuth(user: User | null, isAuthenticated: boolean) {
  if (user) {
    console.log('IDENTIFY USER:', user)
    LogRocket.identify(String(user.id), {
      name: user.username || `${user.first_name} ${user.last_name}` || 'Anonymous User',
      email: user.email,
    })
    
  }
  return {
    type: types.SET_AUTH,
    user: { user, isLogin: isAuthenticated },
  };
}


export const socialLogin = () => {
  return (dispatch: any) => {
    getCurrentUser().then((user) => {
      console.log('CURRENT USER:', user)
      if (user) {
        dispatch(setAuth(user, true));
      }
      else
        dispatch(setAuth(null, false));
    })
  };
};


export const checkLogin = (user: any) => {
  return (dispatch: any) => {
    dispatch(setAuth(user, true));
  };
};


export const socialLogout = () => {
  return (dispatch: any) => {
    dispatch(setAuth(null, false));
    logout()
    localStorage.clear();
  }
}
