import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {CURRENT_URL, LIFECYCLE} from '../../utils';
import './style.scss';
import {crmRoutes} from "../../utils/router";

interface HeaderflowProps {
  check: boolean
  isAdmin?: boolean,
  route?: string
}

const Headerflow: React.FC<HeaderflowProps> = ({ route, isAdmin }) => {
  
  const baseRoute = route?.split('/')[0]

  return (
    <div className='home-header-flow-item'>
      <NavLink activeClassName='link-focused' className={'home-header-flow-item-list'} to="/">Home</NavLink>
      {!baseRoute || !crmRoutes.includes(baseRoute) ? (
        <>
          <NavLink activeClassName="link-focused" className={'home-header-flow-item-list'} to='/contact'>Contact Us</NavLink>
          <NavLink activeClassName='link-focused' className={'home-header-flow-item-list'} to='/whywehelp'>Why we help</NavLink>
          <NavLink activeClassName='link-focused' className={'home-header-flow-item-list'} to='/howwehelp'>How we help</NavLink>
          <NavLink activeClassName='link-focused' className={'home-header-flow-item-list'} to='/faq'>FAQ</NavLink>
          {LIFECYCLE !== 'prod' ? (
            <NavLink activeClassName='link-focused' className={'home-header-flow-item-list'} to='/testimonials'>Testimonials</NavLink>
          ) : null}
        </>
      ) : null}
      {isAdmin ? (
        <>
          <NavLink activeClassName='link-focused' className={'home-header-flow-item-list'} to='/ingest'>CRM</NavLink>
          <a className={'home-header-flow-item-list'} href={`${CURRENT_URL}admin/`}>Admin</a>
        </>
      ) : null}
    </div >
  )
}

export default Headerflow