import React, { useState, useRef } from 'react';
import './styles.scss'
import 'react-chat-elements/dist/main.css'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import {MessageBox} from 'react-chat-elements'

import useBots from '../../hooks/useBots';
import {Bot, BotInstruction, TwilioMessage} from '../../api/types';
import {Loader, Message, ProgressCard} from "../index";
import {getError, getTimeDiff} from "../../utils";
import {Dropdown} from "primereact/dropdown";
import {Divider} from "primereact/divider";
import Card from "../../kit-components/Card/Card";
import {Badge, BadgeSeverityType} from "primereact/badge";
import {Accordion, AccordionTab} from "primereact/accordion";


const BotsTable: React.FC = () => {
  const {
    getBots,
    createBot,
    updateBot,
    getBotInstructions,
    createBotInstruction,
    updateBotInstruction,
    addInstructions,
    removeInstruction,
    deleteBot,
    contactBot,
    clearBotConversation,
    loading
  } = useBots()
  
  const providers = [
    {
      'name': 'VertexAI'
    },
    {
      'name': 'OpenAI'
    },
  ]
  const [name, setName] = useState<string>();
  const [provider, setProvider] = useState<any>();
  const [isDealQualifier, setIsDealQualifier] = useState<boolean>(false);
  const [instructions, setInstructions] = useState<BotInstruction[]>([]);
  const [instructionToAdd, setInstructionToAdd] = useState<string>('');
  const [instructionsToAdd, setInstructionsToAdd] = useState<BotInstruction[]>([]);
  
  const [err, setErr] = useState<string>('');
  const [preLoading, setPreLoading] = useState<boolean>(false);
  
  const [botInstruction, setBotInstruction] = useState<BotInstruction>();
  const [botInstructionDialog, setBotInstructionDialog] = useState<boolean>();
  
  const [newMessageToBot, setNewMessageToBot] = useState<string>("");
  
  const [filteredBots, setFilteredBots] = useState<Bot[]>([]);
  const [bot, setBot] = useState<Bot>();
  const [bots, setBots] = useState<Bot[]>([]);
  const [botDialog, setBotDialog] = useState(false);
  const [sendMessageDialog, setSendMessageDialog] = useState(false);
  
  const [manageInstructionsDialog, setManageInstructionsDialog] = useState(false);
  const [showConversationsDialog, setShowConversationsDialog] = useState(false);
  
  const [deleteBotDialog, setDeleteBotDialog] = useState(false);
  const [deleteBotsDialog, setDeleteBotsDialog] = useState(false);
  const [selectedBots, setSelectedBots] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  
  React.useEffect(() => {
    setPreLoading(true)
    getBots().then((results: Bot[]) => {
      setBots(results)
      setFilteredBots(results)
      setPreLoading(false)
    })
    getBotInstructions().then((results: BotInstruction[]) => {
      setInstructions(results)
      setPreLoading(false)
    })
  }, [])
  
  const findIndexById = (id: number) => {
    let index = -1;
    for (let i = 0; i < filteredBots.length; i++) {
      if (filteredBots[i].id === id) {
        index = i;
        break;
      }
    }
    
    return index;
  }
  
  const openNewBot = () => {
    setBot(undefined);
    setBotDialog(true);
    setErr('')
  }
  
  const openNewBotInstruction = () => {
    setBotInstruction(undefined);
    setBotInstructionDialog(true);
    setErr('')
  }
  
  const handleShowConversationsDialog = async (item: Bot) => {
    setBot(item);
    setShowConversationsDialog(true);
    setErr('')
  }
  
  const openSendMessageDialog = async (item: Bot) => {
    setBot(item);
    setSendMessageDialog(true);
    setErr('')
  }
  
  const hideDialog = () => {
    setBotDialog(false);
    setNewMessageToBot("");
    setShowConversationsDialog(false)
    setBotInstructionDialog(false);
    setSendMessageDialog(false)
    setManageInstructionsDialog(false);
    setErr('')
    setName("");
    setInstructionsToAdd([]);
    setIsDealQualifier(false)
    setDeleteBotDialog(false);
    setDeleteBotsDialog(false);
    setTimeout(() => {
      setBot(undefined);
    }, 100);
  }
  
  const saveInstruction = async () => {
    let _instructions = [...instructions];
    if (botInstruction?.id) {
      const index = findIndexById(botInstruction.id);
      
      if (toast?.current) {
        const res = await updateBotInstruction(botInstruction.id, instructionToAdd)
        if (res?.status === 200) {
          _instructions[index] = res.data;
          setInstructions(_instructions);
          // @ts-ignore
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Bot Instruction Updated',
            life: 3000
          });
          
          hideDialog()
        }
        else {
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Updating Bot Instruction', life: 3000 });
        }
        
      }
    }
    else {
      if (instructionToAdd) {
        const res = await createBotInstruction(instructionToAdd)
        if (res?.status === 201) {
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Bot Instruction Created', life: 3000 });
          _instructions.unshift(res.data)
          setInstructions(_instructions);
          hideDialog()
        }
        else {
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: getError(res.data), life: 3000 });
          console.log(getError(res.data))
        }
      }
      else {
        // @ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Instruction is required'});
      }
    }
  }
 
  const removeActiveInstruction = async (instruction: BotInstruction) => {
    let _bots = [...filteredBots];
    
    if (bot?.id) {
      if (toast?.current) {
        const res = await removeInstruction(bot, instruction)
        if (res?.status === 204) {
          const index = findIndexById(bot.id)
          let _bot = _bots[index]
          console.log(_bot)
          _bot.instruction_set = _bot.instruction_set.filter((i: BotInstruction) => i.id !== instruction.id)
          console.log(_bot)
          _bots[index] = _bot
          setBot(_bot)
          setBots(_bots)
          setFilteredBots(_bots)
          
          // @ts-ignore
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Bot Instruction Removed',
            life: 3000
          });
          
        } else {
          // @ts-ignore
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Removing Bot Instruction',
            life: 3000
          });
        }
      }
    }
  }
  
  const sendBotMessage = async () => {
    let _bots = [...filteredBots];
    
    if (bot?.id) {
      if (toast?.current) {
        const res = await contactBot(bot, newMessageToBot)
        if (res?.status === 200) {
          setNewMessageToBot("")
          const index = findIndexById(bot.id)
          let _bot = _bots[index]
          _bot.personal_messages.push(res.data?.request)
          _bot.personal_messages.push(res.data?.response)
          _bots[index] = _bot
          setBots(_bots)
          setFilteredBots(_bots)
          
          // @ts-ignore
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Bot Message Sent',
            life: 3000
          });
          
        } else {
          // @ts-ignore
          toast.current.show({severity: 'error', summary: 'Error', detail: 'Sending Bot Message', life: 3000});
        }
      }
    }
  }
  
  const saveBot = async () => {
    let _bots = [...filteredBots];
    if (bot?.id) {
      const index = findIndexById(bot.id);
      
      if (toast?.current) {
        const res = await updateBot(bot.id, name, provider?.name, undefined, isDealQualifier)
        if (res.status === 200) {
          _bots[index] = res.data;
          setBots(_bots);
          setFilteredBots(_bots);
          // @ts-ignore
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Bot Updated',
            life: 3000
          });
          
          hideDialog()
        }
        else {
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Updating Bot', life: 3000 });
        }
        
      }
    }
    else {
      if (name) {
        const res = await createBot(name, provider?.name, undefined, isDealQualifier)
        if (res.status === 201) {
          // @ts-ignore
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Bot Created', life: 3000 });
          _bots.unshift(res.data)
          setBots(_bots);
          setFilteredBots(_bots);
          hideDialog()
        }
        else {
          // @ts-ignore
          toast.current.show({ severity: 'error', summary: 'Error', detail: getError(res.data), life: 3000 });
          console.log(getError(res.data))
        }
      }
      else {
        // @ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Name is required'});
      }
    }
  }
  
  const saveInstructions = async () => {
    if (bot?.id) {
      let _bots = [...filteredBots];
      const index = findIndexById(bot.id);
      
      const res = await addInstructions(bot.id, instructionsToAdd)
      if (res.status === 200) {
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Bot Instructions Added', life: 3000 });
        
        _bots[index] = res.data;
        setBots(_bots);
        setFilteredBots(_bots);
        hideDialog()
      }
      else {
        // @ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: getError(res.data), life: 3000 });
        console.log(getError(res.data))
      }
    }
    else {
      // @ts-ignore
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'No bot selected'});
    }
  }
  
  
  const showEditBotDialog = (item: Bot) => {
    setBot({...item});
    setBotDialog(true);
    setName(item?.name)
    setProvider(item?.provider)
    setIsDealQualifier(item.isDealQualifier)
  }
  
  const showAddInstructionsDialog = (item: Bot) => {
    setBot({...item});
    setManageInstructionsDialog(true);
  }
  
  const showConfirmDeleteBotDialog = (item: Bot) => {
    setBot(item);
    setDeleteBotDialog(true);
  }
  
  const handleDeleteBot = async () => {
    
    if (toast?.current && bot?.id) {
      const res = await deleteBot(bot?.id)
      if (res.status === 204) {
        let _items = filteredBots.filter((item: Bot) => item.id !== bot?.id);
        setBots(_items);
        setFilteredBots(_items);
        setBot(undefined);
        setDeleteBotDialog(false);
        
        // @ts-ignore
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Bot Deleted', life: 3000 });
        hideDialog()
      }
    }
  }
  
  const filterListHelper = (bot: Bot, query: string) => {
    const ret = bot.name.toLowerCase().includes(query.toLowerCase()) ||
      String(bot.id).includes(query)
  
    return ret
  }
  
  const handleGlobalFilterChange = (e: any) => {
    setGlobalFilter(e.target.value)
    const filteredLists = bots?.filter((bot: Bot) => filterListHelper(bot, e.target.value))
    setFilteredBots(filteredLists)
  }
  
  const exportCSV = () => {
    // @ts-ignore
    dt?.current?.exportCSV();
  }
  
  const confirmDeleteSelected = () => {
    setDeleteBotsDialog(true);
  }
  
  const deleteSelectedBots = async () => {
    let items = filteredBots.filter((item: Bot) => {
      // @ts-ignore
      return !selectedBots?.includes(item);
    });
    
    if (toast?.current) {
      selectedBots.forEach(async (item: Bot) => {
        await deleteBot(item.id)
      })
      setFilteredBots(items);
      setDeleteBotsDialog(false);
      setSelectedBots([]);
      // @ts-ignore
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Bots Deleted', life: 3000 });
    }
  }
  
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button id="new-bot-btn" label="New Bot" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewBot} />
        <Button id="new-bot-instruction-btn" label="New Instruction" icon="pi pi-plus" className="p-button-info mr-2" onClick={openNewBotInstruction} />
        <Button id="delete-bots-btn" label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedBots || !selectedBots?.length} />
      </React.Fragment>
    )
  }
  
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
      </React.Fragment>
    )
  }
  
  const actionBodyTemplate = (rowData: Bot) => {
    return (
      <React.Fragment>
        <Button id="edit-bot-btn" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => showEditBotDialog(rowData)} />
        <Button id="add-bot-instructions-btn" icon="pi pi-plus" className="p-button-rounded p-button-warning mr-2" onClick={() => showAddInstructionsDialog(rowData)} />
        <Button id="ask-bot-btn" icon="pi pi-question" className="p-button-rounded p-button-info mr-2" onClick={() => openSendMessageDialog(rowData)} />
        <Button id="conversations-btn" icon="pi pi-list" className="p-button-rounded p-button-primary mr-2" onClick={() => handleShowConversationsDialog(rowData)} />
        <Button id="delete-bot-btn" icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => showConfirmDeleteBotDialog(rowData)} />
      </React.Fragment>
    );
  }
  
  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">Manage Bots</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => handleGlobalFilterChange(e)} placeholder="Search..." />
      </span>
    </div>
  );
  
  const botDialogFooter = (
    <React.Fragment>
      <Button id="cancel-bot-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button id="submit-bot-btn" label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveBot} />
    </React.Fragment>
  );
  
  const botInstructionDialogFooter = (
    <React.Fragment>
      <Button id="cancel-bot-instruction-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button id="submit-bot-instruction-btn" label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveInstruction} />
    </React.Fragment>
  );
  
  const manageInstructionsDialogFooter = (
    <React.Fragment>
      <Button id="cancel-add-bot-instructions-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button id="submit-add-bot-instructions-btn" label="Submit" icon="pi pi-check" className="p-button-text" onClick={saveInstructions} />
    </React.Fragment>
  );
  
  const showConversationsDialogFooter = (
    <React.Fragment>
      <Button id="hide-conversations-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
    </React.Fragment>
  );
  
  const sendMessageDialogFooter = (
    <React.Fragment>
      <Button id="cancel-bot-message-btn" label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button id="send-bot-message-btn" label="Submit" icon="pi pi-check" className="p-button-text" onClick={sendBotMessage} />
    </React.Fragment>
  );
  
  const deleteBotDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={handleDeleteBot} />
    </React.Fragment>
  );
  
  const deleteBotsDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedBots} />
    </React.Fragment>
  );
  
  const selectedInstructionsTemplate = (option: BotInstruction, props: any) => {
    if (option) {
      return (
        <div className="instruction-item instruction-item-value">
          <div id={`bot-instruction-${option.id}`}>{option.instruction}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const instructionsOptionTemplate = (option: BotInstruction) => {
    return (
      <div id="bot-instruction-item" className="instruction-item">
        <div>{option.instruction}</div>
      </div>
    );
  }
  
  const selectedProvidersTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="selected-bot-provider-item selected-bot-provider-item-value">
          <div id={`bot-provider-${option.name}`}>{option.name}</div>
        </div>
      );
    }
    
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  const providerOptionTemplate = (option: any) => {
    return (
      <div id="bot-provider-item" className="bot-provider-item">
        <div>{option.name}</div>
      </div>
    );
  }
  
  const providerBodyTemplate = (rowData: Bot) => {
    let severity: BadgeSeverityType = 'info';
    if (rowData.provider?.toLowerCase() === 'openai')
      severity = 'warning';
      
    
    return (
      <>
        <Badge
          className='float-left mr-1 mb-3'
          severity={severity}
          value={rowData.provider}
        />
      </>
    )
  }
  
  const botAnalyticsBodyTemplate = (rowData: Bot) => {
    return (
      <>
        <strong>Messages Sent: </strong>
        <div className="float-right">
          {rowData.num_messages_sent}
        </div>
        
        <Divider className="mt-2"/>
        
        <strong>Messages Received:</strong>
        <div className="float-right">
          {rowData.num_messages_received}
        </div>
        
        <Divider className="mt-2"/>
        
        <strong>Conversion Rate: </strong>
        <div className="float-right">
          {rowData.num_conversions} / {rowData.num_conversations}
        </div>
        <br/>
        <br/>
        <ProgressCard color='auto' numerator={rowData.num_conversions} denominator={rowData.num_conversations}/>
      </>
    )
  }
    
    
    const instructionsBodyTemplate = (rowData: Bot) => {
    if (rowData.instruction_set?.length === 0)
      return (
        <>
          <strong>No active instructions</strong>
          <br/>
          <br/>
        </>
      )
    
    return (
      <div style={{width: '385px'}}>
        <strong>Active Instructions</strong>
        <Divider className="mt-2"/>
        {rowData.instruction_set?.map((item: BotInstruction) => (
          <>
            
            <div key={item.id}>{item.instruction}</div>
            <Divider/>
          </>
        ))}
      </div>
    )
  }
  
  const  removeInstructionToAdd = (item: BotInstruction) => {
    let _instructions = [...instructionsToAdd];
    
    const index = _instructions.findIndex(i => i.id === item.id);
    _instructions.splice(index, 1);
    
    setInstructionsToAdd(_instructions);
  }
  
  const handleClearBotConversation = async () => {
    const res = await clearBotConversation(bot);
    if (res.status === 204){
      if (bot) {
        const _bots = [...filteredBots];
        let _bot = bot
        const index = findIndexById(bot.id);
        
        _bot.personal_messages = [];
        _bots[index] = _bot;
        setBots(_bots);
        setFilteredBots(_bots);
        setBot(_bot);
      }
      
      hideDialog();
      // @ts-ignore
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Bot Conversation Cleared',
        life: 3000
      });
    }
    else
      // @ts-ignore
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Clearing Bot Conversation',
        life: 3000
      });
  }
  
  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />
      <Loader dialog loading={preLoading}/>
      
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
        
        <DataTable ref={dt} value={filteredBots} selection={selectedBots} onSelectionChange={(e) => setSelectedBots(e.value)}
                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Bots"
                   header={header} responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{width: '3rem'}} exportable={false}/>
          <Column field="id" header="ID" sortable style={{minWidth: '1em'}}/>
          <Column field="name" header="Name" sortable style={{minWidth: '4rem'}}/>
          <Column header="Provider" body={providerBodyTemplate} sortable style={{minWidth: '2rem'}}/>
          <Column header="Instructions" body={instructionsBodyTemplate} sortable style={{minWidth: '4rem'}}/>
          <Column header="Analytics" body={botAnalyticsBodyTemplate} sortable style={{minWidth: '4rem'}}/>
          <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}/>
        </DataTable>
      </div>
      
      <Dialog visible={botDialog} style={{ width: '450px' }} header={bot?.id ? "Edit Bot" : "Create Bot"} modal className="p-fluid" footer={botDialogFooter} onHide={hideDialog}>
        
        <div className="field">
          <div className="field">
            <InputText id="bot-name-input" name="name" style={{ width: '385px' }} placeholder='Bot Name' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
        
        <Dropdown
          id="bot-providers-dropdown"
          style={{ width: '385px' }}
          value={provider}
          options={providers}
          onChange={(e) => {
              setProvider(e.target.value)
          }}
          optionLabel="providers"
          placeholder="Select Generative AI Provider"
          valueTemplate={selectedProvidersTemplate}
          itemTemplate={providerOptionTemplate}
        />
        
        <div className="field">
          <label htmlFor="isDealQualifier" className="bold mr-4 mt-4">Is Deal Qualifier</label>
          <Checkbox id="bot-is-deal-qualifier" name="isDealQualifier" checked={isDealQualifier} value="Enabled" onChange={(e) => setIsDealQualifier(e.checked)}/>
        </div>
        
        <Loader loading={loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={botInstructionDialog} style={{ width: '450px' }} header={botInstruction?.id ? "Edit Bot Instruction" : "Create Bot Instruction"} modal className="p-fluid" footer={botInstructionDialogFooter} onHide={hideDialog}>
        
        <div className="field">
          <div className="field">
            <InputText id="bot-instruction-input" name="instruction" style={{ width: '385px' }} placeholder='Bot Instruction' value={instructionToAdd} onChange={(e) => setInstructionToAdd(e.target.value)} />
          </div>
        </div>
        
        <Loader loading={loading} />
        <Message message={err} severity={'error'}/>
      </Dialog>
      
      <Dialog visible={sendMessageDialog} style={{ width: '450px' }} header={`Talk to ${bot?.name}`} modal className="p-fluid" footer={sendMessageDialogFooter} onHide={hideDialog}>
        
        <Card>
          {bot?.personal_messages?.map((message: TwilioMessage) => (
            <MessageBox
              id={`user-message-chat-item-${message.id}`}
              key={message.id}
              position={message.direction === "OUTBOUND" ? "right" : "left"}
              focus={false}
              date={new Date(message.created ?? "")}
              titleColor={message.direction === "OUTBOUND" ? "grey" : "blue"}
              forwarded={false}
              replyButton={false}
              removeButton={false}
              status={"read"}
              type={"text"}
              notch={false}
              retracted={false}
              text={message.message ?? ""}
              title={message.direction === "OUTBOUND" ? bot?.name : "You"}
            />
          ))}
        </Card>
        
        <div className="field">
          <div className="field">
            <InputText id="bot-message-input" name="message" style={{ width: '385px' }} placeholder='Message to Bot' value={newMessageToBot} onChange={(e) => setNewMessageToBot(e.target.value)} />
          </div>
        </div>
        
        <Loader loading={loading} />
        <Message message={err} severity={'error'}/>
        
        <Button id="clear-bot-messages-btn" label="Clear conversation" icon="pi pi-times" className="p-button-text" onClick={handleClearBotConversation} />
      </Dialog>
      
      <Dialog visible={showConversationsDialog} style={{ width: '450px' }} header="Bot Conversations" modal className="p-fluid" footer={showConversationsDialogFooter} onHide={hideDialog}>
        <Accordion>
        {bot?.conversations?.map((c: TwilioMessage[]) => (
          <AccordionTab header={c.length > 0 ? `${c[0].member_name} - ${getTimeDiff(c[0].created)}` : "No Messages"}>
          {c?.map((message: TwilioMessage) => (
              <MessageBox
                id={`bot-message-chat-item-${message.id}`}
                key={message.id}
                position={message.direction === "OUTBOUND" ? "right" : "left"}
                focus={false}
                date={new Date(message.created ?? "")}
                titleColor={message.direction === "OUTBOUND" ? "grey" : "blue"}
                forwarded={false}
                replyButton={false}
                removeButton={false}
                status={"read"}
                type={"text"}
                notch={false}
                retracted={false}
                text={message.message ?? ""}
                title={message.direction === "OUTBOUND" ? bot?.name : "You"}
              />
            ))}
          </AccordionTab>
        ))}
        </Accordion>
      </Dialog>
      
      <Dialog visible={manageInstructionsDialog} style={{ width: '450px' }} header="Manage Instructions" modal className="p-fluid" footer={manageInstructionsDialogFooter} onHide={hideDialog}>
        
        <div className="field">
          
          <div className="field">
            <div className="field">
              
              <strong className="float-left mr-1">Provider:</strong>
              {bot ? providerBodyTemplate(bot): null}
              
              <Divider className="mt-3"/>
              
              <br/>
              {bot?.instruction_set?.length ? bot?.instruction_set?.map((item: BotInstruction) => (
                <div key={item.id}>
                  <strong>Active Instructions</strong>
                  <div id="bot-active-instruction" key={item.id}>{item.instruction}</div>
                  <Button id="bot-active-instruction-remove-btn" icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => removeActiveInstruction(item)} />
                  <Divider/>
                </div>
              )) :  (
                <>
                  <strong>No active instructions</strong>
                </>
              )}
              
              <Divider className="mt-3 mb-3"/>
              
              {instructionsToAdd?.length > 0 ? (
                <div style={{ width: '385px' }}>
                  <strong>Instructions to Add</strong>
                  <br/>
                  <br/>
                  {instructionsToAdd?.map((item: BotInstruction) => (
                    <div key={item.id} id="bot-instruction">
                      <div>{item.instruction}</div>
                      <Button id="bot-instruction-remove-btn" icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => removeInstructionToAdd(item)} />
                      <Divider/>
                    </div>
                  ))}
                </div>
              ): null}
              
              <Dropdown
                id="bot-instructions-dropdown"
                style={{ width: '385px' }}
                value={instructionToAdd}
                options={instructions.filter((i) => {
                   const instructionSetKeys = bot?.instruction_set?.map((i) => i.id);
                   const instructionsToAddKeys = instructionsToAdd?.map((i) => i.id);
                   return !instructionSetKeys?.includes(i?.id) && !instructionsToAddKeys?.includes(i?.id);
                  }
                )}
                onChange={(e) => {
                  let _instructions = [...instructionsToAdd];
                  if (!_instructions.includes(e.value)) {
                    _instructions.push(e.value);
                    setInstructionsToAdd(_instructions)
                  }
                }}
                optionLabel="instructions"
                placeholder="Select Instructions to Add"
                valueTemplate={selectedInstructionsTemplate}
                itemTemplate={instructionsOptionTemplate}
              />
            </div>
            
          </div>
        
        </div>
        
        <Loader loading={loading} />
      </Dialog>
      
      <Dialog visible={deleteBotDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteBotDialogFooter} onHide={hideDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {bot && <span>Are you sure you want to delete bot <b>{bot.id}</b>?</span>}
        </div>
      </Dialog>
      
      <Dialog visible={deleteBotsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteBotsDialogFooter} onHide={hideDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {selectedBots?.length && <span>Are you sure you want to delete the selected list of bots?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default BotsTable;
