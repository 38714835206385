import * as React from 'react';
import {GMapify} from 'g-mapify';
import  'g-mapify/dist/index.css';
import useGeocode from '../../hooks/useGeocode';
import {Loader, Message} from '..';
import {MAPS_KEY} from '../../utils';
import './styles.scss';


interface MapProps {
  address: string,
  showAddress?: boolean,
}

const Map: React.FC<MapProps> = ({ address, showAddress }) => {
  const [geoRes, setGeoRes] = React.useState<any>();
  const [error, setError] = React.useState<string>('');
  
  const { getGeocode } = useGeocode()
  
  const onMapSelect = (status: any, res: any) => {
    console.warn(status, res);
  }

  React.useEffect(() => {
    getGeocode(address).then((location: any) => setGeoRes(location)).catch(err => setError(err))
  }, [address])

  return (
    <>
      {showAddress ? (
        <h4>{address}</h4>
      ) : null}
      {geoRes ? (
        <GMapify
          appKey={MAPS_KEY}
          lat={geoRes.lat}
          lng={geoRes.lng}
          customMarkers={[[geoRes.lat, geoRes.lng, address]]}
          onSelect={onMapSelect}
        />
      ) : <Loader loading={true}/>}
      <br/>
      <Message severity={error ? 'error' : 'success'} message={error || 'Location found'}/>
    </>
  );
};

export default Map;

