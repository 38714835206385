import * as React from 'react';
import Markdown from 'react-markdown'
import Iframe from 'react-iframe';
import {Button, Card} from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import useLeadLists from '../../hooks/useLeadLists';
import {SmsTemplate} from '../../api/types';
import {TextField} from '../index';
import './styles.scss';

const TwilioFlex: React.FC = () => {
  
  const { smsTemplates } = useLeadLists()
  
  const [hidden, setHidden] = React.useState(true)
  
  const [copied, setCopied] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>('');
  const [fromName, setFromName] = React.useState<string>('Silas');
  const [address, setAddress] = React.useState<string>('');
  const [businessName, setBusinessName] = React.useState<string>('Coleman Group Solutions');
  
  const renderTemplate = (templateText: string) => {
    let txt = templateText
    if (name) txt = txt.replace('{first_name}', name)
    if (fromName) txt = txt.replace('{from_name}', fromName)
    if (address) txt = txt.replace('{address}', address)
    if (businessName) txt = txt.replace('{business_name}', businessName)
    
    return txt
  }
  
  const templateFillers = (
    <>
      <TextField
        value={name}
        id="name"
        placeholder="To name"
        autoComplete="name"
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        value={fromName}
        id="fromName"
        placeholder="From Name"
        autoComplete="fromName"
        onChange={(e) => setFromName(e.target.value)}
      />
      <TextField
        value={address}
        id="address"
        placeholder="Address"
        autoComplete="address"
        onChange={(e) => setAddress(e.target.value)}
      />
      <TextField
        value={businessName}
        id="businessName"
        placeholder="Business Name"
        autoComplete="businessName"
        onChange={(e) => setBusinessName(e.target.value)}
      />
    </>
  )
  
  return (
    <>
      <Button onClick={() => setHidden(!hidden)}>{hidden ? 'Show' : 'Hide'} Twilio Flex</Button>
      {!hidden ? (
        <>
          <h4>Fill in template:</h4>
          {templateFillers}
          <br/>
          <br/>
          {smsTemplates.map((template: SmsTemplate) => (
            <Card>
              <CopyToClipboard text={renderTemplate(template.template_text)}
                               onCopy={() => setCopied(true)}>
                <Button>{copied ? 'Copied!' : 'Copy Message'}</Button>
              </CopyToClipboard>
              <Markdown>
                {`~~~${renderTemplate(template.template_text)}\`\`\``}
              </Markdown>
            </Card>
          ))}
          <br/>
          <br/>
          <Iframe url="https://flex.twilio.com"
            width={String(window.innerWidth)}
            height="850px"
            id="flex"
            display="inline"
            position="relative"/>
          </>
      ) : null}
    </>
  );
};

export default TwilioFlex;
