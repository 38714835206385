
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AOS from 'aos';

import { Grid, AddressForm, SectionPills } from '../../components';
import { datAosOnce, fadeRight } from '../../utils/animationsName';

import GridContainer from '../../kit-components/Grid/GridContainer';
import GridItem from '../../kit-components/Grid/GridItem';
import phone from '../../assets/img/phonecall.png';
import email from '../../assets/img/email.png';
import addresshome from '../../assets/img/addresshome.png';

import './styles.scss';
import Footer from '../../kit-components/Footer/Footer';
import {GOOGLE_NUMBER} from '../../utils';

const Contact: React.FC<RouteComponentProps> = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1
    })
  }, [])

  const contactComponent = (
    <div>
      <div className='lg-rules contact-us-container'>
        <div className="contact-us-container-absolute">
          <div className="contact-us-container-absolute-child"></div>
        </div>
        <div className='lg-rules--header contact-us-heading'>Contact Us</div>

        <div data-aos-once={datAosOnce} data-aos={fadeRight} className="helping-neighbor-call-text"  >
          Call Us Today To Get Your Fast & Fair Offer!
        </div>
        <div data-aos-once={datAosOnce} data-aos={fadeRight} className="helping-neighbor-offers-container">
          <div className="helping-neighbor-offers">
            <div>
              We Have Solutions for Any Property, Any Price, Any Condition, No Hassles, No Commissions, No Obligations. Just Solutions!
            </div>
          </div>
        </div>
        <GridContainer>
          <GridItem lg={12}>
            <div data-aos-once={datAosOnce} className="helping-contact-container">
              <div className="contact-us-icon-container">
                <img className="helping-contact-container-icon" src={email} />
                <div className="helping-contact-container-icon-title">
                  Email
                </div>
                <div className="helping-contact-container-icon-title">
                  contact@colemangroupsolutions.com
                </div>
              </div>
              <div className="contact-us-icon-container">
                <img className="helping-contact-container-icon" src={addresshome} />

                <div className="helping-contact-container-icon-title">
                  ADDRESS
                </div>

                <div className="helping-contact-container-icon-title">
                  1 E Erie St Suite 525-2433 Chicago, IL 60611
                </div>
              </div>
              <div className="contact-us-icon-container">
                <img className="helping-contact-container-icon" src={phone} />
                <div className="helping-contact-container-icon-title">
                  PHONE
                </div>
                <div className="helping-contact-container-icon-title">
                  {GOOGLE_NUMBER}
                </div>
              </div>
            </div>
          </GridItem>
        </GridContainer>

        <div className='addres-form-top'>

            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <SectionPills/>
              </GridItem>

              <GridItem xs={6} sm={6} md={6}>
                <AddressForm check={true}/>
              </GridItem>
            </GridContainer>

        </div>
      </div>
      <Footer />
    </div>
  );

  return (
    <div className="contact-main-container">
      <Grid
        items={[
          {
            id: 'lg-rules',
            cols: { xs: 12, sm: 8, md: 6, lg: 10 },
            content: contactComponent,
            rows: 1,
          },
        ]}
      />
    </div>
  );
};

export default withRouter(Contact);
