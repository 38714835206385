import React from 'react';
import AOS from 'aos'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import HandshakeIcon from '@mui/icons-material/Handshake';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';

// core components;
import './style.scss';


import styles from '../../assets/jss/material-kit-react/views/componentsSections/tabsStyle';
import { datAosOnce } from '../../utils/animationsName'


const useStyles = makeStyles(styles);

interface SectionCardsProps {
  heading: string,
  desc: string,
  image?: any,
  icon?: string
}

const WaysCards: React.FC<SectionCardsProps> = ({ heading, desc, image, icon }) => {

  return (
    <div>
      <div className="way-card-container-inside">
        <div className="way-card-container-image">
          {icon ? icon : (<img className="way-card-container-image-img" src={image} />)}
        </div>

        <h3 className="way-card-text-heading">
          {heading}
        </h3>

        <div className="ways-card-text-desc">
          {desc}
        </div>
      </div>
    </div>
  );
}


export default WaysCards;
