import React, { useState, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


import {Header, Headerflow} from '..';
import { socialLogin } from '../../store/actions/auth'

import './styles.scss';


interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState('/home');
  const data = useSelector((store: any) => store);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(socialLogin());
  }, []);


  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location.pathname]);
  
  return (
    <div className="layout-main-container">
      <Header />
      {currentRoute.includes('home') || currentRoute === '/' ? null :
        <div className="layout-header-container">
          <Headerflow check={true} route={location.pathname.replace('/', '')} isAdmin={Boolean(data?.auth?.user?.user?.is_superuser)}  />
        </div>
      }
      <div className="cgs--layout">{children}</div>
    </div>
  );
};

export default Layout;
