import { useState, useEffect } from 'react'
import {AxiosResponse} from 'axios';
import {Deal, DealType, House, State} from '../api/types'
import {deleteData, getData, patchData, postData} from '../api/http';
import {getError} from '../utils';


export default function useDeals() {
  const [loading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [error, setError] = useState<string>('');
  
  const [dealTypes, setDealTypes] = useState<DealType[]>([]);
  
  const [deals, setDeals] = useState<Deal[]>([]);
  const [dealsPageCount, setDealsPageCount] = useState<number>(0);
  const pageLength = 10
  
  useEffect(() => {
    getDealTypes()
      .then((data: DealType[]) => setDealTypes(data))
  }, [])
  
  
  const getNextPage = async (uri: string): Promise<any[]> => {
    setLoading(true)
    return getData(uri)
    .then((data) => {
      setLoading(false)
      return data
    }).catch(({ response }) => console.log(response));
  }
  
  const getDealTypes = async (): Promise<DealType[]> => {
    setLoading(true)
    return getData('dealtypes')
    .then((data) => {
      setLoading(false)
      return data;
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
      return err;
    });
  }
  
  const getDeal = async (id: string): Promise<Deal> => {
    setLoading(true)
    return getData(`deals/${id}`)
    .then((data) => {
      setLoading(false)
      return data;
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
      return err;
    });
  }
  
  const getDeals = async (
    priority: string,
    stage: string,
    name: string,
    address: string,
    number: string,
    dealType?: DealType,
    house?: House,
    state?: State,
    sellerPriceRange?: number[],
    buyerPriceRange?: number[],
    dealPriceRange?: number[],
    page?: number
): Promise<Deal[]> => {
    setLoading(true)
    setError('')
    setMsg('')
    
    let url = 'deals/?'
    if (house) url += `house=${house?.id}&`
    if (state) url += `state=${state?.id}&`
    if (name) url += `name=${name}&`
    if (address) url += `address=${address}&`
    if (priority) url += `priority=${priority}&`
    if (stage) url += `stage=${stage}&`
    if (number) url += `number=${number}&`
    if (dealType?.id) url += `type=${dealType?.id}&`
    
    if (sellerPriceRange?.length) {
      if (sellerPriceRange[0]) url += `seller_min=${String(sellerPriceRange[0])}&`
      if (sellerPriceRange?.length > 1 && sellerPriceRange[1])
        url += `seller_max=${String(sellerPriceRange[1])}&`
    }
    if (buyerPriceRange?.length) {
      if (buyerPriceRange[0]) url += `buyer_min=${String(buyerPriceRange[0])}&`
      if (buyerPriceRange?.length > 1 && buyerPriceRange[1])
        url += `buyer_max=${String(buyerPriceRange[1])}&`
    }
    if (dealPriceRange?.length) {
      if (dealPriceRange[0]) url += `ideal_min=${String(dealPriceRange[0])}&`
      if (dealPriceRange?.length > 1 && dealPriceRange[1])
        url += `deal_max=${String(dealPriceRange[1])}&`
    }
    
    if (page) url += `page=${page}&`
  
    return getData(url)
    .then((data) => {
      setDealsPageCount(Math.ceil(data?.count/pageLength))
      setDeals(data?.results)
      setLoading(false)
      return data?.results;
    })
    .catch(({ response }) => {
      setLoading(false)
      return response;
    });
  }
  
  const updateDeal = async (deal: Deal): Promise<AxiosResponse> => {
    if (!deal) return Promise.reject()
    
    setLoading(true)
    
    const formData = new FormData()
  
    if (deal.priority) formData.append('priority', deal.priority);
    if (deal.stage) formData.append('stage', deal.stage);
    if (deal.deal_type) formData.append('deal_type', String(deal.deal_type));
    if (deal.description) formData.append('description', deal.description);
    if (deal.seller_price) formData.append('seller_price', String(deal.seller_price));
    if (deal.buyer_price) formData.append('buyer_price', String(deal.buyer_price));
    if (deal.deal_price) formData.append('deal_price', String(deal.deal_price));
    if (deal.bank_interest_rate) formData.append('bank_interest_rate', String(deal.bank_interest_rate))
    if (deal.bank_carry_pct) formData.append('bank_carry_pct', String(deal.bank_carry_pct))
    if (deal.owner_interest_rate) formData.append('owner_interest_rate', String(deal.owner_interest_rate))
    if (deal.owner_carry_pct) formData.append('owner_carry_pct', String(deal.owner_carry_pct))
  
    return patchData(`deals/${deal.id}`, formData)
      .then(res => {
        console.log('UPDATE DEAL RES:', res)
        setLoading(false)
        return res;
      })
      .catch((err) => {
        setLoading(false)
        return err;
      });
  }
  
  const createDeal = async (house: House, dealType?: DealType, description?: string, priority?: string, stage?: string, sellerPrice?: string, buyerPrice?: string, dealPrice?: string) => {
    setLoading(true)
    setMsg('')
    setError('')
  
    const formData = new FormData();
    formData.append('house', String(house.id));
    
    if (dealType) formData.append('deal_type', String(dealType.id));
    if (description) formData.append('description', description);
    if (priority) formData.append('priority', priority);
    if (stage) formData.append('stage', stage);
    if (sellerPrice) formData.append('seller_price', sellerPrice);
    if (buyerPrice) formData.append('buyer_price', buyerPrice);
    if (dealPrice) formData.append('deal_price', dealPrice);
    
    return postData('deals/', formData)
      .then((res: any) => {
        if (res?.status === 201)
          setMsg('Successfully created deal')
        else
          setError('Error creating deal')
        setLoading(false)
        return res
      })
      .catch((err) => {
        setError('Error creating deal')
        setLoading(false)
        console.log(err);
      });
    
  }
  
  const deleteDeal = async (id: number): Promise<AxiosResponse> => {
    setLoading(true)
    
    return deleteData(`deals/${id}`)
    .then((res: AxiosResponse) => {
      setLoading(false)
      return res;
    })
    .catch((err) => {
      return err
    });
  };
  
  
  const createDealNote = async (deal: Deal, subject: string, text: string) => {
    setLoading(true)
    
    const formData = new FormData();
    formData.append('deal', String(deal?.id));
    formData.append('subject', subject);
    formData.append('text', text);
    
    return postData('notes/', formData)
    .then((res: any) => {
      setLoading(false)
      if (res.status !== 201) {
        setError(getError(res.data))
        return res
      }
      return res
    })
    .catch((err) => {
      setLoading(false)
      console.log(err);
    });
    
  }
  
  const uploadImage = async (house: House, file: File, description: string) => {
    setLoading(true)
    
    const formData = new FormData();
    formData.append('house', String(house.id));
    formData.append('description', description);
    formData.append('filename', file);
    
    return postData('pictures/', formData)
    .then((res: any) => {
      setLoading(false)
      if (res.status !== 201) {
        setError(getError(res.data))
        return res
      }
      return res
    })
    .catch((err) => {
      setLoading(false)
      console.log(err);
    });
    
  }
  
  
  return {
    loading,
    msg,
    error,
    dealTypes,
    deals,
    dealsPageCount,
    getNextPage,
    getDealTypes,
    getDeal,
    getDeals,
    createDeal,
    updateDeal,
    deleteDeal,
    createDealNote,
    uploadImage,
  }
}
