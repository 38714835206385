import { useState, useEffect } from 'react'
import Geocode from 'react-geocode';
import {MAPS_KEY} from '../utils';

Geocode.setApiKey(MAPS_KEY);
Geocode.setRegion('us');
Geocode.enableDebug();

export default function useGeocode() {
  
  const handleGetGeocode = async ( address: string ) => {
    return Geocode.fromAddress(address).then((response: any) => {
      const { location } = response.results[0].geometry
      return location
    }, (e: any) => {
      return e
    });
  }
  
  return { getGeocode: handleGetGeocode }
}
