import { useState, useEffect } from 'react'
import {AxiosResponse} from 'axios';
import {DealType, House, SmsTemplate, State} from '../api/types'
import {deleteData, getData, patchData, postData} from '../api/http';


export default function useLeads() {
  const [loading, setLoading] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const [error, setError] = useState<string>('');
  
  const [templateMsgs, setTemplateMsgs] = useState<SmsTemplate[]>([]);
  const [dealTypes, setDealTypes] = useState<DealType[]>([]);
  const [states, setStates] = useState<State[]>([]);
  
  const [leads, setLeads] = useState<House[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  
  const pageLength = 50
  
  useEffect(() => {
    getSmsTemplates()
    .then((data: SmsTemplate[]) => setTemplateMsgs(data))
    getDealTypes()
      .then((data: DealType[]) => setDealTypes(data))
    getStates()
      .then((data: State[]) => setStates(data))
  }, [])
  
  
  const getNextPage = async (uri: string): Promise<any> => {
    setLoading(true)
    return getData(uri)
    .then((data) => {
      setLoading(false)
      return data
    }).catch(({ response }) => console.log(response));
  }
  
  const getSmsTemplates = async (): Promise<SmsTemplate[]> => {
    setLoading(true)
    return getData('sms-templates/')
    .then((data) => {
      setLoading(false)
      return data
    }).catch(({ response }) => console.log(response));
  }
  
  const getStates = async (): Promise<State[]> => {
    setLoading(true)
    return getData('states/')
      .then((data) => {
        setLoading(false)
        return data
      }).catch(({ response }) => console.log(response));
  }
  
  const getDealTypes = async (): Promise<DealType[]> => {
    setLoading(true)
    return getData('dealtypes')
    .then((data) => {
      setLoading(false)
      return data;
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
      return err;
    });
  }
  
  const getLeads = async (
    listId?: string,
    name?: string,
    address?: string,
    phoneNumber?: string,
    responses?: boolean,
    noResponses?: boolean,
    state?: State,
    hideContacted?: boolean,
    hideNotContacted?: boolean,
    page?: number
): Promise<any> => {
    setLoading(true)
    setError('')
    setMsg('')
    
    let url = 'houses/?'
    if (address)
      url += `address=${address}&`
    if (name)
      url += `name=${name}&`
    if (phoneNumber)
      url += `phone=${phoneNumber}&`
    if (responses)
      url += 'responses=true&'
    if (noResponses)
      url += 'noResponses=true&'
    if (state)
      url += `state=${state.id}&`
    if (listId)
      url += `list=${listId}&`
    if (hideContacted && !hideNotContacted)
      url += 'hideContacted=true&'
    else if (hideNotContacted && !hideContacted)
      url += 'hideNotContacted=true&'
    if (page)
      url += `page=${page}&`
    
    const data = await getData(url)
    setPageCount(Math.ceil(data.count/pageLength))
    setLeads(data.results)
    return data
  }
  
  
  const sendText = async (fromNumber: string, toNumber: string, house?: House, message?: string): Promise<AxiosResponse> => {
    setMsg('')
    setError('')
    setLoading(true)
    
    const formData = new FormData();
    formData.append('from_number', fromNumber)
    formData.append('to_number', toNumber)
    
    if (house) formData.append('house', String(house.id))
    if (message) formData.append('message', message)
    
    return postData('text/', formData)
    .then((res) => {
      if (res?.status !== 200)
        setError(res.data)
      else
        setMsg(res.data)
      
      setLoading(false)
      return res;
    })
    .catch((err) => {
      console.log('SEND TEXT ERROR:', err);
    });
  };
  
  const serializeTextAreaInput = (text?: string): any => {
    if (!text) return ''
    
    let items = '{'
    const lines = text.trim().split('\n');
    
    lines.forEach((line) => {
      if (!line) return
      if (!line.includes(' ')) return
      
      line = line.replaceAll('\t', ' ').trim()
      
      const parts = line.split(' ');
      const lastPart = parts.pop()?.trim();
      const item = `"${parts.join(" ").trim()}": ${lastPart},`
      
      items += item
    })
    
    items = items.slice(0, -1)
    items += '}'
    
    return items
  }
  
  const deserializeTextAreaInput = (item?: any): string => {
    if (!item) return ''
    
    let text = ''
    Object.keys(item).forEach((key) => {
      text += `${key} ${item[key]}\n`
    })
    
    return text
  }
  
  const createLead = async (address: string, city: string, state: State, zipcode: number, income: string, expenses: string): Promise<AxiosResponse> => {
    setMsg('')
    setError('')
    setLoading(true)
    
    const formData = new FormData();
    formData.append('address', address);
    formData.append('city', city);
    formData.append('state', String(state.id));
    formData.append('zipcode', String(zipcode));
    
    const incomeJSON = serializeTextAreaInput(income)
    const expensesJSON = serializeTextAreaInput(expenses)
    
    if (incomeJSON)
      formData.append('monthly_income', incomeJSON);
    if (expensesJSON)
      formData.append('monthly_expenses', expensesJSON);
    
    return postData('houses/', formData)
    .then((res) => {
      if (res?.status !== 201)
        setError(res.data)
      else
        setMsg(res.data)
      
      setLoading(false)
      return res;
    })
    .catch((err) => {
      return err
    });
  }
  
  const researchLead = async (lead: House): Promise<House> => {
    setMsg('')
    setError('')
    setLoading(true)
    
    return getData(`houses/${lead.id}/research`)
    .then((res) => {
      setLoading(false)
      return res;
    })
    .catch((err) => {
      return err
    });
  }
  
  const updateLead = async (lead: House, address?: string, city?: string, state?: State, zipcode?: number, income?: string, expenses?: string, startupCosts?: string): Promise<AxiosResponse> => {
    setMsg('')
    setError('')
    setLoading(true)
    
    if (!lead) return Promise.reject('No lead')
    
    const formData = new FormData();
    if (address)
      formData.append('address', address);
    if (city)
      formData.append('city', city);
    if (state)
      formData.append('state', String(state.id));
    if (zipcode)
      formData.append('zipcode', String(zipcode));
    
    const incomeJson = serializeTextAreaInput(income)
    if (incomeJson)
      formData.append('monthly_income', incomeJson);
    
    const expensesJson = serializeTextAreaInput(expenses)
    if (expensesJson)
      formData.append('monthly_expenses', expensesJson);
    
    const startupCostsJson = serializeTextAreaInput(startupCosts)
    if (startupCostsJson)
      formData.append('startup_costs', startupCostsJson);
    
    return patchData(`houses/${lead.id}`, formData)
    .then((res) => {
      if (res?.status !== 200)
        setError(res.data)
      else
        setMsg(res.data)
      
      setLoading(false)
      return res
    })
    .catch((err) => {
      return err
    });
  };
  
  const deleteLead = async (id: number): Promise<AxiosResponse> => {
    setLoading(true)
    
    return deleteData(`houses/${id}`)
    .then((res: AxiosResponse) => {
      setLoading(false)
      return res;
    })
    .catch((err) => {
      return err
    });
  };
  
  return {
    loading,
    msg,
    error,
    dealTypes,
    states,
    leads,
    pageCount,
    templateMsgs,
    getNextPage,
    getLeads,
    createLead,
    researchLead,
    updateLead,
    deleteLead,
    sendText,
    serializeTextAreaInput,
    deserializeTextAreaInput,
  }
}
