import * as React from 'react';
import {Link} from 'react-router-dom';

import { SwipeableDrawer, List, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-kit-react/components/footerStyle';

import './style.scss';
import {CURRENT_URL, LIFECYCLE} from '../../utils';
import {crmRoutes} from '../../utils/router';

const useStyles = makeStyles(styles);

interface SideDrawerProps {
  route: string,
  open: boolean,
  isLogin: boolean,
  isAdmin: boolean
  setOpen: any,
  handleLogout: any,
}

const SideDrawer: React.FC<SideDrawerProps> = ({ route, open, setOpen, isLogin, isAdmin, handleLogout }) => {
  const classes = useStyles();
  return (
    <div className="side-drawer">
      <SwipeableDrawer
        anchor="right"
        open={open}
        className="side-drawer"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <div className="side-drawer-list">
          <List>
            <Link to='/home' className={classes.block}>
              <MenuItem onClick={() => setOpen(false)}>
                Home
              </MenuItem>
            </Link>
            {!crmRoutes.includes(route) ? (
              <>
                <Link to='/contact' className={classes.block}>
                  <MenuItem onClick={() => setOpen(false)}>
                    Contact us
                  </MenuItem>
                </Link>
                <Link to='/howwehelp' className={classes.block}>
                  <MenuItem onClick={() => setOpen(false)}>
                    How we help
                  </MenuItem>
                </Link>

                <Link to='/whywehelp' className={classes.block}>
                  <MenuItem onClick={() => setOpen(false)}>
                    Why we help
                  </MenuItem>
                </Link>
      
                <Link to='/faq' className={classes.block}>
                  <MenuItem onClick={() => setOpen(false)}>
                    Faq
                  </MenuItem>
                </Link>
      
                {LIFECYCLE !== 'prod' ? (
                  <Link to='/testimonials' className={classes.block}>
                    <MenuItem onClick={() => setOpen(false)}>
                      Testimonials
                    </MenuItem>
                  </Link>
                ) : null}
              </>
            ) : null}

            {isAdmin ?
              <>
                {!crmRoutes.includes(route) ? (
                  <Link to='/ingest' className={classes.block}>
                    <MenuItem onClick={() => setOpen(false)}>
                      CRM
                    </MenuItem>
                  </Link>
                ) : (
                  <>
                    {crmRoutes.map((item: string, index: number) => (
                      <Link key={index} className={classes.block} to={`/${item}`}>
                        <MenuItem onClick={() => setOpen(false)}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </MenuItem>
                      </Link>
                    ))}
                  </>
                )}
                
                <a href={`${CURRENT_URL}admin/`} className={classes.block}>
                  <MenuItem>
                    Admin
                  </MenuItem>
                </a>
              </>
            : null }
            
            {isLogin ?
                <Link to='/' className={classes.block}>
                  <MenuItem id="signout-item" onClick={() => {handleLogout(); setOpen(false)}}>
                    Sign Out
                  </MenuItem>
                </Link>
              :
                <a href={`${CURRENT_URL}api/rest/login/`} className={classes.block}>
                  <MenuItem id="signin-item">
                    Sign In
                  </MenuItem>
                </a>
            }
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default SideDrawer