import axios from 'axios';
import { CURRENT_URL } from '../utils';

const getCSRFToken = () => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith('csrftoken=')) {
      return cookie.split('=')[1]
    }
  }
}

export const api = () => {
  
  if (window.location.hostname === 'localhost') {
    const csrfToken = getCSRFToken()
    axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
    
  }
  
  axios.defaults.withCredentials = true;
  axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
  axios.defaults.xsrfCookieName = 'csrftoken';

  // const token = localStorage.getItem('auth-token');
  // if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axios.create({ baseURL: `${CURRENT_URL}api/`, timeout: 20000 });
};

export default api();
